import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/bronzeRequirements";
import { BronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";
import BronzeRequirementVCSPercent from "../../BronzeCommon/BronzeRequirements/BronzeRequirementVCSPercent/BronzeRequirementVCSPercent";
import BronzeRequirementPersonalPV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementPersonalPV/BronzeRequirementPersonalPV";
import BronzeRequirementLegsPercent from "../../BronzeCommon/BronzeRequirements/BronzeRequirementLegsPercent/BronzeRequirementLegsPercent";
import BronzeRequirementPerfBonusLV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementPerfBonusLV/BronzeRequirementPerfBonusLV";
import BronzeRequirementVCSPV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementVCSPV/BronzeRequirementVCSPV";
import BronzeRequirementVCSLegs from "../../BronzeCommon/BronzeRequirements/BronzeRequirementVCSLegs/BronzeRequirementVCSLegs";
import BronzeRequirementPersonalOrderPV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementPersonalOrderPV/BronzeRequirementPersonalOrderPV";
import BronzeRequirementPersonalActivityPV from "../../BronzeCommon/BronzeRequirements/BronzeRequirementPersonalActivityPV/BronzeRequirementPersonalActivityPV";
import "./BFIRequirements.scss";

type Props = {
  awardCode: number;
  show?: boolean;
  data: BronzeOverviewResponse;
  isFetching: boolean;
};

const BFIRequirements = ({ awardCode, show = true, data, isFetching = true }: Props) => {
  const { t } = useTranslation(["bronzeRequirements"]);

  const { requirements } = data;

  const {
    personalVolume = {},
    legsPercentage = {},
    performanceBonus = {},
    verifiedCustomerPV = {},
    vcsPercent = {},
    personalOrderPV = {},
    vcsBonusLegs = {},
    personalActivityPV = {},
  } = requirements || {};

  if (show === false) return <></>;

  // The order of the requirements here will also reflect on the order of  (BFIIncentiveProgress) component
  return (
    <div className="BFIRequirements">
      <h2 className="BFIRequirements__header">{t("header", defaultContent["header"])}</h2>
      <div className="BFIRequirements__content">
        <BronzeRequirementPersonalPV {...personalVolume} isFetching={isFetching} />
        <BronzeRequirementPersonalOrderPV {...personalOrderPV} isFetching={isFetching} />
        <BronzeRequirementPersonalActivityPV {...personalActivityPV} isFetching={isFetching} />
        <BronzeRequirementVCSPercent {...vcsPercent} isFetching={isFetching} />
        <BronzeRequirementVCSPV {...verifiedCustomerPV} isFetching={isFetching} />
        <BronzeRequirementPerfBonusLV {...performanceBonus} isFetching={isFetching} />
        <BronzeRequirementLegsPercent {...legsPercentage} isFetching={isFetching} module={"bfi"} />
        <BronzeRequirementVCSLegs {...vcsBonusLegs} isFetching={isFetching} module={"bfi"} />
      </div>
    </div>
  );
};

export default BFIRequirements;
