import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showProfile } from "../profile";
import { OrderGroup, upsertOrderGroup, upsertOrderGroups } from "../model/order";
import { CustomerOrder, OrderResponse } from "../../services/Customer/CustomerOrderList/customerOrderListAPI.types";

type ProfileOrders = {
  period: number;
  pageNum: number;
  pageSize: number;
  pageLimit: number;
  totalOrderCount: number;
  allOrderCount: number;
  affNo: string;
  aboNo: string;
  affAbo: string;
  nextPageNum: number;
  nextPeriod: number;
  customerOrderList: CustomerOrder[];
  orderGroups: OrderGroup[];
  isFetching?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  orderSearchKeyword?: string;
  requestId?: string;
};

export const initialState: ProfileOrders = {
  period: 0,
  pageNum: 0,
  pageSize: 20,
  pageLimit: 0,
  totalOrderCount: 0,
  allOrderCount: 0,
  affNo: "",
  aboNo: "",
  affAbo: "",
  nextPageNum: 1,
  nextPeriod: 0,
  customerOrderList: [],
  orderGroups: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  orderSearchKeyword: "",
  requestId: "",
};

const { reducer, actions } = createSlice({
  name: "profileOrders",
  initialState,
  reducers: {
    updateProfileOrders: (state, { payload }: PayloadAction<{ requestId: string; data: OrderResponse }>) => {
      const { requestId, data } = payload;

      if (state.requestId === requestId) {
        const { period, customerOrderList } = data;
        const { orderGroups, allOrderCount } = state;
        const orderGroup = upsertOrderGroup(data, orderGroups);
        const _orderGroups = upsertOrderGroups(period, orderGroups, orderGroup);

        return {
          ...state,
          ...data,
          orderGroups: _orderGroups,
          allOrderCount: allOrderCount + customerOrderList.length,
          isSuccess: true,
          isError: false,
          isFetching: false,
        };
      }

      return state;
    },
    updateIsFetching: (state, { payload }: PayloadAction<{ requestId: string; isFetching: boolean }>) => ({
      ...state,
      ...payload,
      isError: false,
      isSuccess: false,
    }),
    updateIsError: (state, { payload }: PayloadAction<{ requestId: string; isError: boolean }>) => {
      const { requestId, isError } = payload;

      if (state.requestId === requestId) {
        return {
          ...state,
          requestId,
          isError,
          isSuccess: isError === false,
          isFetching: false,
        };
      }
      return state;
    },
    updateSearchKeyword: (_state, { payload }: PayloadAction<string>) => ({
      ...initialState,
      orderSearchKeyword: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(showProfile, () => initialState);
  },
});

export const { updateProfileOrders, updateIsFetching, updateIsError, updateSearchKeyword } = actions;

export default reducer;
