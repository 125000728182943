import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { useAppSelector } from "../../../../store";
import defaultContent from "../../../../content/annualGcrPGG";
import { IncomeData } from "../../../../services/GroupGrowth/groupGrowth.types";
import { getPerformanceYearShort } from "../../../../common/util/period";
import { BannerTile } from "../../../../common/components/BannerTile/BannerTile";
import { getBannerTitleKey, getBannerSubtitleKey } from "../../../../common/util/groupGrowthUtils";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import TEALIUM from "../../../../common/enums/tealium-enums";

type Props = {
  incomeData: IncomeData;
  isLoading: boolean;
  isNewPerformanceYear?: boolean;
};

export const IncomeBannerTile = ({ incomeData, isLoading, isNewPerformanceYear }: Props) => {
  const { t } = useTranslation(["annualGcrPGG"]);
  const { configuration } = useAppSelector((store) => store.boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const learnMoreLink = configuration.dashboardLinks?.learnMore?.personalGroupGrowth ?? "";

  const performanceYear = getPerformanceYearShort(selectedPeriod);
  const titleKey = getBannerTitleKey(incomeData.Status, isNewPerformanceYear);
  const descriptionKey = getBannerSubtitleKey(incomeData.Status, isNewPerformanceYear);

  const learmMoreTealiumAction = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "personal_group_growth_gcr",
      "personal_group_growth_gcr",
      `personal_group_growth_gcr`,
      "Learn_more",
    );
  };

  return (
    <BannerTile
      isLoading={isLoading}
      title={t(`bannerTile.${titleKey}`, defaultContent.bannerTile[titleKey], { performanceYear })}
      description={t(`bannerTile.${descriptionKey}`, defaultContent.bannerTile[descriptionKey], {
        performanceBonus: incomeData.PerformanceBonusLevelPercent,
      })}
      bonusAmountLabel={t("bannerTile.potentialEarnings", defaultContent.bannerTile.potentialEarnings, {
        performanceYear: performanceYear,
      })}
      shouldDisplayAmount={
        ![QUALIFICATION_STATUS.HELD, QUALIFICATION_STATUS.DENIED].includes(incomeData.Status) && !isNewPerformanceYear
      }
      bonusAmount={incomeData.PotentialPYEarnings}
      incentiveCapLabel={
        incomeData.IncentiveCap
          ? t("bannerTile.incentiveCap", defaultContent.bannerTile.incentiveCap, {
              bonusCap: incomeData.IncentiveCap,
            })
          : ""
      }
      learnMoreLink={learnMoreLink}
      learnMoreTealiumFunction={learmMoreTealiumAction}
    />
  );
};
