import { useGetABOProfileQuery } from "../../../../services/Profile/profileAPI";
import { useAppSelector } from "../../../../store";
import { LeafErrorMessage } from "../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import LeafApplicantItem from "../../../../common/leaf/LeafProfile/LeafApplicant/LeafApplicantItem/LeafApplicantItem";
import "./ABOApplicantBody.scss";
import {
  cardActionClickTrack,
  profileApplicantCardErrorTrack,
} from "../../../../common/tealium/ABOProfile/Profile/ApplicantCardTrack";

type Props = {
  isShowApplicant: boolean;
};

const ABOApplicantBody = (props: Props) => {
  const { isShowApplicant } = props;

  // Get Login ABO
  const user = useAppSelector((state) => state.boot?.user);
  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const boot = useAppSelector(({ boot }) => boot);
  const profileInfo = boot?.configuration?.profile?.aboInfo || {};

  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const applicantTrackOnclick = (event?: string) => {
    cardActionClickTrack(event, false);
  };

  const { isFetching, isError, data } = useGetABOProfileQuery({
    period: selectedPeriod,
    affAbo,
    reqAffAbo: `${user.aff}-${user.abo}`,
  });

  if (isError === true) {
    profileApplicantCardErrorTrack();
  }

  if (isShowApplicant && isFetching === false && data?.aboInfo && data.partyInfo) {
    const { aboInfo, partyInfo } = data;
    const applicantList = partyInfo.filter(({ isPrimary }) => isPrimary === false);

    return (
      <div className="abo-applicant-body">
        {applicantList.map((info, index) => {
          return (
            <div className="abo-applicant-body__card" key={index}>
              <LeafApplicantItem
                key={index}
                isExpandable={false}
                aboInfo={aboInfo}
                partyInfo={info}
                applicantTrackOnclick={applicantTrackOnclick}
                profileInfo={profileInfo}
              />
            </div>
          );
        })}
      </div>
    );
  }

  return <LeafErrorMessage show={isError} />;
};

export default ABOApplicantBody;
