import { Trans, useTranslation } from "react-i18next";
import { Document, Page, Text, View, Svg, Path, Link } from "@react-pdf/renderer";
import {
  findNonBonusableAmount,
  PerformanceTotal,
} from "../../PerformanceDashboard/MonthlyPerformanceDash/MonthlyPerformanceTotalModals/DifferentialBonusModal/DifferentialBonusModal";
import defaultContent from "../../../content/monthlyPerformanceTotalModals";
import leafAboNameContent from "../../../content/leafAboName";
import commonContent from "../../../content/common";
import { BonusBreakdownLineData } from "../../../common/interfaces/monthlyPerformanceDash";
import { getABOName } from "../../../common/leaf/LeafProfile/LeafABOName/LeafABOName";
import { storedLocale } from "../../impersonation/util";
import { AmwayLogo } from "../svg/AmwayLogo";
import {
  DifferentialBonusEntry,
  GroupEntry,
  MonthlyPerformanceResponse,
} from "../../../services/MonthlyPerformanceDashboard/monthlyPerformanceDashboardAPI.types";
import styles from "./DifferentialBonusPDFStyles";

// Create the PDF document
const DifferentialBonusPDF = ({
  performanceData,
  performanceTotal,
  anaMarket,
}: {
  performanceData?: Partial<MonthlyPerformanceResponse>;
  performanceTotal?: Partial<BonusBreakdownLineData>;
  anaMarket: boolean;
}) => {
  const { t } = useTranslation(["monthlyPerformanceTotalModals", "leafAboName", "common"]);

  if (!performanceData || !performanceTotal) {
    // Force to pre-load the font
    return (
      <Document>
        <Page style={{ ...styles.page, ...styles.body, ...styles.title }}>Loading</Page>
      </Document>
    );
  }

  const contents = t("monthlyPerformanceTotalModals:differential", {
    returnObjects: true,
    defaultValue: defaultContent.differential,
  }) as PerformanceTotal;

  const locale = localStorage.getItem("locale") || storedLocale();

  const addLineBreakAfterAsterisk = (text: string): string => {
    // Split the text by '*'
    const parts = text.split("*");

    // Join the parts back together with the asterisk and line breaks
    return parts
      .map((part, index) => {
        return index < parts.length - 1 ? `${part}\n\n*` : part; // Add line break before asterisk
      })
      .join("");
  };

  const footer = addLineBreakAfterAsterisk(`${t("common:footer", commonContent["footer"])}`);

  // Used only for zh-TW locale.
  /* Tech Debt: As traditional Chinese does not use spaces to separate words,
  so special handling is required to enable proper line breaking.
  Need to find a better alternative in future.*/
  const insertLineBreaks = (text: string, maxLength: number) => {
    if (locale === "zh-TW") {
      const regex = new RegExp(`(.{1,${maxLength}})`, "g");
      return text.match(regex)?.join("\n") || "";
    }
    return text;
  };

  return (
    <Document>
      <Page style={styles.page} wrap>
        {/* Amway Logo */}
        <AmwayLogo />
        <View style={styles.section}>
          <Text style={styles.title}>{contents?.title}</Text>
          <Text style={styles.subTitle}>
            <Trans defaults={contents?.subTitle} components={{ 1: <Text style={styles.bold} /> }} />
          </Text>
        </View>

        {/* Table content */}
        <View style={styles.section}>
          {performanceData?.differentialBonus?.map((item: DifferentialBonusEntry, index: number) => (
            <View wrap={false} key={`differentialBonusEntry-${index}`} style={styles.section}>
              <View style={styles.aboBackground}>
                {/* Column 1: ABO Name */}
                <Text style={[styles.tableRow, styles.abo]}>
                  {getABOName({
                    name: item.aboName,
                    localName: item.localizedName,
                    privacyFlag: item.privacyFlag,
                    languageCode: item.isoLanguageCode,
                    confidentialText: `${t("leafAboName:ConfidentialUser", leafAboNameContent["ConfidentialUser"])}`,
                    notAvailableText: `${t("leafAboName:notAvailable", leafAboNameContent["notAvailable"])}`,
                  })}
                </Text>
                <View key={index} style={[styles.tableRow, styles.aboDetail]}>
                  <Text>
                    <Trans
                      defaults={contents?.table?.aboBonusPercent}
                      values={{ aboBonusPercent: item.bonusPercent.formatted }}
                    />{" "}
                    | <Trans defaults={contents?.table?.aboGpv} values={{ aboGpv: item.totalGPV.formatted }} />
                  </Text>
                </View>
              </View>
              {item.groupBonus?.map((groupItem: GroupEntry, index: number) => (
                <View>
                  <View style={[styles.tableRow, styles.detailRow]}>
                    <Text>
                      <Trans
                        defaults={contents?.table?.personalBV}
                        values={{ countryCode: groupItem.isoCountryCode }}
                      />
                    </Text>
                    <Text>{groupItem.groupBV.formatted}</Text>
                  </View>
                  <View style={[styles.tableRow, styles.detailRow, styles.borderBottom]}>
                    <Text>
                      <Trans
                        defaults={contents?.table?.bonusPercent}
                        values={{
                          bonusPercent: `${performanceData?.bonusPercent?.formatted} - ${item.bonusPercent?.formatted}`,
                        }}
                      />
                    </Text>
                    <Text>x {item.differentialPercent?.formatted}</Text>
                  </View>
                  <View style={[styles.tableRow, styles.currentDiffTotal]}>
                    <Text>{contents?.table?.personalBonusAmount}</Text>
                    <Text>{groupItem.differentialAmount.formatted}</Text>
                  </View>
                  {anaMarket && (
                    <View style={[styles.tableRow, styles.nbbv]}>
                      <Text style={styles.tableRow}>{contents?.table?.nonBonusable}</Text>
                      <View style={styles.tableRow}>
                        <Text style={styles.bold}>
                          {findNonBonusableAmount(groupItem.isoCurrencyCode, item.groupNonBonusable)}
                        </Text>
                        <Svg style={styles.icon} viewBox="0 0 18 18" width="18" height="18">
                          <Path
                            d="M16.8125 14.0312L10.1562 2.65625C9.875 2.21875 9.4375 2 9 2C8.53125 2 8.09375 2.21875 7.8125 2.65625L1.125 14.0312C0.65625 14.9062 1.28125 16 2.3125 16H15.6562C16.6875 16 17.3125 14.9062 16.8125 14.0312ZM2.625 14.5L8.96875 3.65625L15.3438 14.5H2.625ZM9 11.5625C8.4375 11.5625 8 12 8 12.5312C8 13.0625 8.4375 13.5 9 13.5C9.53125 13.5 9.96875 13.0625 9.96875 12.5312C9.96875 12 9.53125 11.5625 9 11.5625ZM8.25 6.75V9.75C8.25 10.1875 8.5625 10.5 9 10.5C9.40625 10.5 9.75 10.1875 9.75 9.75V6.75C9.75 6.34375 9.40625 6 9 6C8.5625 6 8.25 6.34375 8.25 6.75Z"
                            fill="#A66400"
                          />
                        </Svg>
                      </View>
                    </View>
                  )}
                </View>
              ))}
            </View>
          ))}
          <View style={[styles.tableRow, styles.totalDiffBonus]} wrap={false}>
            <Text>{contents?.table?.total}</Text>
            <Text>{performanceTotal?.amount}</Text>
          </View>
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={styles.footer}>
          <Text style={styles.footerPadding}>
            {insertLineBreaks(`${t("common:totalsDisclaimer", commonContent["totalsDisclaimer"])}`, 50)}
          </Text>
          <Text style={styles.footerPadding}>
            {insertLineBreaks(`${t("common:bonusesDisclaimer", commonContent["bonusesDisclaimer"])}`, 50)}
          </Text>
          <Text style={styles.footerPadding}>
            <Trans
              defaults={insertLineBreaks(footer, 50)}
              components={{
                1: <Link src="http://amway.com/en_US/money-and-rewards" id="bonus-info-footer-link" />,
                br: <Text>{"\n"}</Text>,
              }}
            />
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default DifferentialBonusPDF;
