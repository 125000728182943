import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storageSession from "redux-persist/lib/storage/session";
import { Config } from "../common/interfaces/config";
import { ShellConfig } from "./model/boot";

export type BootUser = {
  token_type: string;
  access_token: string;
  expires_in: string;
  cacheDateInMS: number;
  abo: string;
  aff: string;
  partyId: string;
  globalPartyId: string;
  idToken: string;
  isoCountryCode: string;
  cognitoToken?: string;
};

type WSO2 = {
  id_token: string;
};

type BootTokens = {
  wso2: WSO2;
};

type BootLocale = {
  isoCurrencyCode: string;
  languageCode: string;
  market: string;
  path?: string;
};

export type BootType = {
  configuration: Config;
  user: BootUser;
  tokens: BootTokens;
  locale: BootLocale;
  shellConfig: ShellConfig;
  isBootLoading: boolean;
  isShellLoading: boolean;
  isShellFetching: boolean;
};

export const initialState: BootType = {
  configuration: {} as Config,
  user: {
    token_type: "",
    access_token: "",
    expires_in: "",
    cacheDateInMS: 0,
    abo: "",
    aff: "",
    partyId: "",
    globalPartyId: "",
    idToken: "",
    isoCountryCode: "",
    cognitoToken: "",
  },
  tokens: {
    wso2: {
      id_token: "",
    },
  },
  locale: {
    isoCurrencyCode: "",
    languageCode: "",
    market: "",
    path: "",
  },
  shellConfig: {} as ShellConfig,
  isBootLoading: false,
  isShellLoading: false,
  isShellFetching: false,
};

const { actions, reducer } = createSlice({
  name: "boot",
  initialState,
  reducers: {
    setBoot: (state, action) => {
      return {
        ...state,
        configuration: { ...state.configuration, ...action.payload.configuration },
        user: { ...state.user, isoCountryCode: action.payload.locale?.market || "US", ...action.payload.user },
        tokens: { ...state.tokens, ...action.payload.tokens },
        locale: { ...state.locale, ...action.payload.locale },
      };
    },
    setUser: (state, action) => {
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
      };
    },
    setShellConfig: (state, action: PayloadAction<ShellConfig>) => {
      return {
        ...state,
        shellConfig: {
          ...state.shellConfig,
          ...action.payload,
        },
      };
    },
    setBootLoading: (state, action) => {
      return {
        ...state,
        isBootLoading: action.payload,
      };
    },
    setShellLoading: (state, action) => {
      return {
        ...state,
        isShellLoading: action.payload.isLoading,
        isShellFetching: action.payload.isFetching,
      };
    },
    setShowOldMpd: (state, action) => {
      return {
        ...state,
        configuration: {
          ...state.configuration,
          navigation: {
            ...state.configuration.navigation,
            showOldMpd: action.payload,
          },
        },
      };
    },
  },
});
export const { setBoot, setUser, setShellConfig, setBootLoading, setShowOldMpd, setShellLoading } = actions;

const bootPersistConfig = {
  key: "boot",
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
};

export default persistReducer<BootType>(bootPersistConfig, reducer);
