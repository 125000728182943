import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderResponse } from "../../services/Customer/CustomerOrderList/customerOrderListAPI.types";
import { upsertOrderGroup, initialState, upsertOrderGroups, resetList } from "../model/order";
import { updatePeriod } from "../period";
import { setBoot } from "../boot";
import {
  resetFilter,
  updateFilterMenuByPeriod,
  updateFilterOption,
  updateFilterOptions,
  updateSearchKeyword,
  updateSelectedFilter,
} from "./filter";
import { resetSort, updateSortOption } from "./sort";

const { reducer, actions } = createSlice({
  name: "orders",
  initialState,
  reducers: {
    updateOrdersList: (state, { payload }: PayloadAction<{ requestId: string; data: OrderResponse }>) => {
      const { requestId, data } = payload;

      if (state.requestId === requestId) {
        const { period, customerOrderList } = data;
        // note: allOrderCount is used to sum all the order counts from each bonus period
        const { orderGroups, allOrderCount } = state;
        const orderGroup = upsertOrderGroup(data, orderGroups);
        const _orderGroups = upsertOrderGroups(period, orderGroups, orderGroup);

        return {
          ...state,
          ...data,
          orderGroups: _orderGroups,
          allOrderCount: allOrderCount + customerOrderList.length,
          isSuccess: true,
          isError: false,
          isFetching: false,
        };
      }
      return state;
    },
    updateIsFetching: (state, { payload }: PayloadAction<{ requestId: string; isFetching: boolean }>) => ({
      ...state,
      ...payload,
      isError: false,
      isSuccess: false,
    }),
    updateIsError: (state, { payload }: PayloadAction<{ requestId: string; isError: boolean }>) => {
      const { requestId, isError } = payload;

      if (state.requestId === requestId) {
        return {
          ...state,
          isError,
          isSuccess: isError === false,
          isFetching: false,
        };
      }

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePeriod, () => resetList());
    builder.addCase(setBoot, () => resetList());
    builder.addCase(updateFilterMenuByPeriod, (state) => resetList(state.requestId));
    builder.addCase(resetFilter, () => resetList());
    builder.addCase(updateFilterOptions, () => resetList());
    builder.addCase(updateFilterOption, () => resetList());
    builder.addCase(updateSearchKeyword, () => resetList());
    builder.addCase(updateSortOption, () => resetList());
    builder.addCase(resetSort, () => resetList());
    builder.addCase(updateSelectedFilter, () => resetList());
  },
});

export const { updateOrdersList, updateIsFetching, updateIsError } = actions;

export default reducer;
