import { useEffect, useState } from "react";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { FggOverviewResponse } from "../../services/GroupGrowth/FrontlineGroupGrowth/frontlineGroupGrowthAPI.types";
import { lambdaErrorCheck } from "../../common/util/errorHandling";
import { useAppSelector } from "../../store";
import { useGetFggOverviewQuery } from "../../services/GroupGrowth/FrontlineGroupGrowth/frontlineGroupGrowthAPI";
import { storedLocale } from "../impersonation/util";
import { MULTIPLIER_DATA_TYPE } from "../../common/enums/requirements";

export const defaultFggOverviewData = (): FggOverviewResponse => {
  return {
    IncomeData: {
      PerformanceBonusLevelPercent: "",
      PotentialPYEarnings: "",
      IncentiveCap: "",
      Status: QUALIFICATION_STATUS.NOT_QUALIFIED,
    },
    AnnualRequirements: {
      VolumeRequirements: [],
      PercentRequirements: [],
      BadgeRequirements: [],
      TotalRequirements: 0,
      RequirementsMet: 0,
      RequirementMetStatuses: [],
    },
    IncentiveData: {
      MultiplierData: [
        {
          Type: MULTIPLIER_DATA_TYPE.INCENTIVE,
          Base: "",
          Multiplier: "",
          Total: "",
        },
      ],
      TrackingQ: "",
      PotentialMultiplier: "",
      PreviousPYFQ: "",
      CurrentYTDFQ: "",
      ShowPreviousViewDetails: false,
    },
    MultiplierMatrix: {
      ColumnHeaders: [],
      Rows: [],
      Legend: "",
    },
    CallResults: [],
    Error: [],
  };
};

export const FetchFggOverviewData = (period: string, isComingSoon: boolean) => {
  const { user, configuration } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetFggOverviewQuery(
    {
      abo: user.abo,
      aff: user.aff,
      period: period,
      isoCountryCode: user.isoCountryCode,
      isoCurrencyCode: shellConfig.isoCurrencyCode,
      locale: storedLocale(),
      baselineStart: configuration?.fgg?.averageBaselineStartingPY || 0,
    },
    {
      skip: isComingSoon,
    },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [fggOverviewData, setFggOverviewData] = useState<FggOverviewResponse>(defaultFggOverviewData());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setFggOverviewData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, fggOverviewData, errorStatus, refetch };
};
