import { AWARD_CODES } from "../../../../common/enums/award-codes";

export const monthlyBonusBreakdownMap = [
  {
    awardCode: AWARD_CODES.PERFORMANCE_BONUS,
    name: "performanceBonus",
  },
  {
    awardCode: AWARD_CODES.PERFPRMANCE_BONUS_PERSONAL,
    name: "personal",
  },
  {
    awardCode: AWARD_CODES.PERFPRMANCE_BONUS_DIFFERENCIAL,
    name: "differential",
  },
  {
    awardCode: AWARD_CODES.CSI,
    name: "csi",
  },
  {
    awardCode: AWARD_CODES.STRONG_START_INCENTIVE,
    name: "strongStartIncentive",
  },
  {
    awardCode: AWARD_CODES.PATH_TO_BRONZE,
    name: "newAboActivation",
  },
  {
    awardCode: AWARD_CODES.BRONZE_FOUNDATION,
    name: "bronzeFoundation",
  },
  {
    awardCode: AWARD_CODES.BRONZE_BUILDER,
    name: "bronzeBuilder",
  },
  {
    awardCode: AWARD_CODES.MN_BRONZE_FOUNDATION,
    name: "bronzeFoundation",
  },
  {
    awardCode: AWARD_CODES.MN_BRONZE_BUILDER,
    name: "bronzeBuilder",
  },
  {
    awardCode: AWARD_CODES.PERFORMANCE_PLUS,
    name: "performancePlus",
  },
  {
    awardCode: AWARD_CODES.PERFORMANCE_ELITE,
    name: "performanceElite",
  },
  {
    awardCode: AWARD_CODES.PERFORMANCE_PLUS_ELITE,
    name: "performancePlusElite",
  },
  {
    awardCode: AWARD_CODES.MONTHLY_PGG,
    name: "personalGroupGrowthMonthly",
  },
  {
    awardCode: AWARD_CODES.RUBY,
    name: "ruby",
  },
  {
    awardCode: AWARD_CODES.LEADERSHIP,
    name: "leadership",
  },
  {
    awardCode: AWARD_CODES.FOSTER,
    name: "foster",
  },
  {
    awardCode: AWARD_CODES.INTERNATIONAL_LEADERSHIP,
    name: "internationalLeadership",
  },
  {
    awardCode: AWARD_CODES.DEPTH,
    name: "depth",
  },
];
