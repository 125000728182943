import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ExportNotificationListResponse } from "../../services/Export/exportAPI.types";

type CustomerExport = {
  tncAgreement: boolean;
  showTnC: boolean;
  isShowExport: boolean;
  orderNotifyReqId: string;
  customerNotifyReqId: string;
  orderExportCount: number;
  customerExportCount: number;
  visibleOrderExportCount: number;
  visibleCustomerExportCount: number;
  requestingCustomerExport: boolean;
  requestingOrderExport: boolean;
};

const initialState: CustomerExport = {
  tncAgreement: false,
  showTnC: false,
  isShowExport: false,
  orderNotifyReqId: "",
  customerNotifyReqId: "",
  orderExportCount: 0,
  customerExportCount: 0,
  visibleOrderExportCount: 0,
  visibleCustomerExportCount: 0,
  requestingCustomerExport: false,
  requestingOrderExport: false,
};

const { actions, reducer } = createSlice({
  name: "customerExport",
  initialState,
  reducers: {
    showExport: (state) => ({
      ...state,
      isShowExport: true,
    }),
    hideExport: (state) => ({
      ...state,
      isShowExport: false,
    }),
    toggleTnC: (state) => ({
      ...state,
      showTnC: !state.showTnC,
    }),
    acceptTnCAgreement: (state) => ({
      ...state,
      tncAgreement: true,
    }),
    declineTnCAgreement: (state) => ({
      ...state,
      tncAgreement: false,
    }),
    updateNotifyReqId: (
      state,
      { payload }: PayloadAction<{ orderNotifyReqId: string } | { customerNotifyReqId: string }>,
    ) => ({
      ...state,
      ...payload,
    }),
    // "getOrderExportNotification" RTK Query Side Effect Reducer
    updateOrderExportCount: (
      state,
      { payload }: PayloadAction<{ orderNotifyReqId: string; data: ExportNotificationListResponse }>,
    ) => {
      const { orderNotifyReqId, data } = payload;
      if (state.orderNotifyReqId === orderNotifyReqId) {
        const { exportCount, visibleExportRequestCount } = data;
        return {
          ...state,
          orderExportCount: exportCount,
          visibleOrderExportCount: visibleExportRequestCount,
        };
      }
      return state;
    },
    // "getCustomerExportNotification" RTK Query Side Effect Reducer
    updateCustomerExportCount: (
      state,
      { payload }: PayloadAction<{ customerNotifyReqId: string; data: ExportNotificationListResponse }>,
    ) => {
      const { customerNotifyReqId, data } = payload;
      if (state.customerNotifyReqId === customerNotifyReqId) {
        const { exportCount, visibleExportRequestCount } = data;
        return {
          ...state,
          customerExportCount: exportCount,
          visibleCustomerExportCount: visibleExportRequestCount,
        };
      }
      return state;
    },
    updateVisibleOrderExportCount: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      visibleOrderExportCount: payload,
    }),
    updateVisibleCustomerExportCount: (state, { payload }: PayloadAction<number>) => ({
      ...state,
      visibleCustomerExportCount: payload,
    }),
    updateRequestingCustomerExport: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      requestingCustomerExport: payload,
    }),
    updateRequestingOrderExport: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      requestingOrderExport: payload,
    }),
  },
});

export const {
  showExport,
  hideExport,
  toggleTnC,
  acceptTnCAgreement,
  declineTnCAgreement,
  updateNotifyReqId,
  updateOrderExportCount,
  updateCustomerExportCount,
  updateVisibleOrderExportCount,
  updateVisibleCustomerExportCount,
  updateRequestingCustomerExport,
  updateRequestingOrderExport,
} = actions;

export default reducer;
