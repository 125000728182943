import { useTranslation } from "react-i18next";
import { DefaultTFuncReturnWithObject } from "i18next";
import defaultContentPGG from "../../../content/annualPGG";
import defaultContentFGG from "../../../content/frontlineGrowth";
import defaultContentPGGGCR from "../../../content/annualGcrPGG";
import defaultContentQCG from "../../../content/qcGrowth";
import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import LeafTable from "../../../common/leaf/LeafTable/LeafTable";
import { MultiplierMatrix, RowItems, RowItemStatus } from "../../../services/GroupGrowth/groupGrowth.types";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import "./MatrixFQModal.scss";

type Props = {
  displayModal: boolean;
  toggleModal: Function;
  incentive: "annualPGG" | "frontlineGrowth" | "annualPGGGCR" | "qcGrowth" | "visionBonus" | "visionBonusQC";
  matrixFQData: {
    matrixFQs: string;
    potentialMultiplier: string;
    performanceYear: string;
    multiplierMatrixData: MultiplierMatrix;
  };
  joinIdenticalRowsVertically?: boolean;
  incentiveContent?: DefaultTFuncReturnWithObject | any;
};

export const MatrixFQModal = ({
  displayModal,
  toggleModal,
  incentive,
  matrixFQData,
  joinIdenticalRowsVertically = false,
  incentiveContent = {},
}: Props) => {
  const { matrixFQs, performanceYear, potentialMultiplier, multiplierMatrixData } = matrixFQData;

  const getDefaultContent = () => {
    switch (incentive) {
      case "annualPGG":
        return { defaultContent: defaultContentPGG, namespace: "annualPGG" };
      case "annualPGGGCR":
        return { defaultContent: defaultContentPGGGCR, namespace: "annualGcrPGG" };
      case "frontlineGrowth":
        return { defaultContent: defaultContentFGG, namespace: "frontlineGrowth" };
      case "qcGrowth":
        return { defaultContent: defaultContentQCG, namespace: "qcGrowth" };
      case "visionBonus":
      case "visionBonusQC":
        return { defaultContent: incentiveContent, namespace: "visionBonus" };
    }
  };

  const { defaultContent, namespace } = getDefaultContent();

  const combinedCells = new Map<string, Set<string>>();
  const combinedStatuses = new Map<string, RowItemStatus>();

  const { t } = useTranslation([namespace]);

  let colWidth = 100 / multiplierMatrixData.ColumnHeaders.length + "%";
  if (incentive === "qcGrowth" || incentive === "visionBonusQC") {
    colWidth = 100 / (multiplierMatrixData.ColumnHeaders.length + 1) + "%";
  }

  const header = multiplierMatrixData.ColumnHeaders.map((col: string) => {
    if (col === "") {
      return {
        value: "",
        width: colWidth,
      };
    }
    return {
      value: `${t(`multiplierMatrix.${col}`, defaultContent.multiplierMatrix[col])}`,
      width: colWidth,
    };
  });

  const getTitleOrDesc = (title: boolean) => {
    if (incentive === "annualPGGGCR") return "";

    if (title) {
      return `${t("multiplierMatrix.title", defaultContent.multiplierMatrix.title, {
        fqs: matrixFQs,
      })}`;
    }

    if (incentive === "visionBonus" || incentive === "visionBonusQC") return "";

    return `${t("multiplierMatrix.description", defaultContent.multiplierMatrix.description, {
      performanceYear: performanceYear,
    })}`;
  };

  const getSubtitle = () => {
    return incentive === "annualPGG" ||
      incentive === "annualPGGGCR" ||
      incentive === "qcGrowth" ||
      incentive === "visionBonus" ||
      incentive === "visionBonusQC"
      ? `${t("multiplierMatrix.potentialMultiplier", defaultContent.multiplierMatrix.potentialMultiplier, {
          multiplier: potentialMultiplier,
        })}`
      : `${t(
          "multiplierMatrix.potentialMultiplier",
          defaultContent.multiplierMatrix.potentialMultiplier,
        )} = ${potentialMultiplier}`;
  };

  const getItemStatusClass = (status: RowItemStatus) => {
    if (status === QUALIFICATION_STATUS.QUALIFIED) {
      return "MatrixFQModal__qualified-item";
    }
    if (status === QUALIFICATION_STATUS.TRACKING) {
      return "MatrixFQModal__tracking-item";
    }
    return "";
  };

  /**
   * Returns content for growMax or maintain "Grow 6+", "Maintain", or "Maintain with Booster"
   * @param item
   * @returns
   */
  const setSingleGrowValueContent = (item: RowItems) => {
    if (item.Range[0] === 0 || item.Range[0] === -1) {
      const itemCellContent = `multiplierMatrix.${item.CellContent}`;
      if (incentive === "annualPGGGCR") {
        return `${t(itemCellContent, defaultContent.multiplierMatrix[item.CellContent], {
          max: item.Range[0],
        })}`;
      }
      return `${t(itemCellContent, String(defaultContent.multiplierMatrix[item.CellContent]))}`;
    }
    if (item.Range[0] > 0) {
      const itemCellContent = `multiplierMatrix.${item.CellContent}`;
      return `${t(itemCellContent, defaultContent.multiplierMatrix[item.CellContent], {
        max: item.Range[0],
      })}`;
    }
  };

  /**
   * Returns content for growRange "Grow 1-5"
   * @param item
   * @returns
   */
  const setMultipleGrowValueContent = (item: RowItems) => {
    const itemCellContent = `multiplierMatrix.${item.CellContent}`;
    return `${t(itemCellContent, defaultContent.multiplierMatrix[item.CellContent], {
      start: item.Range[0],
      end: item.Range[1],
    })}`;
  };

  // Gets the values, indices, and statuses of each cell that should be combined
  const getCombinedCells = (rows: RowItems[][]) => {
    if (rows.length > 0) {
      const numRows = rows.length;
      const numCols = rows[0].length;

      for (let col = 0; col < numCols; col++) {
        let previousValue = null;
        for (let row = 0; row < numRows; row++) {
          const currentValue = rows[row][col].CellContent;
          const status = rows[row][col].Status;
          if (currentValue === previousValue) {
            if (!combinedCells.has(currentValue)) {
              combinedCells.set(currentValue, new Set());
            }
            combinedCells.get(currentValue)!.add(`${row - 1},${col}`);
            combinedCells.get(currentValue)!.add(`${row},${col}`);

            combinedStatuses.set(`${row},${col}`, status);
            if (!combinedStatuses.has(`${row - 1},${col}`)) {
              combinedStatuses.set(`${row - 1},${col}`, status);
            }
          }
          previousValue = currentValue;
        }
      }
    }
  };

  if (joinIdenticalRowsVertically) {
    getCombinedCells(multiplierMatrixData.Rows);
  }

  const rows = multiplierMatrixData.Rows.map((row, rowIndex) => {
    return {
      row: row.map((item, colIndex) => {
        let cellContent: string | undefined;
        let statusClass = getItemStatusClass(item.Status);
        if (item.Range.length > 0) {
          if (item.Range.length === 1) {
            cellContent = setSingleGrowValueContent(item);
          } else if (item.Range.length > 1) {
            cellContent = setMultipleGrowValueContent(item);
          }
        } else {
          cellContent = item.CellContent;
        }
        if (joinIdenticalRowsVertically) {
          if (combinedCells.has(cellContent!)) {
            const indices = combinedCells.get(cellContent!);
            indices?.forEach((key: string) => {
              if (combinedStatuses.has(key) && combinedStatuses.get(key) !== "" && statusClass !== "") {
                statusClass = getItemStatusClass(combinedStatuses.get(key)!);
              }
            });
          }
        }
        return {
          value: <span className={statusClass}>{cellContent}</span>,
        };
      }),
    };
  });

  const getModalLegend = () => {
    if (multiplierMatrixData.Legend) {
      return (
        <div className="MatrixFQModal__multiplier-legend">
          {t(
            `multiplierMatrix.${multiplierMatrixData.Legend}`,
            defaultContent.multiplierMatrix[multiplierMatrixData.Legend],
            {
              kickerMultiplier: "20",
              performanceYear: performanceYear,
            },
          )}
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="MatrixFQModal">
      <LeafModal
        title={getTitleOrDesc(true)}
        subtitle={getSubtitle()}
        description={getTitleOrDesc(false)}
        onCloseCallbackFn={toggleModal}
        open={displayModal}
      >
        <div className="MatrixFQModal">
          <div className="MatrixFQModal__multiplier-section-table">
            {combinedCells.size === 0 ? (
              <LeafTable
                header={header}
                rows={rows}
                columnAlign={"center"}
                headerStyle={"default"}
                hideHeader={false}
              />
            ) : (
              <LeafTable
                header={header}
                rows={rows}
                columnAlign={"center"}
                headerStyle={"default"}
                hideHeader={false}
                combinedCells={combinedCells}
              />
            )}
          </div>
          {getModalLegend()}
        </div>
      </LeafModal>
    </div>
  );
};
