import { useTranslation } from "react-i18next";
import ChartLegend from "../ChartLegend/ChartLegend";
import defaultContent from "../../../../../content/customers";
import PieChartLoader from "../..//Loader/PieChartLoader/PieChartLoader";
import "./CustomerTypeChart.scss";
import PVGreyChart from "../PVGreyChart/PVGreyChart";
import { formattedPV } from "../../../../../components/impersonation/util";
import { LeafErrorMessage } from "../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { customerTypeChartErrorTrack } from "../../../../../common/tealium/Customers/Overview/Chart/CustomerTypeTrack";
import CustomerTypeDonut from "./CustomerTypeDonut/CustomerTypeDonut";

type Props = {
  isLoading: boolean;
  totalMemberTypeCustomer: number;
  totalGuestTypeCustomer: number;
  totalShareBarTypeCustomer: number;
  totalCustomerCount: number;
  isError: boolean;
  period: string;
};

const CustomerTypeChart = (props: Props) => {
  const { t } = useTranslation(["customers"]);
  const {
    isLoading,
    totalMemberTypeCustomer,
    totalGuestTypeCustomer,
    totalShareBarTypeCustomer,
    totalCustomerCount,
    isError,
    period,
  } = props;

  if (isError === true) {
    customerTypeChartErrorTrack(period);
  }

  const getPercentage = (pv: number) => {
    const _percentage = (pv / totalCustomerCount) * 100;
    return isNaN(_percentage) ? "- " : formattedPV(_percentage);
  };

  const totalMemberTypeCustomerPercent = getPercentage(totalMemberTypeCustomer);
  const totalGuestTypeCustomerPercent = getPercentage(totalGuestTypeCustomer);
  const totalShareBarTypeCustomerPercent = getPercentage(totalShareBarTypeCustomer);
  const showGreyChart = totalCustomerCount === 0;

  const getPVChart = () => {
    return showGreyChart ? (
      <PVGreyChart />
    ) : (
      <CustomerTypeDonut
        textContent={{
          legendPreferredCustomerText: `${t(
            "customerTypeChart.legendPreferredCustomerText",
            defaultContent["customerTypeChart"]["legendPreferredCustomerText"],
          )}`,
          legendGuestCustomerText: `${t(
            "customerTypeChart.legendGuestCustomerText",
            defaultContent["customerTypeChart"]["legendGuestCustomerText"],
          )}`,
          legendSharebarCustomerText: `${t(
            "customerTypeChart.legendSharebarCustomerText",
            defaultContent["customerTypeChart"]["legendSharebarCustomerText"],
          )}`,
        }}
        totalMemberTypeCustomer={totalMemberTypeCustomer}
        totalGuestTypeCustomer={totalGuestTypeCustomer}
        totalShareBarTypeCustomer={totalShareBarTypeCustomer}
      />
    );
  };

  const CustomerTypeContainer = () => (
    <div className="customer-type">
      <div className="customer-type__container">
        <div className="customer-type__title">{`${t(
          "customerTypeChart.title",
          defaultContent["customerTypeChart"]["title"],
        )}`}</div>
        <LeafErrorMessage show={isError} />
        {isError === false && (
          <>
            <div className="customer-type__subtitle">
              <span className="customer-type__total">{`${t("total", defaultContent["total"])}`}</span>{" "}
              <span className="customer-type__cpv-value">{totalCustomerCount}</span>{" "}
              <span className="customer-type__cpv">{`${t(
                "customerTypeChart.counts",
                defaultContent["customerTypeChart"]["counts"],
              )}`}</span>
            </div>
            {getPVChart()}
            <ChartLegend
              legends={[
                {
                  id: "totalMemberTypeCustomer",
                  text: `${t(
                    "customerTypeChart.legendPreferredCustomerText",
                    defaultContent["customerTypeChart"]["legendPreferredCustomerText"],
                  )}`,
                  value: t(
                    "customerTypeChart.legendPreferredCustomerValue",
                    defaultContent["customerTypeChart"]["legendPreferredCustomerValue"],
                    {
                      COUNT: totalMemberTypeCustomer,
                      PERCENTAGE: totalMemberTypeCustomerPercent,
                    },
                  ),
                  color: "lightPink",
                },
                {
                  id: "totalGuestTypeCustomer",
                  text: `${t(
                    "customerTypeChart.legendGuestCustomerText",
                    defaultContent["customerTypeChart"]["legendGuestCustomerText"],
                  )}`,
                  value: t(
                    "customerTypeChart.legendGuestCustomerValue",
                    defaultContent["customerTypeChart"]["legendGuestCustomerValue"],
                    {
                      COUNT: totalGuestTypeCustomer,
                      PERCENTAGE: totalGuestTypeCustomerPercent,
                    },
                  ),
                  color: "red",
                },
                {
                  id: "totalShareBarTypeCustomer",
                  text: `${t(
                    "customerTypeChart.legendSharebarCustomerText",
                    defaultContent["customerTypeChart"]["legendSharebarCustomerText"],
                  )}`,
                  value: t(
                    "customerTypeChart.legendSharebarCustomerValue",
                    defaultContent["customerTypeChart"]["legendSharebarCustomerValue"],
                    {
                      COUNT: totalShareBarTypeCustomer,
                      PERCENTAGE: totalShareBarTypeCustomerPercent,
                    },
                  ),
                  color: "vizPink",
                },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );

  return <PieChartLoader isLoading={isLoading} View={CustomerTypeContainer} />;
};

export default CustomerTypeChart;
