import { ReactNode } from "react";
import "./LeafTag.scss";

export type TagColor =
  | "default"
  | "orange"
  | "medOrange"
  | "green"
  | "medGreen"
  | "darkGreen"
  | "purple"
  | "gray"
  | "lightBlue"
  | "blue"
  | "red"
  | "medRed"
  | "darkRed"
  | "lightRed"
  | "lightYellow"
  | "darkOrange";

type Props = {
  variant: TagColor;
  hideTag?: boolean;
  children?: ReactNode;
  classname?: string;
  id?: string;
};

const LeafTag = ({ variant = "default", hideTag = false, children, classname = "", id = "" }: Props) => {
  return (
    <div className={"leaf-tag " + classname} hidden={hideTag} data-testid={`leafTag${id ? `_${id}` : ""}`}>
      <span className={`leaf-tag__${variant}`}>{children}</span>
    </div>
  );
};

export default LeafTag;
