import {
  updateIsError as updateProfileOrderError,
  updateIsFetching as updateProfileOrderFetching,
  updateProfileOrders,
} from "../../../reducers/order/profileOrders";
import {
  updateIsError as updateOrdersListPagesError,
  updateIsFetching as updateOrdersListPagesFetching,
  updateOrdersList as updateOrdersListPages,
} from "../../../reducers/order/orders";
import { accAPI } from "../..";
import { OrderListQueryParams, OrderListRTKQueryParams, OrderResponse } from "./customerOrderListAPI.types";

const getQueryParamsString = ({
  pageNum,
  pageSize,
  period,
  sortValue,
  sortOrder,
  searchBy,
  locale,
  filterOrderByVcs,
  filterOrderBySourceType,
  customerIds,
  confidentialUserText,
  businessNature,
}: OrderListQueryParams) => {
  const parsedParams = new URLSearchParams({
    pageNum: `${pageNum}`,
    pageSize: `${pageSize}`,
    period,
    sortValue,
    sortOrder,
  });

  // optional fields
  if (searchBy !== void 0 && searchBy.length > 0) {
    parsedParams.append("searchBy", searchBy);
  }

  if (locale !== void 0) {
    parsedParams.append("locale", `${locale}`);
  }

  if (customerIds !== void 0) {
    parsedParams.append("customerIds", `${customerIds}`);
  }

  if (filterOrderByVcs !== void 0) {
    parsedParams.append("filterOrderByVcs", `${filterOrderByVcs}`);
  }

  if (filterOrderBySourceType !== void 0) {
    parsedParams.append("filterOrderBySourceType", `${filterOrderBySourceType}`);
  }

  if (confidentialUserText !== void 0 && confidentialUserText.length > 0) {
    parsedParams.append("confidentialUserText", confidentialUserText);
  }

  if (businessNature !== void 0) {
    parsedParams.append("businessNature", businessNature);
  }

  return parsedParams.toString();
};

const getURL = ({ affAbo, ...queryParams }: OrderListRTKQueryParams) =>
  `/customer/v2/${affAbo}/orders?${getQueryParamsString(queryParams)}`;

export const customerOrderListAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getOrderList: build.query<OrderResponse, OrderListRTKQueryParams>({
      query: (params) => getURL(params),
    }),
    // query with profileOrders reducer side-effect
    getProfileOrders: build.query<OrderResponse, OrderListRTKQueryParams>({
      query: (params) => getURL(params),
      async onQueryStarted(_args, { dispatch, queryFulfilled, requestId }) {
        try {
          dispatch(updateProfileOrderFetching({ requestId, isFetching: true }));
          const { data } = await queryFulfilled;
          dispatch(updateProfileOrders({ requestId, data }));
        } catch (error) {
          dispatch(updateProfileOrderError({ requestId, isError: true }));
        }
      },
    }),
    // query with orders reducer side-effect
    getOrderListPages: build.query<OrderResponse, OrderListRTKQueryParams>({
      query: (params) => getURL(params),
      async onQueryStarted(_args, { dispatch, queryFulfilled, requestId }) {
        try {
          dispatch(updateOrdersListPagesFetching({ requestId, isFetching: true }));
          const { data } = await queryFulfilled;
          dispatch(updateOrdersListPages({ requestId, data }));
        } catch (error) {
          dispatch(updateOrdersListPagesError({ requestId, isError: true }));
        }
      },
    }),
  }),
});

export const {
  useGetOrderListQuery,
  useGetProfileOrdersQuery,
  useLazyGetProfileOrdersQuery,
  useLazyGetOrderListPagesQuery,
} = customerOrderListAPI;
