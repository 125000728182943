import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../store";
import { useOverviewPageLoadTrack } from "../../../common/tealium/Customers/Overview/IndexTrack";
import Cards from "./Cards/Cards";
import NewCustomerList from "./NewCustomerList/NewCustomerList";
import ChartSlider from "./PVChart/ChartSlider/ChartSlider";
import RecentOrderList from "./RecentOrderList/RecentOrderList";
import "./Overview.scss";

const Overview = () => {
  useTranslation(["customers"]);
  const { selectedPeriod } = useAppSelector(({ period }) => period);
  // tealium tagging page load event
  useOverviewPageLoadTrack({ period: selectedPeriod });

  return (
    <div className="overview-container">
      <div className="overview-stats">
        <Cards />
      </div>
      <div className="overview-container_row">
        <div className="overview-container_column">
          <NewCustomerList />
        </div>
        <div className="overview-container_column">
          <RecentOrderList />
        </div>
        <div className="overview-container_column">
          <div className="overview__personal-pv">
            <ChartSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
