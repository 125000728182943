import { useTranslation } from "react-i18next";
import LeafNewCustomerTag from "../LeafTags/LeafNewCustomerTag/LeafNewCustomerTag";
import LeafVCSTag from "../LeafTags/LeafVCSTag/LeafVCSTag";
import LeafCustomerTypeTag from "../LeafTags/LeafCustomerTypeTag/LeafCustomerTypeTag";
import LeafPrimaryTag from "../LeafTags/LeafPrimaryTag/LeafPrimaryTag";
import LeafMigratedTag from "../LeafTags/LeafMigratedTag/LeafMigratedTag";
import "./LeafCustomerTags.scss";

type Props = {
  isNewCustomer?: boolean;
  vcsFlag?: boolean;
  businessNatureCode?: number;
  isPrimary?: boolean;
  isNewInCurrentMonth?: boolean;
  isNewMigrated?: boolean;
  selectedPeriod: string;
  showVerifiedTag?: boolean;
  aboNo: string;
  registrationChannelCd: string;
};

const LeafCustomerTags = (props: Props) => {
  useTranslation(["customers"]);
  const {
    isNewCustomer,
    vcsFlag,
    businessNatureCode,
    isPrimary,
    isNewInCurrentMonth,
    isNewMigrated,
    selectedPeriod,
    showVerifiedTag,
    aboNo,
    registrationChannelCd,
  } = props;
  return (
    <div className={`leaf-customer-tags leaf-customer-tags`}>
      <LeafPrimaryTag isPrimary={isPrimary} />
      <LeafNewCustomerTag
        isNewCustomer={isNewCustomer}
        isNewInCurrentMonth={isNewInCurrentMonth}
        isNewMigrated={isNewMigrated}
        selectedPeriod={selectedPeriod}
        aboNo={aboNo}
      />
      <LeafMigratedTag isNewMigrated={isNewMigrated} aboNo={aboNo} />
      {showVerifiedTag && <LeafVCSTag vcsFlag={vcsFlag} />}
      <LeafCustomerTypeTag businessNatureCode={businessNatureCode} registrationChannelCd={registrationChannelCd} />
    </div>
  );
};

export default LeafCustomerTags;
