import { formatAboPV, formatReqVolume } from "@amwaycommon/acc-mixins/l10n";
import { t } from "i18next";
import { ReactNode } from "react";
import { PerformanceProgressBar } from "../../../../../common/components/PerformanceProgressBar/PerformanceProgressBar";
import { MonthlyPerformanceDashData } from "../../../../../services/MonthlyPerformanceDashboard/monthlyPerformanceDashboardAPI.types";
import { useAppSelector } from "../../../../../store";
import { storedLocale } from "../../../../impersonation/util";
import defaultContent from "../../../../../content/monthlyPerformanceDashboard";
import "./MonthlyPerformanceProgressBar.scss";

type Props = {
  monthlyPerformanceData: MonthlyPerformanceDashData;
};

export const MonthlyPerformanceProgressBar = ({ monthlyPerformanceData }: Props) => {
  const locale = storedLocale();
  const { user } = useAppSelector((state) => state.boot);
  const market = user.isoCountryCode;
  const { pvTargetWithOneQleg } = useAppSelector(
    (state) => state.boot.configuration.monthlyDashboard.monthlyPerformance,
  );

  const {
    CorePlanQualified,
    GroupPv,
    CurrentPerformanceBonusVolume,
    CurrentPerformanceBonusLevel,
    NextPerformanceBonusVolume,
    NextPerformanceBonusLevel,
    QualifiedLegs,
    Schedules: { bonusDetails = [] },
  } = monthlyPerformanceData;

  const getCurrentBonusDetails = () => {
    const maxPerfBonusPercent = monthlyPerformanceData.Schedules.bonusDetails[0].Percent;
    if (CorePlanQualified || maxPerfBonusPercent === CurrentPerformanceBonusLevel) {
      const clonedArr = [...bonusDetails];
      const secondHighestBonusSchedule = clonedArr.sort((a, b) => b.Volume - a.Volume)[1] || { Volume: 0, Percent: 0 };
      return {
        currentBonusLevelVolume: formatReqVolume(secondHighestBonusSchedule.Volume, locale, market),
        currentBonusLevelPercent: secondHighestBonusSchedule.Percent,
      };
    }
    return {
      currentBonusLevelVolume: formatReqVolume(CurrentPerformanceBonusVolume, locale, market),
      currentBonusLevelPercent: CurrentPerformanceBonusLevel,
    };
  };

  const { currentBonusLevelVolume, currentBonusLevelPercent } = getCurrentBonusDetails();
  const formattedGroupPV = formatAboPV(GroupPv, locale, market);
  const nextPbv = formatReqVolume(NextPerformanceBonusVolume, locale, market);
  const gpvString = `${t("gpv", defaultContent["gpv"])}`;
  const highestBonusLevelPercent = Math.max(...bonusDetails.map((item) => item.Percent));

  const hasSingleQLeg = QualifiedLegs === 1;
  const hasMultipleQLegs = QualifiedLegs >= 2;
  const startLabel = QualifiedLegs >= 1 ? 0 : currentBonusLevelPercent;
  const endLabel = QualifiedLegs >= 1 ? highestBonusLevelPercent : NextPerformanceBonusLevel;
  const targetValue = hasSingleQLeg ? pvTargetWithOneQleg : NextPerformanceBonusVolume;
  const legInfo = hasSingleQLeg ? `${t("withQLegs", defaultContent["withQLegs"], { qLegs: "1" })}` : undefined;

  const getProgressMilestones = () => {
    if (hasSingleQLeg) {
      const sortedValues = bonusDetails
        .filter(({ Volume }) => Volume <= pvTargetWithOneQleg)
        .map((schedule) => schedule.Volume)
        .sort((a, b) => a - b);

      return sortedValues.map((value) => {
        return { value: value };
      });
    }
    return [];
  };

  const getStartMilestone = () => {
    if (hasSingleQLeg) return { value: 0, label: `${startLabel}%`, subLabel: `0 ${gpvString}` };
    if (CorePlanQualified && hasMultipleQLegs) return { value: 0, label: `${startLabel}%`, subLabel: `0 ${gpvString}` };

    return { value: 0, label: `${startLabel}%`, subLabel: `${currentBonusLevelVolume} ${gpvString}` };
  };

  const getEndMilestone = () => {
    if (CorePlanQualified && hasMultipleQLegs)
      return {
        value: endLabel,
        label: `${endLabel}%`,
        subLabel: `${t("withQLegs", defaultContent["withQLegs"], { qLegs: "2+" })}`,
      };

    let subLabel: ReactNode = `${nextPbv} ${gpvString}`;
    if (legInfo) {
      subLabel = (
        <span className="MonthlyPerformanceProgressBar__oneQlegMilestone">
          <span>{subLabel}</span>
          <span> {legInfo}</span>
        </span>
      );
    }
    return { value: endLabel, label: `${endLabel}%`, subLabel: subLabel };
  };

  const progressMilestones = [getStartMilestone(), ...getProgressMilestones(), getEndMilestone()];

  return (
    <div className="MonthlyPerformanceProgressBar">
      <PerformanceProgressBar
        hideCaret={hasMultipleQLegs}
        progressMilestones={progressMilestones}
        showMaxFill={CorePlanQualified}
        progressValues={{
          current: { actual: GroupPv, display: formattedGroupPV },
          target: { actual: targetValue, display: `${nextPbv} ${gpvString}` },
        }}
        displayMilestoneMarkers={hasSingleQLeg}
      />
    </div>
  );
};
