import LeafProfileLoader from "../../../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import LeafIdleCustomer from "../../../../../../../common/leaf/LeafProfile/LeafIdleCustomer/LeafIdleCustomer";
import { CustomersResponse } from "../../../../../../../services/Customer/CustomerList/customerListAPI.types";

type Props = {
  customerData?: CustomersResponse;
  isFetching: boolean;
  profileABO: string;
};

const IdleCustomerView = (props: Props) => {
  const { isFetching, customerData, profileABO } = props;

  if (isFetching === false && customerData) {
    const { customerList } = customerData;
    const customerDetail = customerList.find(({ affAbo }) => affAbo === profileABO);

    return <LeafIdleCustomer customerDetail={customerDetail} dayCount={30} />;
  }

  return <LeafProfileLoader row={1} card={true} isFetching={isFetching} />;
};

export default IdleCustomerView;
