import "./Order.scss";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../../content/customers";
import LeafDateTime from "../../../../../../common/leaf/LeafDateTime/LeafDateTime";
import { CustomerOrder } from "../../../../../../services/Customer/CustomerOrderList/customerOrderListAPI.types";
import { LeafPV } from "../../../../../../common/leaf/LeafFormat/LeafPV/LeafPV";
import { LeafBV } from "../../../../../../common/leaf/LeafFormat/LeafBV/LeafBV";
import { isProgramActive } from "../../../../../../common/util/checkProgramActiveStatus";
import { useAppSelector } from "../../../../../../store";
import { AWARD_CODES } from "../../../../../../common/enums/award-codes";
import LeafABOName from "../../../../../../common/leaf/LeafProfile/LeafABOName/LeafABOName";
import OrderType from "./OrderType/OrderType";

type Props = {
  order: CustomerOrder;
  isCompact?: boolean;
  orderIndex: number;
  showVCSOrderTag: boolean;
};

const Order = (props: Props) => {
  const { order, isCompact, orderIndex, showVCSOrderTag } = props;
  const {
    name,
    localName,
    isoLanguageCode,
    privacyFlag,
    orderNumber,
    date,
    pv,
    bv,
    vcsFlag,
    vcsReasons,
    source,
    aboNo,
  } = order;

  const { eligibleIncentives } = useAppSelector(({ boot }) => boot.shellConfig);
  const { t } = useTranslation(["customers"]);

  return (
    <div className={`order ${isCompact ? "order--compact" : ""}`}>
      <div className="order__content">
        <div className="order__header order__display--mobile">
          <div className="order__order-date">
            <LeafDateTime
              date={date}
              options={{ month: "long", day: "numeric", year: "numeric" }}
              ignoreTimeZone={true}
            />
          </div>
          <div className="order__order-number">
            {`${t("order", defaultContent["order"])}`} #{orderNumber}
          </div>
        </div>
        <div className="order__abo-name">
          <LeafABOName
            name={name}
            localName={localName}
            privacyFlag={privacyFlag}
            languageCode={isoLanguageCode}
            aboNo={aboNo}
          />
        </div>
        <div className="order__order-date order__display--desktop">
          <LeafDateTime
            date={date}
            options={{ month: "long", day: "numeric", year: "numeric" }}
            ignoreTimeZone={true}
          />
        </div>
        <div className="order__order-number order__display--desktop">#{orderNumber}</div>
        <div className="order__footer">
          <div className="order__pv">
            <span className="order__pv-value">
              <LeafPV pv={pv} />
            </span>
            <span className="order__display">
              &nbsp;
              {vcsFlag === true ? `${t("vcsPv", defaultContent["vcsPv"])}` : `${t("pv", defaultContent["pv"])}`}
            </span>
          </div>
          <div className="order__bv order__bv__right order__display--desktop">
            <LeafBV bv={bv} />
          </div>
          <div className="order__order-type">
            <OrderType
              vcsFlag={vcsFlag}
              source={source}
              vcsReasons={vcsReasons}
              isCompact={isCompact}
              orderIndex={orderIndex}
              orderDate={date}
              showVCSOrderTag={isProgramActive(eligibleIncentives, AWARD_CODES.CSI, showVCSOrderTag)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
