import { useTranslation } from "react-i18next";
import { IconCarrot } from "../../../../common/icons/IconCarrot/IconCarrot";
import { AboTileData, Requirement } from "../../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import { LeafCheckmarksBase } from "../../../../common/leaf/LeafCheckmarks/LeafCheckmarksBase/LeafCheckmarksBase";
import LeafTag from "../../../../common/leaf/LeafTag/LeafTag";
import defaultContent from "../../../../content/bronzeGroupTracking";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import "./TrackingTile.scss";
import {
  PERFORMANCE_BONUS,
  PPV,
  THREE_LEGS_6_PERCENT,
  TWO_LEGS_6_PERCENT_WITH_VCS,
  THREE_LEGS_3_PERCENT,
  TWO_LEGS_3_PERCENT_WITH_VCS,
  VCS_PERCENT,
  VCS_PV,
  PCPV,
} from "../../../../common/constants/requirements-constants";
import { BRONZE_TAGS } from "../../../../reducers/model/bgtFilters";
import { BGTTags, GetABODisplayName, isReq } from "../../bronzeGroupTrackingUtils";
import breakpoints from "../../../../common/scss/breakpoints.module.scss";

export const TrackingTile = ({
  eligibilityTag,
  aboName,
  aboLocalName,
  isoLanguage,
  aboNumber,
  affNumber,
  reqTrackingTag,
  closeNewTag,
  incentiveTag,
  requirements,
  graduated,
  graduationPeriod,
  restarted,
}: AboTileData) => {
  const { t } = useTranslation(["bronzeGroupTracking"]);
  const { width } = useWindowSize();

  const getReqTitle = (requirement: Requirement) => {
    switch (true) {
      case isReq(requirement, PERFORMANCE_BONUS):
        return t("performanceBonus", defaultContent["performanceBonus"]);
      case isReq(requirement, PPV):
        return t("ppv", defaultContent["ppv"]);
      case isReq(requirement, PCPV):
        return t("pcpv", defaultContent["pcpv"]);
      case isReq(requirement, THREE_LEGS_6_PERCENT):
        return t("threeLegs6", defaultContent["threeLegs6"]);
      case isReq(requirement, TWO_LEGS_6_PERCENT_WITH_VCS):
        return t("twoLegs6", defaultContent["twoLegs6"]);
      case isReq(requirement, TWO_LEGS_3_PERCENT_WITH_VCS):
        return t("twoLegs3", defaultContent["twoLegs3"]);
      case isReq(requirement, THREE_LEGS_3_PERCENT):
        return t("threeLegs3", defaultContent["threeLegs3"]);
      case isReq(requirement, VCS_PV):
        return t("vcsPv", defaultContent["vcsPv"]);
      case isReq(requirement, VCS_PERCENT):
        return t("vcs", defaultContent["vcs"]);
      default:
        return "";
    }
  };

  requirements = requirements.map((req) => {
    return { ...req, text: getReqTitle(req).toString() };
  });

  const BGTLeafTags = () => {
    return (
      <>
        {[closeNewTag, reqTrackingTag, eligibilityTag].map((tag) =>
          BGTTags(tag, false, tag === BRONZE_TAGS.IN_WINDOW || tag === BRONZE_TAGS.PRE_WINDOW),
        )}
        {BGTTags("", restarted)}
      </>
    );
  };

  const EligibilityTag = () => <p>{`${t(`${eligibilityTag}`, defaultContent[eligibilityTag])}`}</p>;

  const IncentiveTag = () => (
    <LeafTag hideTag={!incentiveTag} variant={incentiveTag === BRONZE_TAGS.BRONZE_BUILDER ? "darkRed" : "medRed"}>{`${t(
      `${incentiveTag}`,
      defaultContent[incentiveTag],
    )}`}</LeafTag>
  );

  const AboTrackingTileMobile = () => {
    return (
      <div className="aboTrackingTile aboTrackingTile__display--mobile">
        <div className="aboTrackingTile__group">
          <div className="aboTrackingTile__incentiveTags">
            <EligibilityTag />
            <IncentiveTag />
          </div>
          <div className="aboTrackingTile__aboName">
            <p>{<GetABODisplayName aboName={aboName} aboLocalName={aboLocalName} aboIsoLanguage={isoLanguage} />}</p>
            <LeafCheckmarksBase requirements={requirements} />
          </div>
          <div className="aboTrackingTile__aboNum">
            <p>#{aboNumber}</p>
          </div>
          <div className="aboTrackingTile__infoTags">
            <BGTLeafTags />
          </div>
        </div>

        <div className="aboTrackingTile__arrow">
          <IconCarrot orientation="right" color="black" />
        </div>
      </div>
    );
  };

  const AboTrackingTileDesktop = () => {
    return (
      <div className="aboTrackingTile aboTrackingTile__display--desktop">
        <div className="aboTrackingTile__aboNameNum aboTrackingTile__cell aboTrackingTile__cell--name">
          <span className="aboTrackingTile__aboName">
            {<GetABODisplayName aboName={aboName} aboLocalName={aboLocalName} aboIsoLanguage={isoLanguage} />}
          </span>
          <span className="aboTrackingTile__aboNum">#{aboNumber}</span>
        </div>
        <div className="aboTrackingTile__incentiveTags aboTrackingTile__cell">
          <IncentiveTag />
        </div>

        <div className="aboTrackingTile__infoTags aboTrackingTile__cell aboTrackingTile__cell--tags">
          <BGTLeafTags />
        </div>

        <div className="aboTrackingTile__cell aboTrackingTile__cell--progress">
          <LeafCheckmarksBase requirements={requirements} />
        </div>

        <div className="aboTrackingTile__cell aboTrackingTile__cell--eligibility">
          <EligibilityTag />
        </div>

        <div className="aboTrackingTile__arrow">
          <IconCarrot orientation="right" color="black" />
        </div>
      </div>
    );
  };

  return (
    <>{width && Number(width) < Number(breakpoints.desktop) ? <AboTrackingTileMobile /> : <AboTrackingTileDesktop />}</>
  );
};
