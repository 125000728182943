import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "..";
import { LSOverviewResponse } from "./leadershipSeminarAPI.types";

type LeadershipSeminarReqParams = {
  abo: string;
  aff: string;
  locale: string;
  period: string;
  isoCountryCode: string;
  isoCurrencyCode: string;
};

export const lsiAPI = createApi({
  reducerPath: "lsiAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getLsiOverview: builder.query<LSOverviewResponse, LeadershipSeminarReqParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/lsi/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const { useGetLsiOverviewQuery } = lsiAPI;
