import "./ImpersonationTable.scss";

type TableHeader = {
  value: React.ReactNode | string;
  width?: string;
};

type RowColumn = {
  value: React.ReactNode | string;
  height?: string;
  width?: string;
  columnAlign?: "left" | "right" | "center" | undefined;
};

type TableRow = {
  row: RowColumn[];
};

export type LeafTableProps = {
  header: TableHeader[];
  rows: TableRow[];
  columnAlign: "left" | "right" | "center";
  hideHeader?: boolean;
  rowHeight?: string;
};

export const ImpersonationTable = ({ header, rows, columnAlign }: LeafTableProps) => {
  return (
    <div className="ImpersonationTable__container">
      <table className="ImpersonationTable">
        <thead className="ImpersonationTable__header">
          <tr>
            {header.map((item) => (
              <th key={`${item.value}`}>{item.value}</th>
            ))}
          </tr>
        </thead>
      </table>
      <div className="ImpersonationTable__bodyContainer">
        <table className="ImpersonationTable">
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr className="ImpersonationTable__row" key={rowIndex}>
                {row.row.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    style={{
                      textAlign: column.columnAlign || columnAlign,
                      height: column.height,
                      padding: column.columnAlign ? "0px 20px" : undefined,
                      width: column.height ? column.height : "auto",
                    }}
                  >
                    {column.value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
