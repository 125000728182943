import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IncentiveCalculation } from "../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconAdd } from "../../../common/icons/IconAdd/IconAdd";
import { IconEquals } from "../../../common/icons/IconEquals/IconEquals";
import { IconPlainTable } from "../../../common/icons/IconPlainTable/IconPlainTable";
import defaultContent from "../../../content/visionBonus";
import LeafFilterButton from "../../../common/leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import "./VisionBonusFormula.scss";
import {
  BonusAwardDetail,
  MultiplierBaseDetailsData,
  MultiplierBaseResponse,
} from "../../../services/GroupGrowth/FrontlineGroupGrowth/frontlineGroupGrowthAPI.types";
import { AWARD_CODES } from "../../../common/enums/award-codes";
import { DetailsListModel } from "../../../common/components/Modals/DetailsListModal/DetailsListModal";
import { MatrixFQModal } from "../../../common/components/MatrixFQModal/MatrixFQModal";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";

type VisionBonusFormula = {
  data: {
    fq: {
      isFqLoading: boolean;
      isFqExpanded: boolean;
      basis: { amountCurrency: string };
      multiplier: { amountCurrency: string };
      bonus: { amountCurrency: string };
    };
    qc: {
      isQcLoading: boolean;
      isQcExpanded: boolean;
      basis: { amountCurrency: string };
      multiplier: { amountCurrency: string };
      bonus: { amountCurrency: string };
    };
  };
};

const VisionBonusFormula = ({ data }: VisionBonusFormula) => {
  const { t } = useTranslation(["visionBonus"]);
  const [showBaseModal, setShowBaseModal] = useState(false);
  const [showMultiFQModal, setShowMultiFQModal] = useState(false);
  const [showMultiQCModal, setShowMultiQCModal] = useState(false);
  const [showFQ, setShowFQ] = useState(true);
  const performanceYear = "25";

  const { fq, qc } = data || {};
  const { isFqLoading, isFqExpanded, basis: fqs, multiplier: fqm, bonus: fqb } = fq || {};
  const { isQcLoading, isQcExpanded, basis: qcs, multiplier: qcm, bonus: qcb } = qc || {};

  const onBaseCallback = () => {
    setShowBaseModal(true);
  };

  const onModalBaseClose = () => {
    setShowBaseModal(false);
  };

  const onMultiFQCallback = () => {
    setShowMultiFQModal(true);
  };

  const onModalMultiFQClose = () => {
    setShowMultiFQModal(false);
  };

  const onMultiQCCallback = () => {
    setShowMultiQCModal(true);
  };

  const onModalMultiQCClose = () => {
    setShowMultiQCModal(false);
  };

  const RenderFQ = () => {
    return (
      <IncentiveCalculation
        isLoading={isFqLoading}
        isExpanded={isFqExpanded}
        base={{
          title: t("incentiveCalc.fqIncentive.base", defaultContent.incentiveCalc.fqIncentive.base).toString(),
          amount: fqs.amountCurrency,
          icon: <IconPlainTable />,
          iconAriaLabel: t("incentiveCalc.iconAriaLabel", defaultContent.incentiveCalc.iconAriaLabel).toString(),
          callbackFn: onBaseCallback,
        }}
        multiplier={{
          title: t(
            "incentiveCalc.fqIncentive.multipler",
            defaultContent.incentiveCalc.fqIncentive.multipler,
          ).toString(),
          amount: fqm.amountCurrency,
          icon: <IconPlainTable />,
          iconAriaLabel: t("incentiveCalc.iconAriaLabel", defaultContent.incentiveCalc.iconAriaLabel).toString(),
          callbackFn: onMultiFQCallback,
          symbolIcon: <IconAdd />,
        }}
        total={{
          title: t("incentiveCalc.fqIncentive.total", defaultContent.incentiveCalc.fqIncentive.total).toString(),
          amount: fqb.amountCurrency,
          symbolIcon: <IconEquals />,
        }}
      />
    );
  };

  const RenderQC = () => {
    return (
      <IncentiveCalculation
        isLoading={isQcLoading}
        isExpanded={isQcExpanded}
        base={{
          title: t("incentiveCalc.qcIncentive.base", defaultContent.incentiveCalc.qcIncentive.base).toString(),
          amount: qcs.amountCurrency,
          icon: <IconPlainTable />,
          iconAriaLabel: t("incentiveCalc.iconAriaLabel", defaultContent.incentiveCalc.iconAriaLabel).toString(),
          callbackFn: onBaseCallback,
        }}
        multiplier={{
          title: t(
            "incentiveCalc.qcIncentive.multipler",
            defaultContent.incentiveCalc.qcIncentive.multipler,
          ).toString(),
          amount: qcm.amountCurrency,
          icon: <IconPlainTable />,
          iconAriaLabel: t("incentiveCalc.iconAriaLabel", defaultContent.incentiveCalc.iconAriaLabel).toString(),
          callbackFn: onMultiQCCallback,
          symbolIcon: <IconAdd />,
        }}
        total={{
          title: t("incentiveCalc.qcIncentive.total", defaultContent.incentiveCalc.qcIncentive.total).toString(),
          amount: qcb.amountCurrency,
          symbolIcon: <IconEquals />,
        }}
      />
    );
  };

  const mockMultiplierBaseResponseData: MultiplierBaseResponse = {
    MultiplierBaseDetails: [
      {
        MultiplierType: "foundersPlatinumAbove",
        BonusAwards: [
          {
            BonusAward: 30,
            BonusTotal: "$10",
          },
          {
            BonusAward: 40,
            BonusTotal: "$10",
          },
          {
            BonusAward: 50,
            BonusTotal: "$10",
          },
          {
            BonusAward: 4,
            BonusTotal: "$10",
          },
          {
            BonusAward: 20,
            BonusTotal: "$10",
          },
        ],
        Achieved: false,
        DisplaySectionHeader: false,
      },
      {
        MultiplierType: "diamondAbove",
        BonusAwards: [],
        Achieved: false,
        DisplaySectionHeader: false,
      },
    ],
    Status: "TRACKING",
    CallResults: [],
    Error: "",
  };

  const getAwardContentKey = (awardNumber: number) => {
    switch (awardNumber) {
      case AWARD_CODES.LEADERSHIP:
        return "leadershipBonus";
      case AWARD_CODES.FOSTER:
        return "fosterBonus";
      case AWARD_CODES.PERFORMANCE_BONUS:
        return "performanceBonus";
      case AWARD_CODES.RUBY:
        return "rubyBonus";
      case AWARD_CODES.DEPTH:
        return "depthBonus";
      default:
        return "";
    }
  };

  const createRows = (bonusAwardDetail: BonusAwardDetail[]) => {
    if (bonusAwardDetail && bonusAwardDetail.length > 0) {
      const rows = new Array(bonusAwardDetail.length);
      bonusAwardDetail.forEach((_, index) => {
        const bonusAward = getAwardContentKey(bonusAwardDetail[index].BonusAward);
        const bonusAwardContent = `${t(
          `multiplierBaseModal.${bonusAward}`,
          defaultContent["multiplierBaseModal"][`${bonusAward}`],
          {
            performanceYear: performanceYear,
          },
        )}`;
        rows[index] = {
          row: [
            { value: bonusAwardContent, columnAlign: "left" },
            { value: bonusAwardDetail[index].BonusTotal, columnAlign: "left" },
          ],
        };
      });
      return rows;
    }
    return [];
  };

  return (
    <>
      <div className={"vision-bonus__content__calculation__container"}>
        <div className={"vision-bonus__content__calculation__header__container"}>
          <h2 className={"vision-bonus__content__calculation__header__container__header"}>
            {`${t("incentiveCalc.sectionHeader", defaultContent.incentiveCalc.sectionHeader)}`}
          </h2>
          <span className={"vision-bonus__content__calculation__header__container__sub-header"}>
            {`${t("incentiveCalc.sectionDescription", defaultContent.incentiveCalc.sectionDescription)}`}
          </span>
        </div>
        <div className={"vision-bonus__content__calculation__menu"}>
          <LeafFilterButton
            active={showFQ}
            text={t("incentiveCalc.fqIncentive.fqGrowth", defaultContent.incentiveCalc.fqIncentive.fqGrowth).toString()}
            onButtonClick={() => setShowFQ(true)}
          />
          <LeafFilterButton
            active={!showFQ}
            text={t("incentiveCalc.qcIncentive.qcGrowth", defaultContent.incentiveCalc.qcIncentive.qcGrowth).toString()}
            onButtonClick={() => setShowFQ(false)}
          />
        </div>
        <div className={"vision-bonus__content__calculation__content"}>{showFQ ? <RenderFQ /> : <RenderQC />}</div>
      </div>
      {/* TODO */}
      <DetailsListModel
        multiplierBaseReponseData={{
          isLoading: false,
          errorStatus: false,
          refetch: () => {},
        }}
        modalIsOpen={showBaseModal}
        toggleModal={onModalBaseClose}
        totalMultiplierBase={{
          label: showFQ
            ? `${t("multiplierBaseModal.totalBonusLabel", defaultContent["multiplierBaseModal"]["totalBonusLabel"])}`
            : `${t(
                "multiplierBaseModal.monthlyCoreBonusTotal",
                defaultContent["multiplierBaseModal"]["monthlyCoreBonusTotal"],
              )}`,
          amount: "$50",
        }}
        title={`${t("multiplierBaseModal.title", defaultContent["multiplierBaseModal"]["title"], {
          performanceYear: performanceYear,
        })}`}
        tagStatus={mockMultiplierBaseResponseData.Status}
        table={mockMultiplierBaseResponseData.MultiplierBaseDetails.map((base: MultiplierBaseDetailsData) => {
          const rows = createRows(base.BonusAwards);
          return {
            sectionHeaderIcon: true,
            sectionHeaderText: `${t(`FqQcStatusModal.fqStatus`, defaultContent["FqQcStatusModal"]["fqStatus"])}`,
            achieved: base.Achieved,
            displaySectionHeader: base.DisplaySectionHeader,
            tableHeader:
              rows.length > 0
                ? [
                    {
                      value: t("multiplierBaseModal.bonus", defaultContent["multiplierBaseModal"]["bonus"], {}),
                      width: "70%",
                      padding: "0",
                    },
                    {
                      value: t("multiplierBaseModal.amount", defaultContent["multiplierBaseModal"]["amount"], {}),
                      width: "30%",
                    },
                  ]
                : [],
            tableRows: rows,
          };
        })}
      />
      <div>
        <MatrixFQModal
          displayModal={showMultiFQModal}
          toggleModal={onModalMultiFQClose}
          incentive={"visionBonus"}
          incentiveContent={t("defaultContent.fq", {
            returnObjects: true,
            defaultValue: defaultContent.fq,
          })}
          matrixFQData={{
            matrixFQs: "190",
            potentialMultiplier: "30%",
            performanceYear: "24",
            multiplierMatrixData: {
              ColumnHeaders: ["fqOverBaseline", "belowDiamond", "diamondPlus"],
              Rows: [
                [
                  {
                    CellContent: "growMax",
                    Range: [6],
                    Status: QUALIFICATION_STATUS.QUALIFIED,
                  },
                  {
                    CellContent: "40%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.QUALIFIED,
                  },
                  {
                    CellContent: "70%",
                    Range: [],
                    Status: "",
                  },
                ],
                [
                  {
                    CellContent: "growRange",
                    Range: [1, 5],
                    Status: QUALIFICATION_STATUS.TRACKING,
                  },
                  {
                    CellContent: "30%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.TRACKING,
                  },
                  {
                    CellContent: "50%",
                    Range: [],
                    Status: "",
                  },
                ],
              ],
              Legend: "",
            },
          }}
        />
      </div>
      <div>
        <MatrixFQModal
          displayModal={showMultiQCModal}
          toggleModal={onModalMultiQCClose}
          incentive={"visionBonus"}
          incentiveContent={t("defaultContent.qc", {
            returnObjects: true,
            defaultValue: defaultContent.qc,
          })}
          matrixFQData={{
            matrixFQs: "190",
            potentialMultiplier: "30%",
            performanceYear: "24",
            multiplierMatrixData: {
              ColumnHeaders: ["garAward", "qcRange", "qcMax"],
              Rows: [
                [
                  {
                    CellContent: "executiveDiamond",
                    Range: [6],
                    Status: QUALIFICATION_STATUS.QUALIFIED,
                  },
                  {
                    CellContent: "30%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                  {
                    CellContent: "40%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                ],
                [
                  {
                    CellContent: "doubleDiamond",
                    Range: [1, 5],
                    Status: QUALIFICATION_STATUS.NOT_QUALIFIED,
                  },
                  {
                    CellContent: "35%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                  {
                    CellContent: "50%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                ],
                [
                  {
                    CellContent: "tripleDiamond",
                    Range: [0],
                    Status: QUALIFICATION_STATUS.TRACKING,
                  },
                  {
                    CellContent: "50%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                  {
                    CellContent: "70%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                ],
                [
                  {
                    CellContent: "crown",
                    Range: [0],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                  {
                    CellContent: "75%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                  {
                    CellContent: "100%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                ],
                [
                  {
                    CellContent: "crownAmbassador",
                    Range: [0],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                  {
                    CellContent: "100%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                  {
                    CellContent: "130%",
                    Range: [],
                    Status: QUALIFICATION_STATUS.NOT_TRACKING,
                  },
                ],
              ],
              Legend: "footnote",
            },
          }}
        />
      </div>
      {/* TODO */}
    </>
  );
};

export default VisionBonusFormula;
