import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { lambdaErrorCheck } from "../../../../common/util/errorHandling";
import { useGetInternationalBusinessesQuery } from "../../../../services/MonthlyPerformanceDashboard/monthlyPerformanceDashboardAPI";
import { MonthlyDashIntlBusinessesData } from "../../../../common/interfaces/monthlyPerformanceDash";
import { storedLocale } from "../../../impersonation/util";
import { Business } from "../../../../services/BusinessSelector/businessSelectorAPI.types";
import { mergeIntlBusinessesData } from "./InternationalBusinessUtil";

export const defaultIntlBusinessesData = (): MonthlyDashIntlBusinessesData => {
  return {
    CallResult: [
      {
        responseBody: "",
        url: "",
        error: null,
        status: 0,
        duration: 0,
      },
    ],
    Businesses: new Array(8).fill({}),
    Error: null,
  };
};

export const FetchInternationalBusinessesData = () => {
  const { configuration, user } = useAppSelector((state) => state.boot);
  const { businessSelectorData } = useAppSelector((state) => state.businessSelectorData);

  const { selectedPeriod } = useAppSelector((store) => store.period);
  const affAboList: string[] = businessSelectorData[0].abo
    ? businessSelectorData.map((item: Business) => item.aff + "-" + item.abo.slice(1))
    : [];
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetInternationalBusinessesQuery(
    {
      baseUrl: configuration.monthlyDashboardApiUrl,
      abo: user.abo,
      aff: user.aff,
      period: selectedPeriod,
      aboBusinesses: affAboList,
      locale: storedLocale(),
      market: user.isoCountryCode,
    },
    {
      skip: !affAboList.length,
    },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [intlBusinessesData, setIntlBusinessesData] = useState(defaultIntlBusinessesData());

  useEffect(() => {
    setIsLoading(isFetching || !affAboList.length);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setIntlBusinessesData(mergeIntlBusinessesData(payloadData, businessSelectorData));
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, selectedPeriod, isError, payloadData]);

  return { isLoading, intlBusinessesData, errorStatus, refetch };
};
