import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import defaultContent from "../../../../content/customers";
import "./NewCustomerList.scss";
import ListLoader from "../Loader/ListLoader/ListLoader";
import NewCustomersStatistic from "../Statistic/NewCustomersStatistic/NewCustomersStatistic";
import CustomerProfile from "../../../CustomerProfile/CustomerProfile";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { updateSelectedFilter } from "../../../../reducers/customer/filter";
import { CUSTOMER_BASE_PATH, CUSTOMER_LIST } from "../../../../routing/CustomersRouting";
import useGetNewCustomerList from "../../../../common/hooks/useGetNewCustomerList";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import {
  recentJoinedViewAllClickTrack,
  recentJoinedErrorTrack,
} from "../../../../common/tealium/Customers/Overview/RecentlyJoinedTrack";
import { LeafErrorMessage } from "../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { includeGuestCustomerBN } from "../../../../reducers/model/customerList";
import NewCustomerItem from "./NewCustomerItem/NewCustomerItem";

const NewCustomerList = () => {
  const { t } = useTranslation(["customers"]);
  const dispatch = useAppDispatch();

  const boot = useAppSelector((state) => state.boot);
  const affAbo = getLoginAffAbo({ boot });
  const enableGuestCustomer = boot?.configuration?.globalCML?.enableGuestCustomer;

  const {
    nextPageNum,
    customerList,
    pageNum,
    isFetching = false,
    isError = false,
  } = useAppSelector(({ newCustomers }) => newCustomers);
  const { selectedPeriod } = useAppSelector(({ period }) => period);

  // display max 2 records
  const displayCustomerList = [...customerList].splice(0, 2);

  // condition to auto fetch data from background without user action
  const showViewAll = customerList.length !== 0;
  const haveNextPage = nextPageNum !== null;
  const notCurrentPage = pageNum !== nextPageNum;
  const autoFetchCount = customerList.length < 2;
  const autoFetch = haveNextPage && notCurrentPage && autoFetchCount && isFetching === false && isError === false;

  const queryParams = {
    affAbo,
    period: selectedPeriod,
    pageNum: nextPageNum,
    pageSize: 5,
    sortOrder: "DESC",
    sortValue: "LAST_ORDER_DATE",
    isNew: true,
    ...includeGuestCustomerBN(enableGuestCustomer),
  };

  useGetNewCustomerList({
    ...queryParams,
    autoFetch,
  });

  const applyFilters = () => {
    dispatch(updateSelectedFilter("newCustomer"));
    recentJoinedViewAllClickTrack();
  };

  if (isError === true) {
    recentJoinedErrorTrack(selectedPeriod);
  }

  const NewCustomerListView = () => {
    return (
      <div className="new-customer">
        <CustomerProfile />
        <div className="new-customer__container">
          <h3 className="new-customer__title">{`${t(
            "customerOverview.newCustomerList.title",
            defaultContent["customerOverview"]["newCustomerList"]["title"],
          )}`}</h3>
          {showViewAll && (
            <div className="new-customer__view-all" onClick={applyFilters}>
              <Link to={`${CUSTOMER_BASE_PATH}${CUSTOMER_LIST}`}>{`${t(
                "customerOverview.viewAll",
                defaultContent["customerOverview"]["viewAll"],
              )}`}</Link>
            </div>
          )}
        </div>
        <div className="new-customer__subtitle">
          <NewCustomersStatistic />
        </div>
        <LeafErrorMessage show={isError} />
        <div className="new-customer__list">
          {displayCustomerList.map(
            ({
              affAbo,
              name,
              lastOrderDate,
              totalCustomerPV,
              totalVCSPV,
              isNew,
              isNewInCurrentMonth,
              isNewMigrated,
              registrationChannelCd,
            }) => (
              <NewCustomerItem
                key={affAbo}
                affAbo={affAbo}
                name={name}
                lastOrderDate={lastOrderDate}
                totalCustomerPV={totalCustomerPV}
                totalVCSPV={totalVCSPV}
                selectedPeriod={selectedPeriod}
                isNew={isNew}
                isNewInCurrentMonth={isNewInCurrentMonth}
                isNewMigrated={isNewMigrated}
                registrationChannelCd={registrationChannelCd}
              />
            ),
          )}
        </div>
      </div>
    );
  };

  return <ListLoader isLoading={isFetching} View={NewCustomerListView} />;
};

export default NewCustomerList;
