import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LeafBannerToast } from "../../../common/leaf/LeafBannerToast/LeafBannerToast";
import { IconLightning } from "../../../common/icons/IconLightning/IconLightning";
import { useAppSelector } from "../../../store";
import defaultContent from "../../../content/bronzeIncentives";
import { AWARD_CODES } from "../../../common/enums/award-codes";
import "./BronzeBannerToast.scss";

const BronzeBannerToast = () => {
  const { t } = useTranslation(["bronzeIncentives"]);
  const { isRestartProgram, awardCode, eligibility, isFetching } = useAppSelector((state) => state.bronzeIncentive);

  const { months = [], graduated } = eligibility || {};

  const [showBanner, setShowBanner] = useState(true);

  const isDataReady = isFetching === false;
  const isValidRestartProgram = isRestartProgram && months.length > 1;
  const show = isDataReady && isValidRestartProgram && showBanner && graduated === false;

  const hideBanner = () => {
    setShowBanner(false);
  };

  const getContentType = () => {
    switch (awardCode) {
      case AWARD_CODES.RESTART_BRONZE_FOUNDATION:
      case AWARD_CODES.RESTART_TWO_BRONZE_FOUNDATION:
        return "BFI";
      case AWARD_CODES.RESTART_BRONZE_BUILDER:
      case AWARD_CODES.RESTART_TWO_BRONZE_BUILDER:
        return "BBI";
    }
  };

  const contentType = getContentType();

  return (
    <div className="bronze-banner-toast">
      <LeafBannerToast theme="blue" show={show} closeFunction={hideBanner}>
        <div className="bronze-banner-toast__message">
          <IconLightning />
          <p>
            {t(`bronzeToastMessage.${contentType}`, defaultContent["bronzeToastMessage"][`${contentType}`], {
              noOfMonths: months.length,
            })}
          </p>
        </div>
      </LeafBannerToast>
    </div>
  );
};

export default BronzeBannerToast;
