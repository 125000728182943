import { useEffect, useState } from "react";
import { storedLocale } from "../impersonation/util";
import { useAppSelector } from "../../store";
import { lambdaErrorCheck } from "../../common/util/errorHandling";
import { useGetLsiOverviewQuery } from "../../services/LeadershipSeminar/leadershipSeminarAPI";
import { LSOverviewResponse } from "../../services/LeadershipSeminar/leadershipSeminarAPI.types";
import { lsOverviewResponseInitial } from "./mockLSData";

export const FetchLSOverviewData = (period: string) => {
  const { user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetLsiOverviewQuery({
    abo: user.abo,
    aff: user.aff,
    period: period,
    isoCountryCode: user.isoCountryCode,
    isoCurrencyCode: shellConfig.isoCurrencyCode,
    locale: storedLocale(),
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [lsOverviewData, setLsOverviewData] = useState<LSOverviewResponse>(lsOverviewResponseInitial);

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setLsOverviewData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, lsOverviewData, errorStatus, refetch };
};
