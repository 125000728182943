import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setBoot } from "../../boot";
import { FilterOption, FilterState, getFilterMenu, getUpdatedFilterGroups } from "../../model/filters";
import { initialStateBTG, updateBronzeGroupList } from "../bronzeGroupTracking";
import { AboTileData } from "../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import {
  FilterBTGOption,
  findSearchResults,
  filterByTags,
  BRONZE_GROUP_FILTERS,
  getUpdatedBGTMenuConfig,
  initialTrackingFilterGroup,
} from "../../model/bgtFilters";

const initialGraduatedFilterGroups = initialTrackingFilterGroup.map((group) => ({
  ...group,
  disabled: group.group !== BRONZE_GROUP_FILTERS.INCENTIVE,
}));

const intialFilterMenu = getFilterMenu(initialGraduatedFilterGroups) as FilterBTGOption[];

interface BronzeGroupFilterState extends FilterState {
  filteredGraduatedData: AboTileData[];
  graduatedTilesData: AboTileData[];
}

const initialState: BronzeGroupFilterState = {
  showFilterMenu: false,
  reset: true,
  filterGroups: initialGraduatedFilterGroups,
  filterMenu: intialFilterMenu,
  quickFilterMenu: [],
  searchKeyword: "",
  filterCount: 0,
  filterLastUpdatedAt: 0,
  filteredGraduatedData: [] as AboTileData[],
  graduatedTilesData: initialStateBTG.initialGraduatedTilesData,
};

const { actions, reducer } = createSlice({
  name: "BGTGraduatedFilter",
  initialState,
  reducers: {
    toggleFilterMenu: (state) => ({
      ...state,
      showFilterMenu: !state.showFilterMenu,
    }),

    // resets the filter to default options
    resetGraduatedFilter: (state) => {
      const { filterMenu, filterGroups } = state;
      const _filterMenu = filterMenu.map((filter) => ({
        ...filter,
        checked: false,
      }));

      const { graduatedTilesData, searchKeyword } = state;
      return {
        ...state,
        ...initialState,
        ...getUpdatedFilterGroups(filterGroups, _filterMenu),
        graduatedTilesData,
        searchKeyword,
        showFilterMenu: state.showFilterMenu,
        filteredGraduatedData: findSearchResults(searchKeyword, graduatedTilesData),
      };
    },
    //reset search
    resetGraduatedSearch: (state) => {
      const selectedOptions = state.filterMenu.filter((option) => option.checked) as FilterBTGOption[];
      return {
        ...state,
        searchKeyword: "",
        // check if there are selected filters and apply changes
        filteredGraduatedData:
          selectedOptions.length > 0
            ? filterByTags(selectedOptions, state.graduatedTilesData)
            : state.graduatedTilesData,
      };
    },

    // updates the filter options after clicking on apply filters
    updateFilterOptions: (state, action: PayloadAction<FilterBTGOption[]>) => {
      const { payload } = action;
      const { filterGroups } = state;
      const updatedFilters = getUpdatedFilterGroups(filterGroups, payload);
      return {
        ...state,
        ...updatedFilters,
      };
    },
    // search bronze group tracking
    updateSearchKeyword: (state, action: PayloadAction<string>) => {
      return { ...state, searchKeyword: action.payload, filterLastUpdatedAt: new Date().getTime() };
    },

    // search bronze group tracking list by abo number or name
    searchBronzeGroupTracking: (state, action: PayloadAction<{ searchKeyword: string }>) => {
      const { searchKeyword } = action.payload;
      return {
        ...state,
        filteredGraduatedData: findSearchResults(
          searchKeyword,
          state.filterCount > 0 ? state.filteredGraduatedData : state.graduatedTilesData,
        ),
      };
    },

    filterGraduatedBGT: (state) => {
      const selectedOptions = state.filterMenu.filter((option) => option.checked) as FilterBTGOption[];
      // if we have a search keyword let's use the filtered state otherwise use non-filtered data
      const searchResults = state.searchKeyword.length > 0 ? state.filteredGraduatedData : state.graduatedTilesData;
      return {
        ...state,
        filteredGraduatedData:
          selectedOptions.length > 0 ? filterByTags(selectedOptions, searchResults) : searchResults,
      };
    },

    // updates single filter option, applicable for quickFilterMenu
    updateFilterOption: (state, action: PayloadAction<FilterOption>) => {
      const { payload } = action;
      const { filterGroups } = state;
      return {
        ...state,
        ...getUpdatedFilterGroups(filterGroups, [payload]),
      };
    },

    // Resets the existing filter and applies the given filter
    updateSelectedFilter: (state, action: PayloadAction<string>) => {
      const { payload } = action;
      const { filterMenu, filterGroups } = state;

      const _filterMenu = filterMenu.map((filter) => ({
        ...filter,
        checked: filter.id === payload,
      }));

      return {
        ...state,
        ...getUpdatedFilterGroups(filterGroups, _filterMenu),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBronzeGroupList, (state, action) => {
      const { initialGraduatedTilesData } = action.payload;
      state.graduatedTilesData = initialGraduatedTilesData;
      state.filteredGraduatedData = initialGraduatedTilesData;
    });

    builder.addCase(setBoot, (state, { payload }) => {
      const { filters, hideTabGroups = [] } = payload.configuration.bronzeGroupTracking.graduated;
      const { filterGroups, filterMenu } = state;

      const enableMenu = getUpdatedBGTMenuConfig(filters, filterMenu, "graduated");
      const updatedFilters = getUpdatedFilterGroups(
        filterGroups.filter(({ group }) => !hideTabGroups.includes(group)),
        enableMenu,
      );
      return {
        ...state,
        ...updatedFilters,
        filterLastUpdatedAt: new Date().getTime(),
      };
    });
  },
});

export const {
  toggleFilterMenu,
  resetGraduatedFilter,
  resetGraduatedSearch,
  updateFilterOption,
  updateFilterOptions,
  updateSearchKeyword,
  searchBronzeGroupTracking,
  filterGraduatedBGT,
  updateSelectedFilter,
} = actions;

export default reducer;
