import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CustomersResponse } from "../../services/Customer/CustomerList/customerListAPI.types";
import { initialState, resetList } from "../model/customerList";
import { updatePeriod } from "../period";

const { reducer, actions } = createSlice({
  name: "newCustomers",
  initialState,
  reducers: {
    updateCustomerList: (state, { payload }: PayloadAction<{ requestId: string; data: CustomersResponse }>) => {
      const { requestId, data } = payload;
      if (state.requestId === requestId) {
        return {
          ...state,
          ...data,
          customerList: [...state.customerList, ...data.customerList],
          isSuccess: true,
          isError: false,
          isFetching: false,
        };
      }
      return state;
    },
    updateIsFetching: (state, { payload }: PayloadAction<{ requestId: string; isFetching: boolean }>) => ({
      ...state,
      ...payload,
      isError: false,
      isSuccess: false,
    }),
    updateIsError: (state, { payload }: PayloadAction<{ requestId: string; isError: boolean }>) => {
      const { requestId, isError } = payload;
      if (state.requestId === requestId) {
        return {
          ...state,
          requestId,
          isError,
          isSuccess: isError === false,
          isFetching: false,
        };
      }
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePeriod, () => resetList());
  },
});

export const { updateCustomerList, updateIsError, updateIsFetching } = actions;

export default reducer;
