import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/customers";
import "./LeafVCSTag.scss";

type Props = {
  vcsFlag?: boolean;
};

const LeafVCSTag = (props: Props) => {
  const { vcsFlag } = props;
  const { t } = useTranslation(["customers"]);

  switch (vcsFlag) {
    case true:
      return (
        <span className="tag-vcs">{`${t("customerTags.verified", defaultContent["customerTags"]["verified"])}`}</span>
      );
    case false:
      return (
        <span className="tag-non-vcs">{`${t(
          "customerTags.nonVerified",
          defaultContent["customerTags"]["nonVerified"],
        )}`}</span>
      );
    default:
      return <></>;
  }
};

export default LeafVCSTag;
