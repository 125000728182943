import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "..";
import {
  AnnualIncentiveTrackingReqParams,
  AnnualIncentiveTrackingResponse,
  AnnualKeyMetricsParams,
  AnnualKeyMetricsResponse,
  BaseRequestParams,
  BonusBreakdownData,
  BonusBreakdownReqParams,
  FoundersPlatinumProgressReqParams,
  FoundersPlatinumProgressResponse,
  FoundersPlatinumTrackingResponse,
} from "./annualPerformanceDashboardAPI.types";

export const annualPerformanceDashboardAPI = createApi({
  reducerPath: "annualPerformanceDashboardAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getAnnualKeyMetrics: builder.query<AnnualKeyMetricsResponse, AnnualKeyMetricsParams>({
      query: (params) => {
        const { abo, aff, period, performanceYear, kpiList, locale, market } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          CurrentPeriod: period,
          PerformanceYear: performanceYear,
          KpiList: kpiList.join(","),
          Locale: locale,
          Market: market,
        });

        return `/key-metrics?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
    getFoundersPlatinumTracking: builder.query<FoundersPlatinumTrackingResponse, BaseRequestParams>({
      query: (params) => {
        const { abo, aff, period } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: period,
        });

        return `/founders-platinum/tracking?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
    getFoundersPlatinumProgress: builder.query<FoundersPlatinumProgressResponse, FoundersPlatinumProgressReqParams>({
      query: (params) => {
        const {
          abo,
          aff,
          period,
          isoCountryCode,
          gpvMethodMonthsRequired,
          vepvMethodMonthsRequired,
          spMethodMonthsRequired,
        } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: period,
          IsoCountryCode: isoCountryCode,
          GpvMethodMonthsRequired: gpvMethodMonthsRequired.toString(),
          VepvMethodMonthsRequired: vepvMethodMonthsRequired.toString(),
          SpMethodMonthsRequired: spMethodMonthsRequired.toString(),
        });

        return `/founders-platinum/progress?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
    getAnnualBonusBreakdown: builder.query<BonusBreakdownData, BonusBreakdownReqParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode, persona, eligibleIncentives } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
          Persona: persona.toString(),
          EligibleIncentives: eligibleIncentives.join(","),
        });

        return `/bonus-breakdown?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
    getAnnualIncentiveTracking: builder.query<AnnualIncentiveTrackingResponse, AnnualIncentiveTrackingReqParams>({
      query: (params) => {
        const { abo, aff, period, eligibleIncentives, locale, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
          EligibleIncentives: eligibleIncentives.join(","),
        });

        return `/annual-incentive-tracking?${queryParams}`;
      },
      extraOptions: { urlBase: "annualDashboard" },
    }),
  }),
});

export const {
  useGetAnnualKeyMetricsQuery,
  useGetFoundersPlatinumTrackingQuery,
  useGetFoundersPlatinumProgressQuery,
  useGetAnnualBonusBreakdownQuery,
  useGetAnnualIncentiveTrackingQuery,
} = annualPerformanceDashboardAPI;
