import LeafIncentiveEligibilityBanner from "../../../../common/leaf/LeafIncentiveEligibilityBanner/LeafIncentiveEligibilityBanner";
import { showEligibilitySlider } from "../../../../reducers/bronzeIncentive";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { viewEligibilityButtonOnClick } from "../../../../common/tealium/BronzeIncentive/BronzeFoundation/ViewEligibilityButtonTrack";
import { BronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";

type Props = {
  data: BronzeOverviewResponse;
  show?: boolean;
  isFetching: boolean;
};

const BFIEligibilityBannerTile = ({ data, show = true, isFetching = true }: Props) => {
  const dispatch = useAppDispatch();
  const { eligibility } = data;
  const {
    eligible = false,
    graduated = false,
    remainingIncentiveEarnings = 0,
    endPeriod = 0,
    notStarted = false,
  } = eligibility || {};

  const eligibleButtonOnClick = () => {
    dispatch(showEligibilitySlider());
    viewEligibilityButtonOnClick();
  };

  return (
    <LeafIncentiveEligibilityBanner
      module={"bfi"}
      graduated={graduated}
      eligible={eligible}
      remainingIncentiveEarnings={remainingIncentiveEarnings}
      endPeriod={`${endPeriod}`}
      eligibleButtonOnClick={eligibleButtonOnClick}
      isLoading={isFetching}
      show={show}
      notStarted={notStarted}
    />
  );
};
export default BFIEligibilityBannerTile;
