const content: { [key: string]: any } = {
  pageTitle: "Frontline Group Growth+",
  frontlineGroupGrowth: "Frontline Growth Incentive+",
  confidentialUser: "Confidential User",
  bannerTile: {
    notTracking: "You are not currently tracking to achieve the Frontline Growth Incentive+ for PY{performanceYear}.",
    tracking: "Keep going to earn the Frontline Growth Incentive+!",
    earned: "Great Job! You are earning the Frontline Growth Incentive+.",
    noLongerEligible:
      "It is no longer possible for you to achieve the Frontline Growth Incentive+ for PY{performanceYear}.",
    description:
      "Earn a Frontline Q (FQ) each month for every in-market frontline ABO who qualified at the {performanceBonus} Performance Bonus level. Increase or maintain your total FQs each year to earn more.",
    potentialEarnings: "Potential PY{performanceYear} Earnings",
    incentiveCap: "Incentive is capped at {bonusCap}",
    manuallyApproved: "The Frontline Growth Incentive+ has been manually approved",
    contactCustomerService: "If you require more information, please contact Customer Support for further details.",
    held: "Your award is on hold.",
    denied: "Your PY{performanceYear} business is not eligible for the Frontline Growth+ Incentive.",
    newPY: "The year ahead belongs to you",
    newPYDesc:
      "Happy new Performance Year! Information for the new performance year is coming soon. Updated information will become available when last performance year's baselines have been confirmed.",
  },
  progressTile: {
    title: "Track your progress",
    requirementLabel: "You've met <1>{current} of {needed}</1> requirements to earn this incentive.",
  },
  incentiveCalc: {
    sectionHeader: "How is this incentive calculated?",
    incentive: {
      base: "Multiplier Base",
      multipler: "Current Incentive Multiplier",
      total: "Potential PY{performanceYear} Frontline Growth Incentive+",
    },
    leg: {
      base: "Additional Platinum Leg Bonus",
      multipler: "Platinum Legs Over Previous",
      total: "Current Total",
    },
  },
  multiplierDetails: {
    titleExpand: "View Multiplier Details",
    titleCollapse: "Hide Multiplier Details",
    previousPy: "Previous Performance Year",
    baseline: "Baseline",
    currentYtd: "Current YTD",
    viewMatrix: "View Multiplier Matrix",
    viewDetails: "View Details",
  },
  requirements: {
    sectionHeader: "How do I qualify? Here is the list of annual requirements.",
    personal: {
      title: "{volume} Annual PPV",
      description: "",
      definition: "Annual PPV excludes PV from Top-up transactions.",
    },
    ruby: {
      title: "{volume} Annual Ruby PV",
      description: "",
    },
    basisCheckForFoundersPlatinumWithEq: {
      title: "Qualified Founders Platinum Pin or Above",
      description: "Must qualify Founders Platinum to earn this incentive.",
    },
    basisCheckForPlatinumWithEq: {
      title: "Qualified Platinum Pin or Above",
      description: "Must qualify Platinum to get this incentive",
    },
    ytdVcsPercentageCheckForfrontlineGrowthIncentive: {
      title: "60% Culmulative VCS",
      decription: "",
    },
    growthOrMaintainCheckForsilver: {
      title: "Maintain Pin",
      description: "Must achieve the previous year's pin or higher. In Market or GAR pin level can be used.",
    },
    leadershipAndFoster: {
      title: "Minimum Annual Leadership Bonus of {bonusAmount}",
      description: "Must achieve this Leadership Bonus to get this incentive.",
    },
  },
  multiplierBase: {
    title: "PY{performanceYear} Multiplier Base",
    foundersPlatinumAbove: "Founders Platinum and above",
    platinumAbove: "Platinum and above",
    diamondAbove: "Diamond and above",
    bonus: "Bonus",
    amount: "Amount",
    leadershipBonus: "Leadership Bonus",
    fosterBonus: "Foster Leadership Bonus",
    performanceBonus: "Performance Bonus",
    rubyBonus: "Ruby Bonus",
    depthBonus: "Depth Bonus",
    totalMultiplierBase: "Total Multiplier Base",
  },
  multiplierMatrix: {
    title: "Tracking for {fqs} FQs",
    potentialMultiplier: "Potential Multiplier",
    description: "(Based on legs with 1 or more in market qualified months in PY{performanceYear})",
    numFqsPriorYear: "Number of FQs compared to prior year",
    annualMultiplierNonFe: "Annual Multiplier for non-Founders Emerald Bonus Recipients",
    annualMultiplierNonFeMobile: "Annual Multiplier for non-F.E Bonus Recipients",
    annualMultiplierFe: "Annual Multiplier for Founders Emerald Bonus Recipient",
    fqOverPreviousPy: "FQ over Previous Year",
    fqOverBaseline: "FQ over Baseline",
    foundersPlatFoundersEmerald: "F. Plat - F. Emerald",
    foundersPlatFoundersSapphire: "F. Plat - F. Sapphire",
    emeraldFoundersEmerald: "Emerald - F. Emerald*",
    faaPointsTracking: "FAA Points Tracking",
    diamondPlus: "Diamond+",
    diamondPlusDisclaimer: "Diamond+*",
    platToFEmerald: "Platinum - F. Emerald",
    growMax: "Grow {max}+",
    growRange: "Grow {start}-{end}",
    maintain: "Maintain",
    multiplier: "Multiplier",
    kickerLabel:
      "+Additional {kickerMultiplier}% Kicker for PY{performanceYear} only for Founders Diamond Bonus Recipients who maintain FQs.",
    emeraldDiamondLabel: "*Must be Emerald or Diamond Bonus Recipient or lower pin multiplier will be used.",
    additionalPercentPaidLabel:
      "For 100% payment, 80% will be paid this PY, and an additional 20% will be paid next PY if the benefit conditions are met.",
  },
  additionalVolumeTag: "Additional {volume} Needed",
  onTrackTag: "On Track",
  achievedTag: "Achieved",
  veTrackingTag: "VE Tracking",
  fPtLegTag: "F. PT Leg",
  notTracking: "Not Tracking",
  currentFqs: "{fqs} FQs",
  previousFqs: "{fqs} FQs",
  pyLabel: "PY{performanceYear}",
  average: "Average",
  abo: "ABO",
  total: "Total",
};

export default content;
