import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store";
import { HATSummaryAPIResponse } from "./hatSummaryAPI.types";

type HATSummaryAPIReqParams = {
  baseUrl: string;
  aboNo: string;
  affNo: string;
  period: string;
  overridePeriod: boolean;
};

export const hatSummaryAPI = createApi({
  reducerPath: "hatSummaryAPI",
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      const { user, configuration } = (getState() as RootState).boot;
      if (user && user.access_token) {
        headers.set("Authorization", `Bearer ${user.access_token}`);
        headers.set("x-mashery-domain", configuration.requestUrlBase);
        headers.set("Content-Type", "application/json");
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getHATSummary: builder.query<HATSummaryAPIResponse, HATSummaryAPIReqParams>({
      query: (params) => ({
        url: `${params.baseUrl}/hat/hatsummary?aboNo=${params.aboNo}&affNo=${params.affNo}&bonusPeriod=${params.period}&overridePeriod=${params.overridePeriod}`,
      }),
    }),
  }),
});

export const { useGetHATSummaryQuery } = hatSummaryAPI;
