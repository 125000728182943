import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/customers";
import "./LeafPrimaryTag.scss";

type Props = {
  isPrimary?: boolean;
};

const LeafPrimaryTag = (props: Props) => {
  const { isPrimary } = props;
  const { t } = useTranslation(["customers"]);

  return isPrimary ? (
    <span className="tag-primary">{`${t("customerTags.primary", defaultContent["customerTags"]["primary"])}`}</span>
  ) : (
    <></>
  );
};

export default LeafPrimaryTag;
