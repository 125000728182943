import { useTranslation } from "react-i18next";
import { ReactEventHandler } from "react";
import { IconArrowLink } from "../../../../icons/IconArrowLink/IconArrowLink";
import LeafApplicantItem from "../LeafApplicantItem/LeafApplicantItem";
import { ABOInfo, PartyInfo, ProfileInfo } from "../../../../../services/Profile/profileAPI.types";
import "./LeafApplicantCard.scss";
import "../LeafApplicantItem/LeafApplicantItem.scss";
import defaultContent from "../../../../../content/leafApplicantCard";

type Props = {
  aboInfo: ABOInfo;
  partyInfo: PartyInfo[];
  viewAllLinkOnclick: ReactEventHandler;
  applicantTrackOnclick: Function;
  profileInfo: Partial<ProfileInfo>;
};

const LeafApplicantCard = (props: Props) => {
  const { t } = useTranslation(["leafApplicantCard"]);

  const { aboInfo, partyInfo, viewAllLinkOnclick, applicantTrackOnclick, profileInfo } = props;
  const { privacyFlag } = aboInfo;
  const primaryParty = partyInfo.find(({ isPrimary }) => isPrimary === true) || partyInfo[0];
  const numberOfApplicant = partyInfo.length;

  const ViewAllLink = () =>
    privacyFlag === false ? (
      <div className="leaf-applicant-card__view-all">
        <div className="leaf-applicant-card__line"></div>
        <div className="leaf-applicant-card__footer" onClick={viewAllLinkOnclick} tabIndex={0}>
          <div className="leaf-applicant-card__footer--view-link">
            {t("viewAllLink", defaultContent["viewAllLink"], { NUMBER: numberOfApplicant })}
          </div>
          <div>
            <IconArrowLink />
          </div>
        </div>
      </div>
    ) : (
      <></>
    );

  return numberOfApplicant > 0 ? (
    <div className="leaf-applicant-card">
      <LeafApplicantItem
        aboInfo={aboInfo}
        partyInfo={primaryParty}
        isExpandable={false}
        applicantTrackOnclick={applicantTrackOnclick}
        profileInfo={profileInfo}
      />
      <ViewAllLink />
    </div>
  ) : (
    <></>
  );
};

export default LeafApplicantCard;
