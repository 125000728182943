import { ReactNode, useEffect, useRef } from "react";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  handleOnClose: Function;
  focusedElement?: HTMLElement;
}

export const FocusTrap = (props: Props) => {
  const { handleOnClose, children, focusedElement = null, ...htmlAttributes } = props;
  const focusTrapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const focusTrap = focusTrapRef.current;

    if (focusTrap) {
      let activeElementBeforeOpen: HTMLElement | null = document.activeElement as HTMLElement;

      // Function to get all focusable elements excluding those with aria-disabled="true"
      const getFocusableElements = () => {
        return Array.from(
          focusTrap?.querySelectorAll<HTMLElement>(
            'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
          ) || [],
        ).filter((el) => el.getAttribute("aria-disabled") !== "true");
      };

      let focusableElements = getFocusableElements();

      // Function to focus the first element
      const focusFirstElement = () => {
        const focusableElements = getFocusableElements();
        if (focusedElement) {
          focusedElement.focus();
        } else if (focusableElements.length > 0) {
          focusableElements[0].focus();
        }
      };

      // Handle keyboard events
      const handleKeyDown = (event: KeyboardEvent) => {
        focusableElements = getFocusableElements();
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
        const activeElement = document.activeElement as HTMLElement;

        if (event.key === "Tab") {
          if (event.shiftKey) {
            if (activeElement === firstElement) {
              event.preventDefault();
              lastElement.focus();
            }
          } else {
            if (activeElement === lastElement) {
              event.preventDefault();
              firstElement.focus();
            }
          }
        } else if (event.key === "Escape") {
          const backButton = document.querySelectorAll('[aria-label="Back"]')[0] as HTMLButtonElement;
          if (backButton) {
            // Click the "Back" button instead of closing the modal
            backButton.click();
          } else if (activeElement.tagName === "LI") {
            (activeElement.closest("ul")?.querySelector(".selected") as HTMLButtonElement)?.click();
          } else {
            // Close the modal
            handleOnClose();
          }
        }
      };

      document.addEventListener("keydown", handleKeyDown);

      // Save the currently focused element before opening the trap
      activeElementBeforeOpen = document.activeElement as HTMLElement;

      // Focus the first element inside the trap when it mounts
      focusFirstElement();

      return () => {
        document.removeEventListener("keydown", handleKeyDown);

        // Restore focus to the element that was focused before opening the trap
        if (activeElementBeforeOpen && activeElementBeforeOpen !== document.body) {
          activeElementBeforeOpen.focus();
        }
      };
    }
  }, [children, focusedElement, handleOnClose]);

  return (
    <section {...htmlAttributes} ref={focusTrapRef} tabIndex={-1}>
      {children}
    </section>
  );
};

export default FocusTrap;
