import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import defaultContent from "../../../../content/customers";
import "./RecentOrderList.scss";
import ListLoader from "../Loader/ListLoader/ListLoader";
import OrderStatistic from "../Statistic/OrderStatistic/OrderStatistic";
import { CUSTOMER_BASE_PATH, CUSTOMER_ORDER } from "../../../../routing/CustomersRouting";
import { updateSelectedFilter } from "../../../../reducers/order/filter";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useGetOrderListQuery } from "../../../../services/Customer/CustomerOrderList/customerOrderListAPI";
import { getLoginAffAbo } from "../../../../reducers/model/boot";
import { SORTBY_DESC_ORDER, SORTBY_ORDER_DATE } from "../../../../reducers/model/sort";
import {
  recentOrdersViewAllClickTrack,
  recentOrderErrorTrack,
} from "../../../../common/tealium/Customers/Overview/RecentOrdersTrack";
import { LeafErrorMessage } from "../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { includeGuestCustomerBN } from "../../../../reducers/model/order";
import RecentOrderItem from "./RecentOrderItem/RecentOrderItem";

const RecentOrderList = () => {
  const { t } = useTranslation(["customers"]);

  const dispatch = useAppDispatch();

  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });
  const eligibleIncentives = useAppSelector((state) => state.boot?.shellConfig?.eligibleIncentives);
  const enableGuestCustomer = useAppSelector((state) => state.boot?.configuration?.globalCML?.enableGuestCustomer);
  const showVCSOrderTag = useAppSelector((state) => state.boot?.configuration?.globalCML?.orderList?.showVCSOrderTag);
  const selectedPeriod = useAppSelector(({ period }) => period?.selectedPeriod);

  const applyFilters = () => {
    dispatch(updateSelectedFilter("order"));
    recentOrdersViewAllClickTrack();
  };

  const queryParams = {
    period: selectedPeriod,
    pageNum: 1,
    pageSize: 2,
    sortOrder: SORTBY_DESC_ORDER,
    sortValue: SORTBY_ORDER_DATE,
    filterOrderBySourceType: "003",
    ...includeGuestCustomerBN(enableGuestCustomer),
  };

  const { data, isFetching, isError } = useGetOrderListQuery({ affAbo, ...queryParams }) || {};
  const { customerOrderList } = data || {};
  const showViewAll = customerOrderList && customerOrderList?.length !== 0;

  if (isError === true) {
    recentOrderErrorTrack(selectedPeriod);
  }

  const RecentOrderListView = () => (
    <div className="recentOrderList">
      <div className="recentOrderList__header">
        <div className="recentOrderList__header-leftDiv">
          <div className="recentOrderList__header-title">{`${t(
            "customerOverview.recentOrderList.title",
            defaultContent["customerOverview"]["recentOrderList"]["title"],
          )}`}</div>
        </div>
        <div className="recentOrderList__header-rightDiv">
          {showViewAll && (
            <div className="recentOrderList__header-view" onClick={applyFilters}>
              <Link to={`${CUSTOMER_BASE_PATH}${CUSTOMER_ORDER}`}>{`${t(
                "customerOverview.viewAll",
                defaultContent["customerOverview"]["viewAll"],
              )}`}</Link>
            </div>
          )}
        </div>
      </div>

      <div className="recentOrderList__subHeader">
        <div className="recentOrderList__subHeader-text">
          <OrderStatistic />
        </div>
      </div>
      <LeafErrorMessage show={isError} />
      <ul className="recentOrderList__card">
        {customerOrderList?.map((customerOrder, index) => (
          <RecentOrderItem
            key={index}
            orderIndex={index}
            customerOrder={customerOrder}
            showVCSOrderTag={showVCSOrderTag}
            eligibleIncentives={eligibleIncentives}
          />
        ))}
      </ul>
    </div>
  );

  return <ListLoader isLoading={isFetching} View={RecentOrderListView} />;
};

export default RecentOrderList;
