import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { BadgeRequirementTile } from "../../../../common/components/RequirementTiles/BadgeRequirementTile/BadgeRequirementTile";
import { VolumeRequirementTile } from "../../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";
import { IconPlainTable } from "../../../../common/icons/IconPlainTable/IconPlainTable";
import { createLoadingTiles } from "../LSRequirements";
import { LSOverviewResponse } from "../../../../services/LeadershipSeminar/leadershipSeminarAPI.types";
import defaultContent from "../../../../content/leadershipSeminar";
import { VcsPercentRequirementTile } from "../../../../common/components/RequirementTiles/VcsPercentRequirementTile/VcsPercentRequirementTile";
import { FQsComparisonTile } from "../../FQsComparisonTile/FQsComparisonTile";
import "./MonthlyAnnualLayout.scss";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import TEALIUM from "../../../../common/enums/tealium-enums";

type Props = {
  lsOverviewResponse: LSOverviewResponse;
  isLoading: boolean;
};

const getDesktopSubHeaders = (useMobileLayout: boolean, translate: TFunction) => {
  if (!useMobileLayout) {
    return (
      <div className="MonthlyAnnual__sub-headers">
        <h4>
          {translate(
            `requirements.monthlyRequirements`,
            defaultContent["requirements"]["monthlyRequirements"] as string,
          )}
        </h4>
        <h4>
          {translate(`requirements.annualRequirements`, defaultContent["requirements"]["annualRequirements"] as string)}
        </h4>
      </div>
    );
  }
  return <></>;
};

const getVerticalLine = (useMobileLayout: boolean) => {
  if (!useMobileLayout) {
    return (
      <div className="MonthlyAnnual__vertical-line">
        <svg xmlns="http://www.w3.org/2000/svg" width="1" height="310" viewBox="0 0 1 310" fill="none">
          <path d="M0.5 0V310" stroke="#E4E4E4" />
        </svg>
      </div>
    );
  }
};

export const MonthlyAnnualLayout = ({ lsOverviewResponse, isLoading }: Props) => {
  const { t } = useTranslation("leadershipSeminar");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { MonthlyRequirements, AnnualRequirements, secondTime } = lsOverviewResponse;
  const { width = 0 } = useWindowSize();
  const useMobileLayout = width < 1024;

  const onClickVolumeRequirementTileButton = () => {
    openModal();
  };

  const openModal = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      `${TEALIUM.SECTION.MONTHLY_GPV_STATUS_MODAL}_open`,
      TEALIUM.CATEGORY.LEADERSHIP_SEMINAR,
      "",
      "monthly_gpv_status",
    );
  };

  const closeModal = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      `${TEALIUM.SECTION.MONTHLY_GPV_STATUS_MODAL}_close`,
      TEALIUM.CATEGORY.LEADERSHIP_SEMINAR,
      "",
      "monthly_gpv_status",
    );
  };

  if (isLoading) {
    return createLoadingTiles(1);
  }

  return (
    <div className="MonthlyAnnual">
      {getDesktopSubHeaders(useMobileLayout, t)}
      <div className="MonthlyAnnual__tiles-section">
        <section className="MonthlyAnnual__Monthly">
          {useMobileLayout ? (
            <h4>
              {t(`requirements.monthlyRequirements`, defaultContent["requirements"]["monthlyRequirements"] as string)}
            </h4>
          ) : (
            <></>
          )}
          <div className="MonthlyAnnual__Monthly--tiles">
            {MonthlyRequirements.MonthlyRubyRequirement.map((requirement) => {
              return (
                <section
                  className={`MonthlyAnnual__tile ${
                    secondTime && !useMobileLayout ? "MonthlyAnnual__centerAlign" : ""
                  }`}
                  key={requirement.Name}
                  data-testid={requirement.Name + "-Tile"}
                >
                  <VolumeRequirementTile
                    isLoading={false}
                    title={t(
                      `requirements.${requirement.Name}.title`,
                      defaultContent["requirements"][requirement.Name]["title"] as string,
                      {
                        volumeMonthlyGpv: requirement.Volume.Target.Formatted,
                      },
                    )}
                    currentAmount={requirement.Volume.Current.Actual}
                    targetAmount={requirement.Volume.Target.Actual}
                    met={requirement.RequirementsMet.NoLegPVMet}
                    circleCheckmarkId={requirement.Name}
                    volumeType={requirement.Name}
                    labels={[requirement.Volume.Current.Formatted, requirement.Volume.Remaining.Formatted]}
                    showLabelsOnly={true}
                    additionalNeeded={requirement.Volume.Remaining.Formatted}
                    exempt={requirement.Exempt}
                    hideBorder={!useMobileLayout}
                    buttonData={{
                      clickFunction: onClickVolumeRequirementTileButton,
                      text: "Monthly GPV Status",
                      ref: buttonRef,
                      icon: <IconPlainTable />,
                    }}
                  />
                </section>
              );
            })}
            {MonthlyRequirements.BadgeRequirements.map((requirement) => {
              return (
                <section
                  className="MonthlyAnnual__tile"
                  key={requirement.Name}
                  data-testid={requirement.Name + "-Tile"}
                >
                  <BadgeRequirementTile
                    title={t(
                      `requirements.${requirement.Name}.title`,
                      defaultContent["requirements"][requirement.Name]["title"] as string,
                      {
                        bonusAmount: requirement.Target,
                      },
                    )}
                    description={t(
                      `requirements.${requirement.Name}.description`,
                      defaultContent["requirements"][requirement.Name]["description"] as string,
                    )}
                    status={requirement.Status}
                    showLabel={requirement.ShowLabel}
                    hideBorder={!useMobileLayout}
                  />
                </section>
              );
            })}
          </div>
        </section>
        {getVerticalLine(useMobileLayout)}
        <section className="MonthlyAnnual__Annual">
          {useMobileLayout ? (
            <h4>
              {t(`requirements.annualRequirements`, defaultContent["requirements"]["annualRequirements"] as string)}
            </h4>
          ) : (
            <></>
          )}
          <div className="MonthlyAnnual__Annual--tiles">
            {AnnualRequirements.VolumeRequirements.map((requirement) => {
              return (
                <section
                  className="MonthlyAnnual__tile"
                  key={requirement.Name}
                  data-testid={requirement.Name + "-Tile"}
                >
                  <VolumeRequirementTile
                    isLoading={false}
                    title={t(
                      `requirements.${requirement.Name}.title`,
                      defaultContent["requirements"][requirement.Name]["title"] as string,
                      {
                        volume: requirement.Volume.Target.Formatted,
                        volumeRuby: requirement.Volume.Target.Formatted,
                        volumeLegs: requirement.Legs ? requirement.Legs.Target.Formatted : "",
                      },
                    )}
                    currentAmount={requirement.Volume.Current.Actual}
                    targetAmount={requirement.Volume.Target.Actual}
                    met={requirement.Met}
                    circleCheckmarkId={requirement.Name}
                    volumeType={requirement.Name}
                    labels={[requirement.Volume.Current.Formatted, requirement.Volume.Remaining.Formatted]}
                    showLabelsOnly={true}
                    additionalNeeded={requirement.Volume.Remaining.Formatted}
                    exempt={requirement.Exempt}
                    hideBorder={!useMobileLayout}
                  />
                </section>
              );
            })}
            {AnnualRequirements.PercentRequirements.map((requirement) => {
              return (
                <section
                  className="MonthlyAnnual__tile"
                  key={requirement.Name}
                  data-testid={requirement.Name + "-Tile"}
                >
                  <VcsPercentRequirementTile
                    isLoading={false}
                    additionalNeeded={requirement.Percent.Remaining.Actual}
                    targetValue={requirement.Percent.Target.Actual}
                    met={requirement.Met}
                    shouldShowGraphics={true}
                    leftValue={requirement.Volume.Current.Actual}
                    rightValue={requirement.Volume.Target.Actual}
                    percent={requirement.Percent.Current.Actual}
                    hideBorder={!useMobileLayout}
                  />
                </section>
              );
            })}
            {AnnualRequirements.BadgeRequirements?.map((requirement) => {
              return (
                <section
                  className="MonthlyAnnual__tile"
                  key={requirement.Name}
                  data-testid={requirement.Name + "-Tile"}
                >
                  <BadgeRequirementTile
                    title={t(
                      `requirements.${requirement.Name}.title`,
                      defaultContent["requirements"][requirement.Name]["title"] as string,
                      {
                        bonusAmount: requirement.Target,
                      },
                    )}
                    description={t(
                      `requirements.${requirement.Name}.description`,
                      defaultContent["requirements"][requirement.Name]["description"] as string,
                    )}
                    status={requirement.Status}
                    showLabel={requirement.ShowLabel}
                    hideBorder={!useMobileLayout}
                  />
                </section>
              );
            })}
            {AnnualRequirements.FQRequirement ? (
              <FQsComparisonTile
                isLoading={false}
                hideBorder={!useMobileLayout}
                showFqTileByStack={AnnualRequirements.FQRequirement.ShowFqTileByStack}
                fqStatusesMet={[true, true, false]}
                totalFqs={AnnualRequirements.FQRequirement.TotalFqs}
                met={AnnualRequirements.FQRequirement.Met}
                status={AnnualRequirements.FQRequirement.StatusCount}
                tracking={AnnualRequirements.FQRequirement.TrackingCount}
                currentYtd={AnnualRequirements.FQRequirement.CurrentYtdCount}
              />
            ) : (
              <></>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
