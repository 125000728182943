import "./LeafEligibility.scss";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/leafEligibility";
import { BgtMiniProfileData, EligibilityGroup } from "../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import LeafRectangleLoader from "../LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { useAppSelector } from "../../../store";
import { BGT_TABS } from "../../enums/bronze-group-tracking-enums";
import { getShortPeriodFormat } from "../../util/period";

type Props = {
  data: BgtMiniProfileData;
  isLoading: boolean;
  locale: string;
  isPrewindow: boolean;
};

export const formatWindowPeriod = (eligibilityWindow: { startPeriod: number; endPeriod: number }, locale: string) => {
  const { startPeriod = 0, endPeriod = 0 } = eligibilityWindow || {};
  return `${getShortPeriodFormat(startPeriod.toString(), locale)} - ${getShortPeriodFormat(
    endPeriod.toString(),
    locale,
  )}`;
};

export const LeafEligibility = (props: Props) => {
  const { data, isLoading, locale, isPrewindow } = props;
  const { t } = useTranslation(["leafEligibility"]);
  const selectedTab = useAppSelector((state) => state.bronzeGroupTrackingTab)?.activeView;
  const { firstTime, restartOne, restartTwo } = data;
  const restartTitle = t("restartEligibility", defaultContent["restartEligibility"]);
  const firstTimeTitle = t("eligibility", defaultContent["eligibility"]);
  const restartData = restartOne ? restartOne : restartTwo;
  const hideRestartEligibilityWindow = isPrewindow && !!restartData;
  const hideEligibilityWindow = isPrewindow && !restartData;

  const createEligibiltySection = (
    data: EligibilityGroup | null,
    locale: string,
    title: string,
    hideEligWindow: boolean,
  ) => {
    if (!data) return <></>;
    const { eligibilityWindow, missedMonths, opportunitiesRemaining, achievedMonths, graduationDate } = data;
    if (!graduationDate && selectedTab === BGT_TABS.GRADUATED_TAB) return <></>;

    return (
      <div>
        <h3 className="leaf-eligibility__title">{title}</h3>
        {hideEligWindow ? (
          <></>
        ) : (
          <h4 className="leaf-eligibility__date-range">{formatWindowPeriod(eligibilityWindow, locale)}</h4>
        )}
        <div className="leaf-eligibility">
          <div className="leaf-eligibility__row">
            <p>{t("opportunitiesRemaining", defaultContent["opportunitiesRemaining"])}</p>
            <p>
              {opportunitiesRemaining} {t("months", defaultContent["months"])}
            </p>
          </div>
          <div className="leaf-eligibility__row">
            <p>{t("achievedMonths", defaultContent["achievedMonths"])}</p>
            <p>
              {achievedMonths} {t("months", defaultContent["months"])}
            </p>
          </div>
          <div className="leaf-eligibility__row">
            <p>{t("missedMonths", defaultContent["missedMonths"])}</p>
            <p>
              {missedMonths} {t("months", defaultContent["months"])}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <LeafRectangleLoader isLoading={isLoading} width="100%" height="6rem">
        {createEligibiltySection(restartData, locale, restartTitle, hideRestartEligibilityWindow)}
        {createEligibiltySection(firstTime, locale, firstTimeTitle, hideEligibilityWindow)}
      </LeafRectangleLoader>
    </div>
  );
};
