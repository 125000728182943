import { useTranslation } from "react-i18next";
import { formatCurrency } from "@amwaycommon/acc-mixins/src/modules/l10n";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../content/calcDescTile";
import "./CalcDescTile.scss";
import { PathToBronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";

type Props = {
  isLoading: boolean;
  data: PathToBronzeOverviewResponse;
  locale: string;
  volumeNeeded: string;
  volumeType: string;
};

export default function CalcDescTile({ isLoading, data, locale, volumeNeeded, volumeType }: Props) {
  const { t } = useTranslation(["calcDescTile"]);
  const { bonusCurrency, legRequirements, country } = data;

  const descriptionContentKey = legRequirements.length === 3 ? "calcDescriptionThreeLegs" : "calcDescriptionTwoLegs";

  return (
    <div className="desc-tile-container">
      <div>
        <LeafTile isLoading={isLoading} circleCheckmarkId={""}>
          <section>
            {t(descriptionContentKey, defaultContent[descriptionContentKey], {
              firstBonusAmount: legRequirements[0].bonusAmount.formatted,
              secondBonusAmount: legRequirements[1].bonusAmount.formatted,
              thirdBonusAmount: legRequirements[2]?.bonusAmount.formatted || null,
              volumeNeeded,
              volumeType,
            })}
          </section>
        </LeafTile>
      </div>
    </div>
  );
}
