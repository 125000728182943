import { useTranslation } from "react-i18next";
import { useState } from "react";
import defaultContent from "../../../../content/bronzeConsistencyPaymentMetric";
import { IncentiveCalculation } from "../../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconAdd } from "../../../../common/icons/IconAdd/IconAdd";
import { IconEquals } from "../../../../common/icons/IconEquals/IconEquals";
import "./BBIConsistencyPaymentMetric.scss";
import BronzeConsistencyPaymentDetails from "../../BronzeCommon/BronzeConsistencyPaymentDetails/BronzeConsistencyPaymentDetails";
import { IconPlainTable } from "../../../../common/icons/IconPlainTable/IconPlainTable";
import { additionalIncentiveMetricOnClick } from "../../../../common/tealium/BronzeIncentive/BronzeFoundation/AdditionalIncentiveMetricButtonTrack";
import { BronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";

type Props = {
  data: BronzeOverviewResponse;
  isFixedPayment: boolean;
  show?: boolean;
  isFetching: boolean;
};

const BBIConsistencyPaymentMetric = ({ data, isFixedPayment, isFetching = true, show = true }: Props) => {
  const { t } = useTranslation(["bronzeConsistencyPaymentMetric"]);

  const [showModal, setShowModal] = useState(false);

  const { incomeData } = data;

  const {
    bonus = { amount: 0, isoCurrency: "", amountCurrency: "" },
    consistencyPayment = {
      target: 0,
      actual: 0,
      met: false,
      schedule: [],
      basePayment: {
        amount: 0,
        amountCurrency: "",
      },
      additionalPayment: {
        amount: 0,
        amountCurrency: "",
      },
      totalPayment: {
        amount: 0,
        amountCurrency: "",
      },
    },
  } = incomeData || {};

  const onCallback = () => {
    setShowModal(true);
    additionalIncentiveMetricOnClick();
  };

  const onModalClose = () => {
    setShowModal(false);
  };

  if (show === true && isFixedPayment === true && consistencyPayment.met === true && bonus.amount > 0)
    return (
      <div className="bbi-consistency-payment-metric">
        <div className="bbi-consistency-payment-metric__content">
          <div className="bbi-consistency-payment-metric__incentive-calculation">
            <IncentiveCalculation
              isLoading={isFetching}
              base={{
                title: t("base", defaultContent["base"]),
                amount: consistencyPayment.basePayment.amountCurrency,
                icon: <IconPlainTable />,
                iconAriaLabel: `${t("iconAriaLabel", defaultContent["iconAriaLabel"])}`,
                callbackFn: onCallback,
                hideDesktopIcon: true,
              }}
              multiplier={{
                title: t("multiplier", defaultContent["multiplier"]),
                amount: consistencyPayment.additionalPayment.amountCurrency,
                icon: <IconPlainTable />,
                iconAriaLabel: `${t("iconAriaLabel", defaultContent["iconAriaLabel"])}`,
                callbackFn: onCallback,
                symbolIcon: <IconAdd />,
                hideMobileIcon: true,
              }}
              total={{
                title: t("currentTotal", defaultContent["currentTotal"]),
                amount: consistencyPayment.totalPayment.amountCurrency, // The API will sum the base bonus and consistency payment to calculate the total. If the consistency payment is unavailable, the total will still reflect the base bonus amount.
                symbolIcon: <IconEquals />,
              }}
            />
          </div>
        </div>
        <BronzeConsistencyPaymentDetails
          showModal={showModal}
          onModalClose={onModalClose}
          module={"bbi"}
          schedules={consistencyPayment.schedule}
          targetRequired={consistencyPayment.target}
          met={consistencyPayment.met}
        />
      </div>
    );

  return <></>;
};
export default BBIConsistencyPaymentMetric;
