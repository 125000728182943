import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { storedLocale } from "../../../impersonation/util";
import { lambdaErrorCheck } from "../../../../common/util/errorHandling";
import { useGetPggPQMonthsQuery } from "../../../../services/GroupGrowth/PersonalGroupGrowth/personalGroupGrowthAPI";
import { PggPQMonthsResponse } from "../../../../services/GroupGrowth/PersonalGroupGrowth/personalGroupGrowthAPI.types";

export const FetchPQMonthData = (period: string, skipRequest: boolean) => {
  const { user, shellConfig } = useAppSelector((state) => state.boot);

  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetPggPQMonthsQuery(
    {
      abo: user.abo,
      aff: user.aff,
      period: period,
      locale: storedLocale(),
      isoCountryCode: user.isoCountryCode,
      isoCurrencyCode: shellConfig.isoCurrencyCode,
    },
    { skip: skipRequest },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [pqMonthData, setPQMonthData] = useState<PggPQMonthsResponse>({
    PQs: [],
    ShowBoosterRequirement: false,
    CallResults: [],
    Error: "",
  });

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setPQMonthData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, pqMonthData, errorStatus, refetch };
};
