import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FilterState {
  visibleAwardCode: number;
}

const initialState: FilterState = {
  visibleAwardCode: 0,
};

const { actions, reducer } = createSlice({
  name: "mbiFilter",
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<number>) => ({
      ...state,
      visibleAwardCode: action.payload,
    }),
  },
});

export const { updateFilter } = actions;

export default reducer;
