export enum U_TAG_METHOD {
  LINK = "link",
  VIEW = "view",
}

export enum EVENT_NAME {
  CLICK_ACTION = "click_action",
  CLICK_CATEGORY = "click_category",
}

export enum CATEGORY {
  CORE_PLUS_PORTAL = "core_plus_portal",
  USER_MANUAL_LINK = "userManual_link",
  SMART_NEXT_STEPS = "smart_next_steps",
  DROP_DOWN = "drop_down",
  PATH_TO_BRONZE = "path_to_bronze",
  TWO_TIME_CASH_INCENTIVE = "two_time_cash_incentive",
  GLOBAL_CML = "global_cml",
  GLOBAL_CML_OVERVIEW = "global_cml_overview",
  GLOBAL_CUSTOMER_PROFILE = "global_cml_profile",
  GLOBAL_CUSTOMER_LIST = "global_cml_customer_list",
  GLOBAL_ORDER_HISTORY = "global_cml_order_history",
  BRONZE_TRACKING = "bronze_tracking",
  OVERVIEW = "overview",
  GLOBAL_CML_EXPORT = "global_cml_export",
  MY_PERFORMANCE_DASH = "myPerformance_dashboard",
  ANNUAL_PERF_DASH = "annualPerformance_dashboard",
  MBI = "MBI",
  ILB = "ILB",
  FRONTLINE_GROUP_GROWTH = "frontline_group_growth",
  PERSONAL_GROUP_GROWTH = "personal_group_growth",
  ABO_PROFILE = "ABO_profile",
  ACADEMY = "academy",
  STRONG_START_INCENTIVE = "strong_start_incentive",
  PATH_TO_BRONZE_INCENTIVE = "path_to_bronze_incentive",
  BRONZE_FOUNDATION_INCENTIVE = "bronze_foundation_incentive",
  BRONZE_BUILDER_INCENTIVE = "bronze_builder_incentive",
  LEADERSHIP_SEMINAR = "leadership_seminar",
  PERFORMANCE_PLUS_ELITE = "performance_plus_and_elite",
  PERFORMANCE_PLUS = "performance_plus",
}

export enum SECTION {
  PERFORMANCE_BONUS_PERCENT_LEGS = "performance_bonus_percent_legs",
  PERFORMANCE_BONUS_PERCENT_LEG_WITH_VCS_PV = "performance_bonus_percent_leg_with_vcs_pv",
  ELIGIBILITY_TILE = "eligibility_tile",
  BANNER_CTA = "banner_cta",
  STRONG_START_INCENTIVE = "strong_start_incentive",
  MONTHLY_GPV_STATUS_MODAL = "monthly_gpv_status_modal",
}

const tealiumEnums = {
  U_TAG_METHOD,
  EVENT_NAME,
  CATEGORY,
  SECTION,
};

export default tealiumEnums;
