import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../..";
import { PerformanceBonusResponse, PggOverviewResponse, PggPQMonthsResponse } from "./personalGroupGrowthAPI.types";

type PGGBaseRequestParams = {
  abo: string;
  aff: string;
  locale: string;
  period: string;
};

type PGGExtendedRequestParams = PGGBaseRequestParams & {
  isoCountryCode: string;
  isoCurrencyCode: string;
};

export const personalGroupGrowthAPI = createApi({
  reducerPath: "personalGroupGrowthAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getPggOverview: builder.query<PggOverviewResponse, PGGExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/pgg/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getPggPQMonths: builder.query<PggPQMonthsResponse, PGGExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/pgg/pqmonths?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getPggPerformanceBonus: builder.query<PerformanceBonusResponse, PGGExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/pgg/q-month-history?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const { useGetPggOverviewQuery, useGetPggPQMonthsQuery, useGetPggPerformanceBonusQuery } =
  personalGroupGrowthAPI;
