import { useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import TEALIUM from "../../../common/enums/tealium-enums";
import defaultContent from "../../../content/frontlineGrowth";
import { IconBadge } from "../../../common/icons/IconBadge/IconBadge";
import { MultiplierData } from "../../../services/GroupGrowth/groupGrowth.types";
import { getPerformanceYearShort } from "../../../common/util/period";
import { FetchFGGMultiplierBaseData } from "../FGGMultiplierBase/FGGMultiplierBaseDataLayer";
import { FGGMultiplierBase } from "../FGGMultiplierBase/FGGMultiplierBase";
import { IncentiveCalculation } from "../../../common/components/IncentiveCalculation/IncentiveCalculation";
import { IconMultiply } from "../../../common/icons/IconMultiply/IconMultiply";
import { IconEquals } from "../../../common/icons/IconEquals/IconEquals";
import { LeafIconButton } from "../../../common/leaf/LeafIconButton/LeafIconButton";
import { IconTable } from "../../../common/icons/IconTable/IconTable";

type Props = {
  multiplierData: MultiplierData[];
  isLoading: boolean;
  selectedPeriod: string;
  isExpanded?: boolean;
};

export default function FGGIncentiveCalculation({ isLoading, multiplierData, selectedPeriod, isExpanded }: Props) {
  const { t } = useTranslation(["frontlineGrowth"]);
  const [multiplierBaseModalIsOpen, setMultiplierBaseModalIsOpen] = useState<boolean>(false);
  const PY = getPerformanceYearShort(selectedPeriod);
  const {
    fggMultiplierBaseData,
    isLoading: multiplierBaseLoading,
    errorStatus: multiplierBaseErrorStatus,
    refetch: refetchMultiplierBaseRequest,
  } = FetchFGGMultiplierBaseData(selectedPeriod);

  const getDefaultContent = (type: string) => {
    return type === "leg" ? defaultContent.incentiveCalc.leg : defaultContent.incentiveCalc.incentive;
  };

  const getIconForBase = (item: MultiplierData) => {
    return item.Type === "Leg" ? (
      <IconBadge width="1.3125rem" height="1.75rem" />
    ) : (
      <LeafIconButton Icon={<IconTable />} HoverIcon={<IconTable color="blue" />} />
    );
  };

  const getCallbackFunction = (item: MultiplierData) => {
    if (item.Type === "Incentive") {
      return openMultiplierBase();
    }
  };

  const openMultiplierBase = () => {
    setMultiplierBaseModalIsOpen(!multiplierBaseModalIsOpen);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.FRONTLINE_GROUP_GROWTH,
      `incentive_multiplier_base_modal_${!multiplierBaseModalIsOpen ? "open" : "close"}`,
      "multiplier_base_modal",
    );
  };

  const renderModal = (item: MultiplierData) => {
    if (item.Type === "Incentive") {
      return (
        <FGGMultiplierBase
          modalIsOpen={multiplierBaseModalIsOpen}
          toggleModal={openMultiplierBase}
          performanceYear={PY}
          multiplierBaseReponseData={{
            multiplierBaseData: fggMultiplierBaseData,
            isLoading: multiplierBaseLoading,
            errorStatus: multiplierBaseErrorStatus,
            refetch: refetchMultiplierBaseRequest,
          }}
          multiplierBase={item.Base}
        />
      );
    }
  };

  const incentiveCalculationTile = (item: MultiplierData) => {
    const type = item.Type.toLowerCase();
    return (
      <div className="FGGIncentiveCalculation" key={type}>
        <IncentiveCalculation
          isLoading={isLoading}
          base={{
            title: t(`incentiveCalc.${type}.base`, getDefaultContent(type).base).toString(),
            amount: item.Base,
            icon: getIconForBase(item),
            callbackFn: () => getCallbackFunction(item),
          }}
          multiplier={{
            title: t(`incentiveCalc.${type}.multipler`, getDefaultContent(type).multipler).toString(),
            amount: item.Multiplier,
            symbolIcon: <IconMultiply />,
            type: item.Type,
          }}
          total={{
            title: t(`incentiveCalc.${type}.total`, getDefaultContent(type).total, {
              performanceYear: PY,
            }).toString(),
            amount: item.Total,
            symbolIcon: <IconEquals />,
          }}
          isExpanded={isExpanded}
        />
        {renderModal(item)}
      </div>
    );
  };

  return <>{multiplierData.map((item: MultiplierData) => incentiveCalculationTile(item))}</>;
}
