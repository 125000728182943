import { useState } from "react";
import "./CopyToClipboard.scss";
import { IconClipboard } from "../../icons/IconClipboard/IconClipboard";
import { IconCheck } from "../../icons/IconCheck/IconCheck";
import LeafTooltip from "../../leaf/LeafTooltip/LeafTooltip";

type Props = {
  copyText: string;
};

export const CopyToClipboard = ({ copyText }: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(copyText);
      setIsCopied(true);
      setTooltipText("Copied!");

      setTimeout(() => {
        setIsCopied(false);
        setTooltipText("Copy to clipboard");
      }, 2200);
    } catch (error) {
      console.error("Copy failed:", error);
    }
  };

  const displayIcon = isCopied ? <IconCheck /> : <IconClipboard fill="blue" />;

  return (
    <div className="CopyToClipboard">
      <span className="CopyToClipboard__copy-text">{copyText}</span>
      <span className="CopyToClipboard__divider">|</span>
      <button className="CopyToClipboard__button" onClick={handleCopy}>
        <LeafTooltip
          id={"234235"}
          icon={<div className="CopyToClipboard__icon">{displayIcon}</div>}
          openOnClick={false}
          content={tooltipText}
        />
      </button>
    </div>
  );
};
