import { useEffect } from "react";
import "./impersonation-history.scss";
import { useDispatch } from "react-redux";
import { Markets } from "@amwaycommon/acc-es-data/model";
import { ImpersonationDetails, ImpersonationType } from "../../../common/interfaces/impersonation";
import { useAppSelector } from "../../../store";
import { setLocalStorageItem } from "../../../reducers/loginLocalStorage";
import { getCountryFlag } from "../../../common/util/getCountryFlag";
import { IconUser } from "../../../common/icons/IconUser/IconUser";
import { IconMobilePhone } from "../../../common/icons/IconMobilePhone/IconMobilePhone";
import useWindowSize from "../../../common/hooks/useWindowSize";
import { ReactComponent as IconExportDelete } from "../../../common/icons/IconExportDelete/svg/icon_export_delete.svg";
import { getDeviceType } from "../impersonation";
import { ImpersonationTable } from "./impersonationTable/ImpersonationTable";

const parseJson = (val: any) => {
  return JSON.parse(JSON.stringify(val));
};

export const updateImpersonationHistory = (
  impersonationHistory: ImpersonationDetails[],
  currentEntry: ImpersonationDetails,
): ImpersonationDetails[] => {
  const history: ImpersonationDetails[] = parseJson(impersonationHistory);
  const index = history.findIndex(({ abo, country, impersonationType }: ImpersonationDetails) => {
    return (
      abo === currentEntry.abo &&
      country === currentEntry.country &&
      impersonationType === currentEntry.impersonationType
    );
  });

  if (index > -1) {
    history.splice(index, 1);
  }
  history.unshift(currentEntry);

  return history;
};

export const ImpersonationHistory = (props: any) => {
  const { impersonationAdded, impersonationAuthCallback, amwayPlusImpersonationCallback } = props;
  const { impersonationHistory } = useAppSelector((state) => state.loginLocalStorage);

  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const isDesktop = width && width > 600;

  const columns = {
    abo: isDesktop ? "28.5%" : "20%",
    country: isDesktop ? "20.5%" : "10%",
    partyId: "15%",
    note: isDesktop ? "25%" : "0%",
    buttons: "10%",
  };

  const isObjEmpty = (obj: ImpersonationDetails) => Object.keys(obj).length === 0;

  const updateRecentlyUsedWithNewABO = (userEntry: ImpersonationDetails) => {
    const history = updateImpersonationHistory(impersonationHistory, userEntry);
    dispatch(setLocalStorageItem({ impersonationHistory: history }));
  };

  const impersonateWithSelectedABO = (userEntry: ImpersonationDetails) => {
    updateRecentlyUsedWithNewABO(userEntry);
    impersonationAuthCallback(userEntry);
  };

  const impersonateWithAmywayPlus = (userEntry: ImpersonationDetails) => {
    const userEntryWithCurrentDeviceType: ImpersonationDetails = {
      ...userEntry,
      deviceType: getDeviceType(),
    };
    updateRecentlyUsedWithNewABO(userEntryWithCurrentDeviceType);
    amwayPlusImpersonationCallback(userEntryWithCurrentDeviceType);
  };

  const remove = (item: ImpersonationDetails) => {
    let history: ImpersonationDetails[] = parseJson(impersonationHistory);
    history = history.filter(
      (i: ImpersonationDetails) =>
        !(i.abo === item.abo && i.country === item.country && i.impersonationType === item.impersonationType),
    );
    dispatch(setLocalStorageItem({ impersonationHistory: history }));
  };

  useEffect(() => {
    const add = (item: ImpersonationDetails) => {
      const history = parseJson(impersonationHistory);
      history.unshift(item);
      dispatch(setLocalStorageItem({ impersonationHistory: history }));
    };

    const findABO = (abo: string, country: string, impersonationType: ImpersonationType) => {
      return parseJson(impersonationHistory).filter(
        (el: ImpersonationDetails) =>
          el.abo === abo && el.country === country && el.impersonationType === impersonationType,
      )[0];
    };

    const editDuplicate = (item: ImpersonationDetails) => {
      const historyObj = parseJson(impersonationHistory);
      historyObj.forEach((obj: ImpersonationDetails) => {
        if (obj.abo === item.abo && obj.country === item.country) {
          obj.note = item.note;
          obj.partyId = item.partyId;
        }
      });

      dispatch(setLocalStorageItem({ impersonationHistory: historyObj }));
    };

    if (!isObjEmpty(impersonationAdded)) {
      const duplicateABO = findABO(
        impersonationAdded.abo,
        impersonationAdded.country,
        impersonationAdded.impersonationType,
      );
      if (duplicateABO) {
        editDuplicate(impersonationAdded);
      } else {
        add(impersonationAdded);
      }

      updateRecentlyUsedWithNewABO(impersonationAdded);
    }
    // eslint-disable-next-line
  }, [impersonationAdded]);

  const formatImpersonationTable = (impersonationHistory: ImpersonationDetails[]) => {
    return parseJson(impersonationHistory).map((item: ImpersonationDetails, i: number) => {
      const country = new Markets().getByCtry(item.country);
      let isoCountryCode = "US";
      if (country) {
        isoCountryCode = country.iso;
      }

      const isDesktop = width && width > 600;

      const row = [
        { value: <span className="impersonationHistory__rowItem">{item.abo}</span>, width: columns.abo },
        {
          value: (
            <span className="impersonationHistory__rowItem">
              <span>{getCountryFlag(isoCountryCode)}</span>
              <span>{item.country}</span>
            </span>
          ),
          width: columns.country,
        },
        {
          value: <span className="impersonationHistory__rowItem">{item.partyId ? item.partyId : "-"}</span>,
          width: columns.partyId,
        },
      ];

      if (isDesktop) {
        row.push({
          value: <span className="impersonationHistory__rowItem">{item.note ? item.note : "-"}</span>,
          width: columns.note,
        });
      }

      const getImpersonationBtn = () => {
        if (item.impersonationType === "amwayPlus") {
          return (
            <button
              type="button"
              className="impersonationHistory__impersonationButton__impersonateButton"
              onClick={() => impersonateWithAmywayPlus(item)}
            >
              <IconMobilePhone fill="white" />
            </button>
          );
        }
        return (
          <button
            type="button"
            className="impersonationHistory__impersonationButton__impersonateButton"
            onClick={() => impersonateWithSelectedABO(item)}
          >
            <IconUser iconStyle="filled" color="white" />
          </button>
        );
      };

      row.push({
        value: (
          <div className="impersonationHistory__rowItem impersonationHistory__buttonGroup">
            <button
              type="button"
              className="impersonationHistory__impersonationButton__deleteButton"
              onClick={() => remove(item)}
            >
              <IconExportDelete />
            </button>

            {getImpersonationBtn()}
          </div>
        ),
        width: columns.buttons,
      });

      return { row: row };
    });
  };

  const getHeader = () => {
    const header = [
      { value: "ABO", width: columns.abo },
      { value: "Country", width: columns.country },
      { value: "Party ID", width: columns.partyId },
    ];

    if (isDesktop) {
      header.push({ value: "Note", width: columns.note });
    }

    header.push({ value: "", width: columns.buttons });
    return header;
  };

  return (
    <div className="impersonationHistory">
      {impersonationHistory && parseJson(impersonationHistory).length > 0 ? (
        <ImpersonationTable
          header={getHeader()}
          rows={formatImpersonationTable(impersonationHistory)}
          columnAlign="left"
          hideHeader={true}
        />
      ) : null}
    </div>
  );
};
