import { useTranslation } from "react-i18next";
import { LATAM_MARKET_CODES } from "../../../common/constants/market-constants";
import { getAff } from "../../impersonation/util";
import defaultCommonContent from "../../../content/common";
import {
  ActualFormatted,
  BonusHistory,
  LegRequirement,
  LegVolumeReq,
  PathToBronzeOverviewResponse,
} from "../../../services/BronzeIncentives/bronzeIncentivesAPI.types";

/**
 * The firstLeg will always have the highest scheduleRank and the last leg will always have the lowest. The legRequirements come
 * from the coreplus incentives-api sorted in descending order by scheduleRank.
 *
 * 3 Legs example: firstLeg/scheduleRank 3, secondLeg/scheduleRank 2, thirdLeg/scheduleRank 1.
 *
 * 2 Legs example: firstLeg/scheduleRank 2, secondLeg/scheduleRank 1.
 *
 * @param legRequirements
 * @returns
 */
export const getLegByLegNumber = (legRequirements: LegRequirement[], legNumber: number): LegRequirement => {
  const legBumByIndex = legNumber - 1;
  if (!legRequirements || legRequirements.length === 0 || !legRequirements[legBumByIndex]) {
    return {
      scheduleRank: 0,
      met: false,
      count: 0,
      target: 0,
      deficit: 0,
      bonusAmount: { actual: 0, formatted: "0" },
    };
  }

  return legRequirements[legBumByIndex];
};

export const getLegRequiredVolume = (legRequiredVolume: LegVolumeReq): LegVolumeReq => {
  const legReqVolume = {
    volumeRequired: { actual: 150, formatted: "150.00" }, // TODO: might move to backend
    volumeType: "Volume",
    requirement: legRequiredVolume.requirement,
  };
  if (legRequiredVolume) {
    if (legRequiredVolume.volumeRequired.actual === 0) {
      legReqVolume.volumeRequired.actual = 150;
      legReqVolume.volumeRequired.formatted = "150.00";
    } else {
      legReqVolume.volumeRequired.actual = legRequiredVolume.volumeRequired.actual;
      legReqVolume.volumeRequired.formatted = legRequiredVolume.volumeRequired.formatted;
    }
  }

  return legReqVolume;
};

export const getVolReqData = (pathToBronzeData: PathToBronzeOverviewResponse) => {
  const { perfBonusLevel, personalVolume, displayVCS, isAna, vcsPercent, vcs } = pathToBronzeData;

  return {
    GPVTile: {
      met: perfBonusLevel.met,
      currentAmount: { actual: perfBonusLevel.currentGpv.actual, formatted: perfBonusLevel.currentGpv.formatted },
      targetAmount: { actual: perfBonusLevel.requiredGpv.actual, formatted: perfBonusLevel.requiredGpv.formatted },
      neededAmount: {
        actual: perfBonusLevel?.additionalNeeded?.actual,
        formatted: perfBonusLevel?.additionalNeeded?.formatted,
      },
      requiredPerfLevel: perfBonusLevel.requiredPerfLevel,
    },
    PPVTile: {
      met: personalVolume.met,
      currentAmount: { actual: personalVolume.currentPpv.actual, formatted: personalVolume.currentPpv.formatted },
      targetAmount: { actual: personalVolume.requiredPpv.actual, formatted: personalVolume.requiredPpv.formatted },
      neededAmount: {
        actual: personalVolume?.additionalNeeded?.actual,
        formatted: personalVolume?.additionalNeeded?.formatted,
      },
      volumeType: personalVolume.requirement,
      required: personalVolume.required,
    },
    displayVCS,
    isAna,
    VCSTile: {
      target: { actual: vcsPercent?.target?.actual, formatted: vcsPercent?.target?.formatted },
      value: { actual: vcsPercent?.value?.actual, formatted: vcsPercent?.value?.formatted },
      met: vcsPercent?.met || false,
      ppv: { actual: personalVolume?.currentPpv?.actual, formatted: personalVolume?.currentPpv?.formatted },
      additionalNeeded: {
        actual: vcsPercent?.additionalNeeded?.actual,
        formatted: vcsPercent?.additionalNeeded?.formatted,
      },
      vcs: { actual: vcs.actual, formatted: vcs.formatted },
    },
    vcs: { actual: vcs.actual, formatted: vcs.formatted },
  };
};

interface TrackingMetObj {
  firstLegsMet: boolean;
  perfBonusMet: boolean;
  vcsMet: boolean;
  ppvMet: boolean;
}
export const getTrackingMeObj = (
  firstLegReq: any,
  perfBonusLevel: any,
  vcsPercent: any,
  personalVolume: any,
): TrackingMetObj => {
  const trackingMetObj = {
    firstLegsMet: firstLegReq?.met || false,
    perfBonusMet: perfBonusLevel?.met || false,
    vcsMet: vcsPercent?.met || false,
    ppvMet: personalVolume?.met || false,
  };
  return trackingMetObj;
};

export interface TrackingReqData {
  requiredTrackingReq: boolean[];
  firstLegsMet: boolean;
  perfBonusMet: boolean;
  vcsMet: boolean;
  ppvMet: boolean;
  legsMetCount: number;
  totalLegsCount: number;
  extraBonusesMet: number;
  totalLegRequirements: number;
  nextBonusAmount: ActualFormatted;
  legRequirements: LegRequirement[];
  metEverything: boolean;
}

export const getTrackingReqData = (pathToBronzeData: PathToBronzeOverviewResponse): TrackingReqData => {
  const { legRequirements, perfBonusLevel, personalVolume, isAna, vcsPercent } = pathToBronzeData;
  const firstLegReq = getLegByLegNumber(legRequirements, 1);
  const totalLegRequirements = legRequirements.length;

  const requiredTrackingReq: boolean[] = [
    true,
    perfBonusLevel.required || false,
    isAna || false,
    personalVolume.required || false,
  ];

  const trackingMetObj = getTrackingMeObj(firstLegReq, perfBonusLevel, vcsPercent, personalVolume);
  const legsMetCount = Object.values(trackingMetObj).filter((item, index) => item && requiredTrackingReq[index]).length;
  const totalLegsCount = Object.values(requiredTrackingReq).filter((item) => item).length;

  const metEverything = (legRequirements && legRequirements[legRequirements.length - 1]?.met) || false;

  const getExtraBonusesMet = () => {
    if (totalLegRequirements === 3) {
      return legRequirements.filter((leg) => leg.met && leg.scheduleRank <= 2).length;
    }
    return legRequirements.filter((leg) => leg.met && leg.scheduleRank === 1).length;
  };

  const findCurrentLevel = (legRequirements: LegRequirement[]) => {
    const metLegRequirements = legRequirements.filter((leg) => leg.met).sort((a, b) => b.target - a.target);
    return metLegRequirements[0]?.target || 0;
  };

  const getNextBonusAmount = () => {
    const index = findCurrentLevel(legRequirements) + 1;
    const nextBonusAmount = legRequirements.find((leg) => leg.target >= index)?.bonusAmount || {
      actual: 0,
      formatted: "0.00",
    };
    return { actual: nextBonusAmount.actual, formatted: nextBonusAmount.formatted };
  };

  const extraBonusesMet = getExtraBonusesMet();
  const nextBonusAmount = getNextBonusAmount();

  return {
    requiredTrackingReq,
    ...trackingMetObj,
    extraBonusesMet,
    legsMetCount,
    totalLegsCount,
    totalLegRequirements,
    nextBonusAmount,
    legRequirements,
    metEverything,
  };
};

export const getMonthsEarned = (bonusHistory: BonusHistory[]) => {
  return bonusHistory.filter((month) => month.status === "QUALIFIED" || month.status === "TRACKING").length || 0;
};

export const calculateRemainigTime = (achieveLimit = 0, bonusHis: any) => {
  let count = 0;
  if (bonusHis && bonusHis.length > 0) {
    bonusHis.forEach((element: { status: string }) => {
      if (element.status === "QUALIFIED" || element.status === "TRACKING") {
        count += 1;
      }
    });
  }
  return Math.abs(achieveLimit - count);
};

export const returnDefaultData = (): PathToBronzeOverviewResponse => {
  return {
    awardCap: {
      target: 0,
      actual: 0,
      remaining: 0,
    },
    perfBonusLevel: {
      currentGpv: { actual: 0, formatted: "0" },
      currentPerfLevel: 0,
      met: false,
      required: true,
      requiredGpv: { actual: 0, formatted: "0" },
      requiredPerfLevel: 0,
    },
    eligible: false,
    country: "US",
    denied: false,
    held: false,
    ineligibleForPY: false,
    earning: false,
    manualApproval: false,
    bonus: { actual: 0, formatted: "0" },
    bonusCurrency: "USD",
    legActual: 0,
    legRequirements: [
      {
        scheduleRank: 2,
        target: 0,
        met: false,
        count: 0,
        bonusAmount: { actual: 0, formatted: "0" },
        deficit: 0,
      },
      {
        scheduleRank: 1,
        target: 0,
        met: false,
        count: 0,
        bonusAmount: { actual: 0, formatted: "0" },
        deficit: 0,
      },
    ],
    legRequirementVolume: {
      volumeRequired: { actual: 0, formatted: "0" },
      volumeType: "Volume",
      requirement: "group",
    },
    personalVolume: {
      currentPpv: { actual: 0, formatted: "0" },
      met: false,
      required: true,
      requiredPpv: { actual: 0, formatted: "0" },
      requirement: "personal",
    },
    bonusHistory: [],
    graduated: false,
    errors: [],
    programActive: true,
    showDateError: false,
    vcsPercent: {
      met: false,
      value: { actual: 0, formatted: "0" },
      target: { actual: 0, formatted: "0" },
      additionalNeeded: { actual: 0, formatted: "0" },
    },
    displayVCS: false,
    isAna: false,
    vcs: { actual: 0, formatted: "0" },
    effectivePeriod: 0,
    newLegTenureMonths: 0,
  };
};

export const getStatusForTealium = (data: PathToBronzeOverviewResponse) => {
  const { ineligibleForPY, awardCap, graduated, earning } = data;
  const isOverPaymentLevel = awardCap.target > 0 && awardCap.actual >= awardCap.target;

  if (isOverPaymentLevel) {
    return "ineligible:over_payment_level";
  } else if (ineligibleForPY) {
    return "ineligible:above_perf_level";
  } else if (graduated) {
    return "earned";
  } else if (earning) {
    return "tracking";
  } else {
    return "not_earned";
  }
};

export const isLatamMarketAndNotPastPeriod = (period: number) => {
  return (
    LATAM_MARKET_CODES.includes(getAff()) &&
    period >= +(new Date().getFullYear().toString() + (new Date().getMonth() + 1).toString().padStart(2, "0"))
  );
};

export const GetVolumeTypeLabel = (volumeType: string) => {
  const { t } = useTranslation(["common"]);
  switch (volumeType) {
    case "personalTransaction":
      return t("pcpv", defaultCommonContent["pcpv"]);
    case "group":
      return t("gpv", defaultCommonContent["gpv"]);
    case "personal":
      return t("ppv", defaultCommonContent["ppv"]);
    default:
      return "";
  }
};
