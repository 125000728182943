import { createSlice } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const initialStateBusinessSelector = {
  businessSelectorData: [{}],
};

const { actions, reducer } = createSlice({
  name: "businessSelectorData",
  initialState: initialStateBusinessSelector,
  reducers: {
    setBusinessSelectorData: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        businessSelectorData: [...payload],
      };
    },
  },
});

export const { setBusinessSelectorData } = actions;

const persistConfig = {
  key: "businessSelectorData",
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
};

export default persistReducer<any>(persistConfig, reducer);
