import { accAPI } from "..";
import { PerfHistoryParams, PerfHistoryResponse } from "./performanceHistoryAPI.types";

const subPath = "/performance/api";

export const perfHistoryAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getPersonalPV: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/pv/personal?${queryParams}`;
      },
    }),
    getGroupPV: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/pv/group?${queryParams}`;
      },
    }),
    getRubyPV: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/pv/ruby?${queryParams}`;
      },
    }),
    getLeadershipPV: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/pv/leadership?${queryParams}`;
      },
    }),
    getVCSPV: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/pv/vcs?${queryParams}`;
      },
    }),
    getQCPV: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/pv/qc?${queryParams}`;
      },
    }),
    getVCSPercentage: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/pv/vcspercent?${queryParams}`;
      },
    }),
    getTotalABO: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/abo/total?${queryParams}`;
      },
    }),
    getNewABO: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/abo/new?${queryParams}`;
      },
    }),
    getPersonalOrder: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/order/personal?${queryParams}`;
      },
    }),
    getGroupOrder: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/order/group?${queryParams}`;
      },
    }),
    getCustomerOrder: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/order/customer?${queryParams}`;
      },
    }),
    getNewCustomer: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/customer/new?${queryParams}`;
      },
    }),
    getTotalCustomer: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/customer/total?${queryParams}`;
      },
    }),
    getPerformanceBonus: build.query<PerfHistoryResponse, PerfHistoryParams>({
      query: (params) => {
        const { affAbo, period, periodCount, locale, market } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          periodCount: `${periodCount}`,
          locale,
          market,
        });

        return `${subPath}/performance-bonus?${queryParams}`;
      },
    }),
  }),
});

export const {
  useGetPersonalPVQuery,
  useGetGroupPVQuery,
  useGetLeadershipPVQuery,
  useGetQCPVQuery,
  useGetNewABOQuery,
  useGetRubyPVQuery,
  useGetTotalABOQuery,
  useGetVCSPVQuery,
  useGetVCSPercentageQuery,
  useGetPersonalOrderQuery,
  useGetCustomerOrderQuery,
  useGetGroupOrderQuery,
  useGetNewCustomerQuery,
  useGetTotalCustomerQuery,
  useGetPerformanceBonusQuery,
} = perfHistoryAPI;
