import { Trans, useTranslation } from "react-i18next";
import { formatAboPV } from "@amwaycommon/acc-mixins/l10n";
import defaultContent from "../../../../content/customers";
import { getBonusPeriod } from "../../../../common/util/common";
import "./Statistic.scss";
import { marketCode, storedLocale } from "../../../impersonation/util";

type Props = {
  current: number;
  previous: number;
  isFetching: boolean;
  entryDate: string;
  selectedPeriod: string;
  formatPV?: boolean;
  module: "Order" | "CustomerPV" | "NewCustomer";
};

const Statistic = (props: Props) => {
  const { t } = useTranslation(["customers"]);
  const { current, previous, isFetching, entryDate, selectedPeriod, formatPV, module } = props;

  const isNewJoiner = getBonusPeriod(new Date(entryDate)) === selectedPeriod;

  const locale = storedLocale();
  const market = marketCode();

  const Content = (prop: { topic: string; count?: number }) => {
    const { topic, count = 0 } = prop;
    const i18nKey = `${topic}${module}`;

    const context = formatPV ? formatAboPV(count, locale, market) : `${count}`;

    return (
      <Trans
        t={t}
        i18nKey={i18nKey}
        context={context}
        components={{
          neutral: <span className="overview-statistic__figure overview-statistic__figure--neutral" />,
          positive: <span className="overview-statistic__figure overview-statistic__figure--positive" />,
          negative: <span className="overview-statistic__figure overview-statistic__figure--negative" />,
        }}
      >
        {defaultContent["statistic"][i18nKey]}
      </Trans>
    );
  };

  if (isFetching === true) {
    return <></>;
  }

  if (isNewJoiner === true) {
    return <span>{`${t("statistic.newJoinerMessage", defaultContent["statistic"]["newJoinerMessage"])}`}</span>;
  }

  return (
    <p className="overview-statistic">
      {current > previous && <Content topic="positive" count={current - previous} />}
      {current < previous && <Content topic="negative" count={previous - current} />}
      {current === previous && <Content topic="neutral" />}
    </p>
  );
};

export default Statistic;
