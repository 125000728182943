import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import { FoundersPlatinumProgressResponse } from "../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI.types";
import { useGetFoundersPlatinumProgressQuery } from "../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI";

export const defaultFoundersPlatinumProgress = (): FoundersPlatinumProgressResponse => {
  return {
    SpMonthsRequirement: {
      MonthsRequired: 0,
      MonthsAchieved: 0,
      MonthsNeeded: 0,
      MonthsQualificationStatus: QUALIFICATION_STATUS.UN_AVAILABLE,
      Qualified: false,
    },
    GpvRequirement: {
      MonthsRequired: 0,
      MonthsAchieved: 0,
      MonthsNeeded: 0,
      AnnualGpvRequired: 0,
      AnnualGpvEarned: 0,
      AnnualGpvNeeded: 0,
      MonthsQualificationStatus: QUALIFICATION_STATUS.UN_AVAILABLE,
      GpvQualificationStatus: QUALIFICATION_STATUS.UN_AVAILABLE,
      RequirementQualificationStatus: QUALIFICATION_STATUS.UN_AVAILABLE,
    },
    VepvRequirement: {
      MonthsRequired: 0,
      MonthsAchieved: 0,
      MonthsNeeded: 0,
      AnnualVepvRequired: 0,
      AnnualVepvEarned: 0,
      AnnualVepvNeeded: 0,
      MonthsQualificationStatus: QUALIFICATION_STATUS.UN_AVAILABLE,
      VepvQualificationStatus: QUALIFICATION_STATUS.UN_AVAILABLE,
      RequirementQualificationStatus: QUALIFICATION_STATUS.UN_AVAILABLE,
    },
    CallResult: [],
    Error: "",
  };
};

export const FetchFoundersPlatinumProgressData = (period: string) => {
  const { user, configuration } = useAppSelector((state) => state.boot);
  const { annualDashboard } = configuration;
  const {
    foundersPlatinum: { spMethodMonthsRequired = 0, gpvMethodMonthsRequired = 0, vepvMethodMonthsRequired = 0 },
  } = annualDashboard;

  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetFoundersPlatinumProgressQuery({
    aff: user.aff,
    abo: user.abo,
    period,
    isoCountryCode: user.isoCountryCode,
    gpvMethodMonthsRequired,
    vepvMethodMonthsRequired,
    spMethodMonthsRequired,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [data, setData] = useState<FoundersPlatinumProgressResponse>(defaultFoundersPlatinumProgress());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setData(payloadData);
      if (payloadData.Error) {
        setErrorStatus(true);
      }
    }
  }, [isError, payloadData, isFetching]);

  return { isLoading, data, errorStatus, refetch };
};
