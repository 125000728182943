import { useGetABOProfileQuery } from "../../../../../../services/Profile/profileAPI";
import { useAppSelector } from "../../../../../../store";
import LeafProfileLoader from "../../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import { LeafErrorMessage } from "../../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import "./OverviewTab.scss";
import LeafRectangleLoader from "../../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { PartyInfo } from "../../../../../../services/Profile/profileAPI.types";
import ABOProfilePinInfo from "./ABOProfilePinInfo/ABOProfilePinInfo";
import ABOProfilePrimaryAddress from "./ABOProfilePrimaryAddress/ABOProfilePrimaryAddress";
import ABOProfileAdditionalInfo from "./ABOProfileAdditionalInfo/ABOProfileAdditionalInfo";

const OverviewTab = () => {
  const user = useAppSelector((state) => state.boot?.user);
  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const { selectedPeriod } = useAppSelector(({ period }) => period);
  const profile = useAppSelector((state) => state.boot?.configuration?.profile);
  const { showHighestPin } = profile?.aboInfo || {};

  // fetch profile data
  const {
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
    data: profileData,
  } = useGetABOProfileQuery({
    period: selectedPeriod,
    affAbo,
    reqAffAbo: `${user.aff}-${user.abo}`,
  });

  // Display loading state
  if (isFetchingProfile === true) {
    return (
      <>
        <LeafRectangleLoader isLoading={isFetchingProfile} numRows={1} width="50%" height="1.5rem" />
        <LeafProfileLoader row={1} card={true} isFetching={isFetchingProfile} />
        <LeafRectangleLoader isLoading={isFetchingProfile} numRows={1} width="50%" height="1.5rem" />
        <LeafProfileLoader row={3} card={true} isFetching={isFetchingProfile} />
        <LeafRectangleLoader isLoading={isFetchingProfile} numRows={1} width="95%" height="1.5rem" />
        <LeafProfileLoader row={4} card={true} isFetching={isFetchingProfile} />
      </>
    );
  }

  // Display error state
  if (isErrorProfile === true) {
    return <LeafErrorMessage show={isErrorProfile} />;
  }

  const getApplicantAddress = (partyInfo: PartyInfo[] | undefined) => {
    if (partyInfo && partyInfo.length > 0) {
      const { addresses = [] } = partyInfo.find(({ isPrimary }) => isPrimary === true) || partyInfo[0];
      return addresses;
    }
    return [
      {
        addressLines: [],
        municipality: "",
        region: "",
        postalCode: "",
        country: "",
        type: "",
      },
    ];
  };

  // Profile Data
  const { privacyFlag = false } = profileData?.aboInfo || {};
  const addresses = getApplicantAddress(profileData?.partyInfo);

  const {
    currentPin = 0,
    currentPinDate,
    highestAwardAchieved = 0,
    highestAwardAchievedDate,
    highestTrackingAward = 0,
  } = profileData?.awardInfo || {};

  const {
    autoRenewal = false,
    contractSigned = false,
    orderBlock = false,
    sponsorBlock = false,
  } = profileData?.additionalRegInfo || {};

  return (
    <div className="overview-tab__container" tabIndex={0}>
      <ABOProfilePrimaryAddress address={addresses[0]} privacyFlag={privacyFlag} />
      <ABOProfilePinInfo
        currentPin={currentPin}
        currentPinDate={currentPinDate}
        highestAwardAchieved={highestAwardAchieved}
        highestAwardAchievedDate={highestAwardAchievedDate}
        highestTrackingAward={highestTrackingAward}
        showHighestPin={showHighestPin}
      />
      <ABOProfileAdditionalInfo
        orderBlock={orderBlock}
        sponsorBlock={sponsorBlock}
        autoRenewal={autoRenewal}
        contractSigned={contractSigned}
      />
    </div>
  );
};

export default OverviewTab;
