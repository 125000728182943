import { AWARD_CODES } from "../../../common/enums/award-codes";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getLoginAffAbo } from "../../../reducers/model/boot";
import { updateOverviewDetails } from "../../../reducers/bronzeIncentive";
import { useGetBronzeOverviewQuery } from "../../../services/BronzeIncentives/bronzeIncentivesAPI";
import { storedLocale } from "../../impersonation/util";
import BronzeBannerToast from "../BronzeBannerToast/BronzeBannerToast";
import BIEligibilitySlider from "../BronzeCommon/BIEligibilitySlider/BIEligibilitySlider";
import BronzeIncentivesLoader from "../BronzeCommon/BronzeIncentivesLoader/BronzeIncentivesLoader";
import BBIIncentiveProgress from "./BBIIncentiveProgress/BBIIncentiveProgress";
import BBIMetric from "./BBIMetric/BBIMetric";
import BBIConsistencyPaymentMetric from "./BBIConsistencyPaymentMetric/BBIConsistencyPaymentMetric";
import BBIBannerTile from "./BBIBannerTile/BBIBannerTile";
import "./BronzeBuilder.scss";
import BBIEligibilityBannerTile from "./BBIEligibilityBannerTile/BBIEligibilityBannerTile";
import BBIRequirements from "./BBIRequirements/BBIRequirements";

const BronzeBuilder = () => {
  const dispatch = useAppDispatch();
  const locale = storedLocale();
  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });
  const { configuration } = useAppSelector((state) => state.boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { isoCountryCode = "" } = boot?.user || {};
  const { isoCurrencyCode = "" } = boot?.shellConfig || {};
  const { isStandardRetailEnhancementCountry = false } = configuration?.corePlus || {};
  const primaryAwardCode = isoCountryCode === "MN" ? AWARD_CODES.MN_BRONZE_BUILDER : AWARD_CODES.BRONZE_BUILDER;

  const { data, isFetching } = useGetBronzeOverviewQuery({
    affAbo,
    period: selectedPeriod,
    awardCode: primaryAwardCode,
    locale,
    isoCountryCode,
    isoCurrencyCode,
    fixedPayment: AWARD_CODES.FIXED_PAYMENT,
    restart1: AWARD_CODES.RESTART_BRONZE_BUILDER,
    restart2: AWARD_CODES.RESTART_TWO_BRONZE_BUILDER,
    isStandarRetailEnhancementCountry: isStandardRetailEnhancementCountry,
  });

  if (isFetching === false && data) {
    const { status, displayRules, eligibility } = data;
    const { awardCode, isFixedPayment, fixedPaymentAwardCode } = status;
    const { displayBannerOnly, displayEligibleBannerOnly, showAllBannerTiles } = displayRules;

    dispatch(updateOverviewDetails({ ...status, showEligibilitySliderFlag: false, isFetching: false, eligibility }));

    return (
      <div
        className={`bronze-builder bronze-builder__${displayBannerOnly ? "banner-only" : ""} bronze-builder__${
          displayEligibleBannerOnly ? "eligible-banner-only" : ""
        }`}
      >
        <div className="bronze-builder">
          <div className="bronze-builder__overview">
            <div className="bronze-builder__overview__banner">
              {/** todo: when only display banner, set compact view to false */}
              <BBIBannerTile isCompactView={true} isFetching={isFetching} data={data} />
            </div>
            <div className="bronze-builder__overview__tracking">
              <BBIIncentiveProgress show={showAllBannerTiles} isFetching={isFetching} data={data} />
            </div>
            <div className="bronze-builder__overview__eligible">
              <BBIEligibilityBannerTile data={data} isFetching={isFetching} show={displayBannerOnly === false} />
            </div>
          </div>
          <div className="bronze-builder__incentive-calculation">
            <BBIMetric data={data} isFetching={isFetching} show={showAllBannerTiles} />
            <BBIConsistencyPaymentMetric
              isFixedPayment={isFixedPayment}
              show={showAllBannerTiles}
              data={data}
              isFetching={isFetching}
            />
          </div>
          <div className="bronze-builder__requirements">
            <BBIRequirements data={data} isFetching={isFetching} show={showAllBannerTiles} />
          </div>
        </div>
      </div>
    );
  }
  return <BronzeIncentivesLoader />;
};

export default BronzeBuilder;
