export enum AWARD_CODES {
  TWO_TIME_CASH_INCENTIVE = 3,
  PERFORMANCE_BONUS = 4,
  MONTHLY_PERFORMANCE = 6,
  ANNUAL_PERFORMANCE = 7,
  HAT = 8,
  FAA = 9,
  PERFPRMANCE_BONUS_PERSONAL = 10,
  MGD = 11,
  ACTION_REPORTS = 12,
  EARNINGS_CALCULATOR = 13,
  CML = 14,
  PERFPRMANCE_BONUS_DIFFERENCIAL = 15,
  CUSTOMER_OVERVIEW = 142,
  EDLOS = 150,
  LOS = 16,
  LEARN_ABOUT_CPP = 17,
  WATCHLIST = 18,
  BRONZE_GROUP_TRACKING = 19,
  RUBY = 20,
  LEADERSHIP = 30,
  FOSTER = 40,
  DEPTH = 50,
  INTERNATIONAL_LEADERSHIP = 130,
  EMERALD = 370,
  DIAMOND = 380,
  DIAMOND_PLUS = 381,
  STRONG_START_INCENTIVE = 533,
  STRONG_START_INCENTIVE_MONTH = 534,
  LSI = 700,
  CSI = 925,
  CSI_PERCENTAGE_CHINA = 928,
  BRONZE_FOUNDATION = 930,
  BRONZE_BUILDER = 931,
  MN_BRONZE_FOUNDATION = 924,
  MN_BRONZE_BUILDER = 928,
  FIXED_PAYMENT = 933, // BBI consistency payment code
  BRONZE_FOUNDATION_FIXED_PAYMENT = 936, // BFI consistency payment code
  RESTART_BRONZE_FOUNDATION = 948,
  RESTART_BRONZE_BUILDER = 949,
  RESTART_TWO_BRONZE_FOUNDATION = 952,
  RESTART_TWO_BRONZE_BUILDER = 953,
  PERFORMANCE_PLUS = 940,
  PERFORMANCE_ELITE = 945,
  PERFORMANCE_PLUS_ELITE = 940945,
  MONTHLY_PGG = 954,
  ANNUAL_PGG = 955,
  PGG_KICKER = 956,
  PQ_MONTH = 958,
  PGG_GCR = 959,
  FGG = 960,
  FGG_KICKER = 961,
  FGG_PLATINUM_DETAILS = 962,
  QCG = 981,
  PATH_TO_BRONZE = 530,
  MBI = 143,
  BRONZE_INCENTIVES = 1010,
  VISION_BONUS = 963,
}

export enum TTCI_AWARD_CODES {
  SILVER = 968,
  GOLD = 969,
  PLATINUM = 970,
  FOUNDERS_PLATINUM = 971,
  SAPPHIRE = 972,
  FOUNDERS_SAPPHIRE = 973,
  IN_MARKET_EMERALD = 974,
  IN_MARKET_FOUNDERS_EMERALD = 975,
  IN_MARKET_DIAMOND = 976,
  IN_MARKET_FOUNDERS_DIAMOND = 977,
}

export type BRONZE_AWARD_NUMS =
  | AWARD_CODES.BRONZE_FOUNDATION
  | AWARD_CODES.BRONZE_BUILDER
  | AWARD_CODES.RESTART_BRONZE_FOUNDATION
  | AWARD_CODES.RESTART_BRONZE_BUILDER;
