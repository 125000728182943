import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import LeafApplicantItem from "../LeafApplicantItem/LeafApplicantItem";
import { ABOInfo, PartyInfo, ProfileInfo } from "../../../../../services/Profile/profileAPI.types";
import defaultContent from "../../../../../content/leafApplicantCarousel";
import "./LeafApplicantCarousel.scss";
import LeafProfileLoader from "../../LeafProfileLoader/LeafProfileLoader";
import LeafLinkButton from "../../../LeafLinkButton/LeafLinkButton";
import { showApplicant } from "../../../../../reducers/profile";
import { useAppDispatch } from "../../../../../store";
import {
  cardActionClickTrack,
  viewAllApplicantsClickTrack,
} from "../../../../tealium/ABOProfile/Profile/ApplicantCardTrack";

type Props = {
  aboInfo?: ABOInfo;
  partyInfo?: PartyInfo[];
  isFetching: boolean;
  profileInfo: Partial<ProfileInfo>;
};

const LeafApplicantCarousel = (props: Props) => {
  const { t } = useTranslation(["leafApplicantCarousel"]);
  const dispatch = useAppDispatch();

  const { aboInfo, partyInfo, isFetching, profileInfo } = props;
  const { privacyFlag } = aboInfo || {};
  const numberOfApplicant = partyInfo?.length || 0;

  const applicantTrackOnclick = (event?: string) => {
    cardActionClickTrack(event, false);
  };

  const viewAllApplicant = () => {
    dispatch(showApplicant());
    viewAllApplicantsClickTrack();
  };

  if (isFetching) {
    return <LeafProfileLoader row={1} applicant={true} isFetching={isFetching} />;
  }

  const sliderSetting = {
    dots: false,
    arrows: false,
    infinite: false,
    variableWidth: numberOfApplicant > 1,
    adaptiveHeight: true,
  };

  if (privacyFlag === true || numberOfApplicant === 0) return <></>;

  return (
    <div className="leaf-applicant-carousel">
      <div className="leaf-applicant-carousel__header">
        <h2 className="leaf-applicant-carousel__header__title">{t("applicants", defaultContent["applicants"])}</h2>
        <div className="leaf-applicant-carousel__header__view-all">
          <LeafLinkButton
            onClick={viewAllApplicant}
            label={t("viewAll", defaultContent["viewAll"])}
            fontSize={"medium"}
            fontWeight={"bold"}
          >
            {t("viewAll", defaultContent["viewAll"])}
          </LeafLinkButton>
        </div>
      </div>
      <div className="leaf-applicant-carousel__body">
        <Slider {...sliderSetting}>
          {partyInfo?.map((applicant, index) => {
            return (
              <div
                className="leaf-applicant-carousel__body__card"
                style={numberOfApplicant > 1 ? { width: 301 } : {}}
                key={index}
              >
                <LeafApplicantItem
                  aboInfo={aboInfo}
                  partyInfo={applicant}
                  isExpandable={false}
                  applicantTrackOnclick={applicantTrackOnclick}
                  profileInfo={profileInfo}
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default LeafApplicantCarousel;
