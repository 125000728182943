import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/customers";
import "./LeafCustomerTypeTag.scss";

type Props = {
  businessNatureCode?: number;
  registrationChannelCd?: string;
};

const LeafCustomerTypeTag = (props: Props) => {
  const { businessNatureCode, registrationChannelCd } = props;
  const { t } = useTranslation(["customers"]);

  switch (businessNatureCode) {
    case 3:
      return (
        <span className="tag-customer">{`${t("customerTags.member", defaultContent["customerTags"]["member"])}`}</span>
      );
    case 17:
      return (
        <span className="tag-customer">
          {registrationChannelCd === "ShareBar"
            ? `${t("customerTags.shareBar", defaultContent["customerTags"]["shareBar"])}`
            : `${t("customerTags.guestCustomer", defaultContent["customerTags"]["guestCustomer"])}`}
        </span>
      );
    case 4:
      return (
        <span className="tag-customer">{`${t(
          "customerTags.customer",
          defaultContent["customerTags"]["customer"],
        )}`}</span>
      );
    default:
      return <></>;
  }
};

export default LeafCustomerTypeTag;
