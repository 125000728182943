import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import "./BronzeIncentivesLoader.scss";

type Props = {
  fullpage?: boolean;
};

const BronzeIncentivesLoader = ({ fullpage }: Props) => {
  return (
    <div className={`bronze-incentives-loader-container ${fullpage && "bronze-incentives-loader-fullpage"}`}>
      {fullpage && (
        <>
          <div className="bronze-incentives-loader-container__header">
            <LeafRectangleLoader isLoading={true} numRows={1} width="18.75rem" height="2rem" />
          </div>

          <div className="bronze-incentives-loader-container__header">
            <LeafRectangleLoader isLoading={true} numRows={1} width="30rem" height="2rem" />
          </div>
        </>
      )}

      <div className="bronze-incentives-loader-container__row bronze-incentives-loader-container__first">
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
      </div>

      <div className="bronze-incentives-loader-container__row bronze-incentives-loader-container__middle leaf-tile">
        <div className="bronze-incentives-loader-container__middle-container">
          <LeafRectangleLoader isLoading={true} numRows={1} height="2rem" width={""} />
        </div>
        <div className="bronze-incentives-loader-container__middle-calculation">
          <div className="bronze-incentives-loader-container__row__box">
            <LeafTile isLoading={true} title={" "} rectangleLoaderNumRows={1} />
          </div>
          <div className="bronze-incentives-loader-container__row__box">
            <LeafTile isLoading={true} title={" "} rectangleLoaderNumRows={1} />
          </div>
          <div className="bronze-incentives-loader-container__row__box">
            <LeafTile isLoading={true} title={" "} rectangleLoaderNumRows={1} />
          </div>
        </div>
      </div>

      <div className="bronze-incentives-loader-container__row bronze-incentives-loader-container__second">
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
        <div className="bronze-incentives-loader-container__row__box">
          <LeafTile isLoading={true} title={" "} />
        </div>
      </div>
    </div>
  );
};

export default BronzeIncentivesLoader;
