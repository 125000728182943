import { useTranslation } from "react-i18next";
import { IconShield, BADGE_TYPE, BADGE_SIZE } from "../../../../../common/icons/IconShield/IconShield";
import { GCRMonthTotals } from "../../../../../services/GroupGrowth/PersonalGroupGrowthGCR/personalGroupGrowthGCRAPI.types";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import LeafTable from "../../../../../common/leaf/LeafTable/LeafTable";
import { getShortMonth, getPerformanceYearShort } from "../../../../../common/util/period";
import { useAppSelector } from "../../../../../store";
import { storedLocale } from "../../../../impersonation/util";
import defaultContent from "../../../../../content/annualGcrPGG";
import { BadgeLegend } from "../../../../../common/components/BadgeLegend/BadgeLegend";
import "./YtdTotalsModal.scss";

type Props = {
  showYTDTotalsModal: boolean;
  toggleModal: Function;
  monthTotals: GCRMonthTotals[];
};

export const YtdTotalsModal = ({ showYTDTotalsModal, toggleModal, monthTotals }: Props) => {
  const { t } = useTranslation(["annualGcrPGG"]);
  const locale = storedLocale();
  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const headers = [
    { value: `${t("ytdTotalsModal.spMonths", defaultContent.ytdTotalsModal.spMonths)}`, width: "33%" },
    { value: `${t("ytdTotalsModal.rubyPv", defaultContent.ytdTotalsModal.rubyPv)}`, width: "33%" },
    {
      value: `${t("ytdTotalsModal.performanceBonusTotal", defaultContent.ytdTotalsModal.performanceBonusTotal)}`,
      width: "33%",
    },
  ];

  const createRows = (monthDetails: GCRMonthTotals[]) => {
    const rows = new Array(monthDetails.length);
    let firstCell, secondCell, thirdCell;
    monthDetails.forEach((row, index) => {
      switch (row.SpMonthAchieved) {
        case true:
          firstCell = (
            <span className="YtdTotalsModal-container__first-cell-container">
              <IconShield type={BADGE_TYPE.EARNED} size={BADGE_SIZE.SMALL} />
              <span className="YtdTotalsModal-container__first-cell-container--month">
                {getShortMonth(row.Period.toString(), locale)}
              </span>
            </span>
          );
          secondCell = <span> {row.RubyPv} </span>;
          thirdCell = <span> {row.PerformanceBonusTotal} </span>;
          break;
        default:
          firstCell = (
            <>
              {" "}
              {(row.RubyPv === "-" && row.PerformanceBonusTotal === "-") ||
              (row.RubyPv === "0.00" && row.BonusPercent === "0%" && row.PerformanceBonusTotal.includes("$0.00")) ? (
                <span className="YtdTotalsModal-container__first-cell-container">
                  <IconShield type={BADGE_TYPE.POTENTIAL} size={BADGE_SIZE.SMALL} />
                  <span className="YtdTotalsModal-container__first-cell-container--month">
                    {getShortMonth(row.Period.toString(), locale)}
                  </span>
                </span>
              ) : (
                <span className="YtdTotalsModal-container__first-cell-container">
                  <span className="YtdTotalsModal-container__first-cell-container--percent">{row.BonusPercent}</span>
                  <span className="YtdTotalsModal-container__first-cell-container--month">
                    {getShortMonth(row.Period.toString(), locale)}
                  </span>
                </span>
              )}
            </>
          );

          secondCell = (
            <>
              {" "}
              {row.RubyPv === "-" ||
              (row.RubyPv === "0.00" && row.BonusPercent === "0%" && row.PerformanceBonusTotal.includes("$0.00")) ? (
                <span> - </span>
              ) : (
                <span> {row.RubyPv} </span>
              )}
            </>
          );

          thirdCell = (
            <>
              {row.PerformanceBonusTotal === "-" ||
              (row.RubyPv === "0.00" && row.BonusPercent === "0%" && row.PerformanceBonusTotal.includes("$0.00")) ? (
                <span>-</span>
              ) : (
                <span>{row.PerformanceBonusTotal}</span>
              )}
            </>
          );
      }
      rows[index] = {
        row: [{ value: firstCell }, { value: secondCell }, { value: thirdCell }],
      };
    });

    return rows;
  };

  const renderLegend = () => {
    return <BadgeLegend />;
  };

  return (
    <div className={"YtdTotalsModal-container"}>
      <LeafModal
        open={showYTDTotalsModal}
        onCloseCallbackFn={toggleModal}
        title={`${t("ytdTotalsModal.title", defaultContent.ytdTotalsModal.title)}`}
        subtitle={`${t("ytdTotalsModal.performanceYear", defaultContent.ytdTotalsModal.performanceYear, {
          perfYear: getPerformanceYearShort(selectedPeriod),
        })}`}
        description={renderLegend()}
      >
        <div className="YtdTotalsModal-container__table">
          <LeafTable
            header={headers}
            rows={createRows(monthTotals)}
            columnAlign={"center"}
            headerStyle={"default"}
            hideHeader={false}
          />
        </div>
      </LeafModal>
    </div>
  );
};
