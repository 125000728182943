import { useAppSelector } from "../../../../store";
import { PERSONA } from "../../../../common/enums/persona";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { AWARD_PROGRESS } from "../../../../common/enums/award-progress-codes";
import { HatTrackingTile } from "./HatTrackingTile/HatTrackingTile";
import { TrackingPinData } from "./TrackingPinDataLayer";
import "./TrackingPinInformation.scss";
import { PinTrackingTile } from "./PinTrackingTile/PinTrackingTile";
import { GarTrackingTile } from "./GarTrackingTile/GarTrackingTile";

export const TrackingPinInformation = () => {
  const { shellConfig } = useAppSelector((state) => state.boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { isLoading, trackingPinData, fpTrackingData, errorStatus, refetch } = TrackingPinData(selectedPeriod);

  if (errorStatus) {
    return (
      <ErrorTile
        clickFunction={refetch}
        errorStatus={errorStatus}
        isLoading={isLoading}
        tealiumTrackId="Tracking_Pin_Info_Tile"
      />
    );
  }

  const renderHATAndGARTiles = () => {
    if (shellConfig.persona >= PERSONA.SILVER || shellConfig.persona >= PERSONA.EMERALD) {
      return (
        <div className="TpiTile__tiles--row">
          {shellConfig.persona >= PERSONA.SILVER ? (
            <div className="TpiTile__tiles--tile">
              <HatTrackingTile
                inMarketLegs={trackingPinData.hatDetail.inMarketLegs}
                foundersLegs={trackingPinData.hatDetail.foundersLegs}
                totalLegs={trackingPinData.hatDetail.totalLegs}
                isLoading={isLoading}
              />
            </div>
          ) : (
            <></>
          )}
          {shellConfig.persona >= PERSONA.EMERALD ? (
            <div className="TpiTile__tiles--tile">
              <GarTrackingTile
                lastMonthProcessed={trackingPinData.garDetail.lastMonthProcessed || 0}
                isFoundersDiamond={trackingPinData.garDetail.isFoundersDiamond}
                foundersPlatinumLegs={trackingPinData.garDetail.foundersPlatinumLegs}
                qualificationCredits={trackingPinData.garDetail.qualCredits}
                trackingLevelRank={trackingPinData.currentAwardDetail.trackingLevelRank || 0}
                isLoading={isLoading}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <div className="TpiTile__tiles">
        {renderHATAndGARTiles()}
        <div className="TpiTile__tiles--row">
          <div className="TpiTile__tiles--tile">
            <PinTrackingTile
              qualifiedLegsPinLevelCode={parseInt(trackingPinData.hatDetail.highestTrackingAwardLegsRank)}
              globalPinLevelCode={trackingPinData.garDetail.globalAwardRank}
              currentPinLevelCode={trackingPinData.currentAwardDetail.currentAwardRank}
              highestPinLevelCode={trackingPinData.currentAwardDetail.highestAwardRank}
              fpTrackingData={fpTrackingData}
              isLoading={isLoading}
              currentInMarketPin={parseInt(trackingPinData.hatDetail.currentAwardRank)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
