import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../store";
import { QUALIFICATION_STATUS } from "../../../../common/enums/incentive-enums";
import LeafIncentiveBannerTile from "../../../../common/leaf/LeafIncentiveBannerTile/LeafIncentiveBannerTile";
import defaultContent from "../../../../content/bbiBannerTile";
import { getBronzeDisplayDate } from "../../BronzeCommon/BronzeDate/BronzeDate";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { learnMoreOnClick } from "../../../../common/tealium/BronzeIncentive/BronzeBuilder/LearnMoreButtonTrack";
import { BronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";
import "./BBIBannerTile.scss";

type Props = {
  isCompactView?: boolean;
  data: BronzeOverviewResponse;
  isFetching: boolean;
};

const BBIBannerTile = ({ data, isCompactView = false, isFetching = true }: Props) => {
  const { t } = useTranslation(["bbiBannerTile"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const learnMoreLink = useAppSelector((store) => store.boot?.configuration?.dashboardLinks?.learnMore?.bronzeBuilder);

  const { incomeData } = data;
  const {
    status = "",
    consistencyPayment = {
      target: 0,
      actual: 0,
      met: false,
      schedule: [],
      basePayment: {
        amount: 0,
        amountCurrency: "",
      },
      additionalPayment: {
        amount: 0,
        amountCurrency: "",
      },
      totalPayment: {
        amount: 0,
        amountCurrency: "",
      },
    },
  } = incomeData || {};

  if (!isFetching) {
    const bannerMessages = [
      {
        status: [
          QUALIFICATION_STATUS.NOT_QUALIFIED,
          QUALIFICATION_STATUS.ELIGIBLE,
          QUALIFICATION_STATUS.PENDING_ELIGIBLE,
          QUALIFICATION_STATUS.REWORK_DISQUALIFIED,
        ],
        title: `${t("keepGoing", defaultContent["keepGoing"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.DENIED, QUALIFICATION_STATUS.NOT_ELIGIBLE],
        title: `${t("notEligible", defaultContent["notEligible"], { dateText: getBronzeDisplayDate(selectedPeriod) })}`,
        subtitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.QUALIFIED, QUALIFICATION_STATUS.TRACKING],
        title: `${t("greatJob", defaultContent["greatJob"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.MANUALLY_QUALIFIED],
        title: `${t("manual", defaultContent["manual"])}`,
        subtitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
      },
      {
        status: [QUALIFICATION_STATUS.HELD],
        title: `${t("onHold", defaultContent["onHold"])}`,
        subtitle: `${t("moreInfo", defaultContent["moreInfo"])}`,
      },
    ];

    const statusToHideIncentive = [
      QUALIFICATION_STATUS.DENIED,
      QUALIFICATION_STATUS.NOT_ELIGIBLE,
      QUALIFICATION_STATUS.HELD,
    ];

    return (
      <div className="BBIBannerTile">
        <LeafIncentiveBannerTile
          bannerMessages={bannerMessages}
          incentive={{
            title: `${t("incentiveLabel", defaultContent["incentiveLabel"])}`,
            status: status,
            amount: consistencyPayment.totalPayment.amountCurrency, // The API will sum the base bonus and consistency payment to calculate the total. If the consistency payment is unavailable, the total will still reflect the base bonus amount.
            statusToHideIncentive,
          }}
          learnMoreLink={learnMoreLink}
          isLoading={isFetching}
          isCompactView={isCompactView}
          learnMoreTealiumFunction={learnMoreOnClick}
        />
      </div>
    );
  }
  return <LeafTile isLoading={isFetching} />;
};

export default BBIBannerTile;
