import LeafABOName from "../../../../../common/leaf/LeafProfile/LeafABOName/LeafABOName";
import { useAppSelector } from "../../../../../store";
import "./ABOProfileHeader.scss";
import { useGetABOProfileQuery } from "../../../../../services/Profile/profileAPI";
import LeafProfileLoader from "../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import { LeafErrorMessage } from "../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import LeafPinLevelName from "../../../../../common/leaf/LeafPinLevelName/LeafPinLevelName";

const ABOProfileHeader = () => {
  // Get Login ABO
  const user = useAppSelector((state) => state.boot?.user);
  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const { selectedPeriod } = useAppSelector(({ period }) => period);
  const { isFetching, isError, data } = useGetABOProfileQuery({
    period: selectedPeriod,
    affAbo,
    reqAffAbo: `${user.aff}-${user.abo}`,
  });

  if (isFetching === false && data?.aboInfo && data?.awardInfo) {
    const { aboNo, name, localName, privacyFlag, isoLanguageCode } = data.aboInfo;

    const { currentPin } = data.awardInfo;

    return (
      <>
        <div className="abo-profile-header__abo-details">
          <div className="abo-profile-header__abo-name">
            <LeafABOName
              name={name}
              localName={localName}
              privacyFlag={privacyFlag}
              languageCode={isoLanguageCode}
              fontSize="large"
              fontWeight="bold"
            />
          </div>
          <div className="abo-profile-header__abo-award-label">
            <LeafPinLevelName pinLevelCode={currentPin} />
          </div>
          <div className="abo-profile-header__abo-number">
            <span className="abo-profile-header__abo-number">#{aboNo}</span>
          </div>
        </div>
      </>
    );
  }

  if (isError === true) {
    // TODO :: tealium tagging track event for error
  }

  return (
    <>
      <LeafErrorMessage show={isError} />
      <LeafProfileLoader row={1} isFetching={isFetching} />
    </>
  );
};

export default ABOProfileHeader;
