import { useTranslation } from "react-i18next";
import ChartLegend from "../ChartLegend/ChartLegend";
import defaultContent from "../../../../../content/customers";
import PieChartLoader from "../..//Loader/PieChartLoader/PieChartLoader";
import "./CustomerPVChart.scss";
import PVGreyChart from "../PVGreyChart/PVGreyChart";
import { formattedPV } from "../../../../../components/impersonation/util";
import { LeafPV } from "../../../../../common/leaf/LeafFormat/LeafPV/LeafPV";
import { LeafErrorMessage } from "../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { cpvChartErrorTrack } from "../../../../../common/tealium/Customers/Overview/Chart/CustomerPVTrack";
import CustomerPVDonut from "./CustomerPVDonut/CustomerPVDonut";

type Props = {
  isLoading: boolean;
  customerPV: number;
  vcsPV: number;
  nonVCSPV: number;
  otherNonVCSPV: number;
  isError: boolean;
  period: string;
};

const CustomerPVChart = (props: Props) => {
  const { t } = useTranslation(["customers"]);
  const { isLoading, customerPV, vcsPV, nonVCSPV, otherNonVCSPV, isError, period } = props;

  if (isError === true) {
    cpvChartErrorTrack(period);
  }

  const getPercentage = (pv: number) => {
    const _percentage = (pv / customerPV) * 100;
    const needToMask = pv <= 0 || _percentage <= 0;
    return needToMask ? "- " : formattedPV(_percentage);
  };

  const vcsPVPercent = getPercentage(vcsPV);
  const nonVcsPVPercent = getPercentage(nonVCSPV);
  const otherNonVcsPVPercent = getPercentage(otherNonVCSPV);
  const showGreyChart = customerPV === 0;

  const getPVChart = () => {
    return showGreyChart ? (
      <PVGreyChart />
    ) : (
      <CustomerPVDonut
        textContent={{
          legendVcsPvText: `${t("vcsPv", defaultContent["vcsPv"])}`,
          legendNonVcsPvText: `${t(
            "customerPVChart.legendNonVcsPvText",
            defaultContent["customerPVChart"]["legendNonVcsPvText"],
          )}`,
          legendOtherNonVcsPvText: `${t(
            "customerPVChart.legendOtherNonVcsPvText",
            defaultContent["customerPVChart"]["legendOtherNonVcsPvText"],
          )}`,
        }}
        vcsPv={vcsPV}
        nonVcsPV={nonVCSPV}
        otherNonVcsPV={otherNonVCSPV}
      />
    );
  };

  const CustomerPVContainer = () => (
    <div className="customer-pv">
      <div className="customer-pv__container">
        <div className="customer-pv__title">{`${t(
          "customerPVChart.title",
          defaultContent["customerPVChart"]["title"],
        )}`}</div>
        <LeafErrorMessage show={isError} />
        {isError === false && (
          <>
            <div className="customer-pv__subtitle">
              <span className="customer-pv__total">{`${t("total", defaultContent["total"])}`}</span>{" "}
              <span className="customer-pv__cpv-value">
                <LeafPV pv={customerPV} />
              </span>{" "}
              <span className="customer-pv__cpv">{`${t(
                "customerPVChart.cpv",
                defaultContent["customerPVChart"]["cpv"],
              )}`}</span>
            </div>
            {getPVChart()}
            <ChartLegend
              legends={[
                {
                  id: "vcsPV",
                  text: `${t("vcsPv", defaultContent["vcsPv"])}`,
                  value: t("customerPVChart.legendVcsPvValue", defaultContent["customerPVChart"]["legendVcsPvValue"], {
                    PV: formattedPV(vcsPV),
                    PERCENTAGE: vcsPVPercent,
                  }),
                  color: "kournikova",
                },
                {
                  id: "nonVCSPV",
                  text: `${t(
                    "customerPVChart.legendNonVcsPvText",
                    defaultContent["customerPVChart"]["legendNonVcsPvText"],
                  )}`,
                  value: t(
                    "customerPVChart.legendNonVcsPvValue",
                    defaultContent["customerPVChart"]["legendNonVcsPvValue"],
                    {
                      PV: formattedPV(nonVCSPV),
                      PERCENTAGE: nonVcsPVPercent,
                    },
                  ),
                  color: "sandyBrown",
                },
                {
                  id: "otherNonVCSPV",
                  text: `${t(
                    "customerPVChart.legendOtherNonVcsPvText",
                    defaultContent["customerPVChart"]["legendOtherNonVcsPvText"],
                  )}`,
                  value: t(
                    "customerPVChart.legendOtherNonVcsPvValue",
                    defaultContent["customerPVChart"]["legendOtherNonVcsPvValue"],
                    {
                      PV: formattedPV(otherNonVCSPV),
                      PERCENTAGE: otherNonVcsPVPercent,
                    },
                  ),
                  color: "darkerOrange",
                },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );

  return <PieChartLoader isLoading={isLoading} View={CustomerPVContainer} />;
};

export default CustomerPVChart;
