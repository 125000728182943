import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Eligibility } from "../services/BronzeIncentives/bronzeIncentivesAPI.types";

type BronzeIncentive = {
  showEligibilitySliderFlag: boolean;
  awardCode: number;
  programActive: boolean;
  isEligible: boolean;
  isRestartProgram: boolean;
  isFixedPayment: boolean;
  fixedPaymentAwardCode: number;
  eligibility: Eligibility;
  isFetching: boolean;
};

const initialState: BronzeIncentive = {
  showEligibilitySliderFlag: false,
  awardCode: 0,
  programActive: false,
  isEligible: false,
  isRestartProgram: false,
  isFixedPayment: false,
  fixedPaymentAwardCode: 0,
  eligibility: {
    reason: "",
    eligible: false,
    startPeriod: 0,
    endPeriod: 0,
    graduated: false,
    notStarted: false,
    mostRecentAcheivedPeriod: 0,
    remainingIncentiveEarnings: 0,
    qualifiedCount: 0,
    inWindows: false,
    months: [],
    programDuration: 0,
  },
  isFetching: true,
};

const { actions, reducer } = createSlice({
  name: "bronzeIncentive",
  initialState,
  reducers: {
    showEligibilitySlider: (state) => ({
      ...state,
      showEligibilitySliderFlag: true,
      awardCode: state.awardCode,
    }),
    hideEligibilitySlider: (state) => ({
      ...state,
      showEligibilitySliderFlag: false,
    }),
    updateOverviewDetails: (state, action: PayloadAction<BronzeIncentive>) => ({
      ...state,
      ...action.payload,
    }),
    resetOverviewDetails: () => initialState,
  },
});

export const { showEligibilitySlider, hideEligibilitySlider, updateOverviewDetails, resetOverviewDetails } = actions;

export default reducer;
