import Statistic from "../Statistic";
import { getLoginAffAbo } from "../../../../../reducers/model/boot";
import { useGetRecentJoinedStatQuery } from "../../../../../services/Customer/RecentJoinedStat/recentJoinedStatAPI";
import { useAppSelector } from "../../../../../store";

const NewCustomersStatistic = () => {
  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });

  const { data, isFetching } = useGetRecentJoinedStatQuery({ affAbo, period: selectedPeriod });
  const { current = 0, previous = 0, entryDate = "" } = data || {};

  return (
    <Statistic
      isFetching={isFetching}
      current={current}
      previous={previous}
      entryDate={entryDate}
      selectedPeriod={selectedPeriod}
      module="NewCustomer"
    />
  );
};

export default NewCustomersStatistic;
