import { accAPI } from "../..";
import { RecentJoinedStatParams, RecentJoinedStatResponse } from "./recentJoinedStatAPI.types";

export const recentJoinedStatAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getRecentJoinedStat: build.query<RecentJoinedStatResponse, RecentJoinedStatParams>({
      query: (params) => {
        const { affAbo, period } = params;
        const queryParams = new URLSearchParams({
          period,
        });
        return `/customer/v2/${affAbo}/stat/recent/joined?${queryParams}`;
      },
    }),
  }),
});

export const { useGetRecentJoinedStatQuery } = recentJoinedStatAPI;
