import { formatInteger } from "@amwaycommon/acc-mixins/l10n";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/analytics";
import { useAppSelector } from "../../../../../store";
import { storedLocale } from "../../../../impersonation/util";
import { PERSONA } from "../../../../../common/enums/persona";
import { ROUTE_PATHS } from "../../../../../common/enums/routing-enums";
import LeafTile from "../../../../../common/leaf/LeafTile/LeafTile";
import LeafTileDivider from "../../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import LeafTileTitleLink from "../../../../../common/leaf/LeafTileTitleLink/LeafTileTitleLink";
import defaultContent from "../../../../../content/annualPerformanceDashboard";
import "./HatTrackingTile.scss";
import TEALIUM from "../../../../../common/enums/tealium-enums";

type Props = {
  inMarketLegs: number;
  foundersLegs: number;
  totalLegs: number;
  isLoading: boolean;
};

export const HatTrackingTile = ({ inMarketLegs, foundersLegs, totalLegs, isLoading }: Props) => {
  const { t } = useTranslation(["annualPerformanceDashboard"]);
  const locale = storedLocale();
  const { user } = useAppSelector((state) => state.boot);
  const { shellConfig } = useAppSelector((state) => state.boot);
  const market = user.isoCountryCode;
  const disable = !shellConfig.shouldShowHAT;

  const trackTileLinkClick = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "Hat Legs Tracking",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "trackingTile",
    );
  };

  return (
    <LeafTile aria-label="HAT Tracking Tile" isLoading={isLoading}>
      <div className="HAT">
        <div className="HAT__title-link">
          <LeafTileTitleLink
            title={t("hatTileTitle", defaultContent["hatTileTitle"])}
            link={ROUTE_PATHS.HAT}
            disable={disable}
            onClick={trackTileLinkClick}
          />
        </div>
        {shellConfig.persona >= PERSONA.EMERALD ? <div className="HAT__spacing" /> : <></>}
        <LeafTileDivider />
        <div className="HAT__content-container">
          <p className="HAT__row-label">{t("hatInMarket", defaultContent["hatInMarket"])}</p>
          <span className="HAT__row-value">{disable ? "--" : formatInteger(inMarketLegs, locale)}</span>

          <p className="HAT__row-label">{t("hatFoundersLegs", defaultContent["hatFoundersLegs"])}</p>
          <span className="HAT__row-value">{disable ? "--" : formatInteger(foundersLegs, locale)}</span>

          <p className="HAT__row-label">{t("hatTotalLegs", defaultContent["hatTotalLegs"])}</p>
          <span className="HAT__row-value">{disable ? "--" : formatInteger(totalLegs, locale)}</span>
        </div>
      </div>
    </LeafTile>
  );
};
