import { Font } from "@react-pdf/renderer";
import { storedLocale } from "../../impersonation/util";
import hktwRegular from "../../../common/fonts/notosans/hk-tw/regular.ttf";
import hktwBold from "../../../common/fonts/notosans/hk-tw/bold.ttf";
import thRegular from "../../../common/fonts/notosans/th/regular.ttf";
import thBold from "../../../common/fonts/notosans/th/bold.ttf";
import jpRegular from "../../../common/fonts/notosans/jp/regular.ttf";
import jpBold from "../../../common/fonts/notosans/jp/bold.ttf";
import krRegular from "../../../common/fonts/notosans/kr/regular.ttf";
import krBold from "../../../common/fonts/notosans/kr/bold.ttf";
import scRegular from "../../../common/fonts/notosans/sc/regular.ttf"; // Simplified Chinese Regular
import scBold from "../../../common/fonts/notosans/sc/bold.ttf"; // Simplified Chinese Bold
import defaultRegular from "../../../common/fonts/notosans/default/regular.ttf";
import defaultBold from "../../../common/fonts/notosans/default/bold.ttf";
import defaultItalic from "../../../common/fonts/notosans/default/italic.ttf";

export const getLocaleFonts = () => {
  const locale = localStorage.getItem("locale") || storedLocale();

  switch (locale) {
    case "zh-TW":
    case "zh-HK":
    case "zh-MO":
      return {
        regular: hktwRegular,
        bold: hktwBold,
        italic: hktwRegular,
      };
    case "zh-CN":
      return {
        regular: scRegular,
        bold: scBold,
        italic: scRegular,
      };
    case "th-TH":
      return {
        regular: thRegular,
        bold: thBold,
        italic: thRegular,
      };
    case "ja-JP":
      return {
        regular: jpRegular,
        bold: jpBold,
        italic: jpRegular,
      };
    case "ko-KR":
      return {
        regular: krRegular,
        bold: krBold,
        italic: krRegular,
      };
    default:
      return {
        regular: defaultRegular,
        bold: defaultBold,
        italic: defaultItalic,
        isDefault: true,
      };
  }
};

export const registerFonts = () => {
  const { regular, bold, italic, isDefault } = getLocaleFonts();

  const defaultFonts: any = [
    { src: regular, fontWeight: "normal" },
    { src: bold, fontWeight: "bold" },
    { src: italic, fontStyle: "italic" },
  ];
  const withoutItalic: any = [
    { src: regular, fontWeight: "normal" },
    { src: bold, fontWeight: "bold" },
  ];

  Font.register({
    family: "NotoSans",
    fonts: isDefault ? defaultFonts : withoutItalic,
  });
};
