import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/qcGrowth";
import { RequirementTileMapper } from "../../../common/components/RequirementTileMapper/RequirementTileMapper";
import { RequirementsWithQC } from "../../../services/GroupGrowth/groupGrowth.types";
import "./QCGRequirements.scss";
import { BadgeRequirementPreviousTile } from "../../../common/components/RequirementTiles/BadgeRequirementPreviousTile/BadgeRequirementPreviousTile";

type Props = {
  requirements: RequirementsWithQC;
  isLoading: boolean;
};

export const QCGRequirements = ({ requirements, isLoading }: Props) => {
  const { t } = useTranslation(["qcGrowth"]);

  return (
    <section className="QCGRequirements">
      <h2>{t("requirements.sectionHeader", String(defaultContent.requirements.sectionHeader))}</h2>
      <div className="QCGRequirements__requirement">
        <RequirementTileMapper
          numOfLoadersOverride={requirements.TotalRequirements - requirements.QCRequirements.length}
          isLoading={isLoading}
          requirements={requirements}
          content={defaultContent}
          translation="qcGrowth"
        />
      </div>
      {requirements.QCRequirements.map((qcReq, index) => (
        <div key={index} className="QCGRequirements__QCTile">
          <BadgeRequirementPreviousTile
            title={t(`requirements.maintainQc.title`, defaultContent["requirements"]["maintainQc"]["title"] as string, {
              bonusAmount: qcReq.Target,
            })}
            status={qcReq.Status}
            showLabel={qcReq.ShowLabel}
            pyStatus={qcReq.PyStatus}
            currentYtd={qcReq.CurrentYtd}
            isLoading={isLoading}
          />
        </div>
      ))}
    </section>
  );
};
