import { useEffect, useState } from "react";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { useTranslation } from "react-i18next";
import { BonusInfoFooter } from "../../../common/components/BonusInfoFooter/BonusInfoFooter";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { ErrorTile } from "../../../common/components/ErrorTile/ErrorTile";
import defaultContent from "../../../content/annualPGG";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../../store";
import TEALIUM from "../../../common/enums/tealium-enums";
import "./AnnualPGG.scss";
import { RequirementTileMapper } from "../../../common/components/RequirementTileMapper/RequirementTileMapper";
import PGGTabSwitcher from "../PGGTabSwitcher";
import { PageTitleSection } from "../../../common/components/PageTitleSection/PageTitleSection";
import { PGGBannerTile } from "./BannerTile/PggBannerTile";
import { MultiplierDetails } from "./MultiplierDetails/MutliplierDetails";
import PGGIncentiveCalculation from "./PGGIncentiveCalculation/PGGIncentiveCalculation";
import TrackProgress from "./TrackProgress/TrackProgress";
import { FetchAnnualPggOverviewData } from "./AnnualPGGDataLayer";
import { MonthlyRubyRequirements } from "./MonthlyRequirements/MonthlyRubyRequirements";

export default function AnnualPGG() {
  const { t } = useTranslation(["annualPGG"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { shouldShowPGGToggle, isComingSoon = false } = useAppSelector((state) => state.boot.shellConfig);

  const multiplierDetailsFootnote = t(
    "multiplierDetails.footnote",
    defaultContent.multiplierDetails.footnote as string,
  );

  const {
    annualPggOverviewData,
    isLoading,
    errorStatus,
    refetch: refetchAnnualPggOverviewData,
  } = FetchAnnualPggOverviewData(selectedPeriod, isComingSoon);

  const dispatch = useAppDispatch();
  const [multiplierDetailsExpanded, setMultiplierDetailsExpanded] = useState(false);

  const monthlyRequirements = annualPggOverviewData.MonthlyRequirements;
  const annualRequirements = annualPggOverviewData.AnnualRequirements;
  const showTrackingTiles = !(
    annualPggOverviewData.IncomeData.Status === QUALIFICATION_STATUS.MANUALLY_APPROVED ||
    annualPggOverviewData.IncomeData.Status === QUALIFICATION_STATUS.DENIED ||
    annualPggOverviewData.IncomeData.Status === QUALIFICATION_STATUS.HELD ||
    isComingSoon
  );

  const showRequirements = monthlyRequirements.TotalRequirements > 0 || annualRequirements.TotalRequirements > 0;

  let classAddition = "";
  if (monthlyRequirements.TotalRequirements + annualRequirements.TotalRequirements === 2) {
    classAddition = "-shortline";
  } else if (monthlyRequirements.TotalRequirements === 1 && annualRequirements.TotalRequirements === 2) {
    classAddition = "-line";
  } else if (monthlyRequirements.TotalRequirements === 2 && annualRequirements.TotalRequirements === 1) {
    classAddition = "-backline";
  }

  useEffect(() => {
    track(
      TEALIUM.U_TAG_METHOD.VIEW,
      "",
      "personal_group_growth",
      "personal_group_growth",
      `annual_pgg${selectedPeriod}`,
      "",
    );
  }, [selectedPeriod]);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
  }, [dispatch]);

  if (errorStatus) {
    return (
      <LeafTile hideBorder={true}>
        <ErrorTile
          clickFunction={refetchAnnualPggOverviewData}
          errorStatus={errorStatus}
          isLoading={isLoading}
          tealiumTrackId="Annual_PGG_Overview"
        />
      </LeafTile>
    );
  }
  return (
    <main className="Pgg">
      <div className="Pgg__container">
        <PageTitleSection
          title={
            shouldShowPGGToggle
              ? `${t("pageTitle.personalGrowthAnnual", String(defaultContent["pageTitle"]["personalGrowthAnnual"]))}`
              : `${t("pageTitle.personalGroupGrowth", String(defaultContent["pageTitle"]["personalGroupGrowth"]))}`
          }
        />
        {shouldShowPGGToggle && <PGGTabSwitcher />}
        <div className="Pgg__ container-split">
          <div className="Pgg__section-padding Pgg__container-row">
            <section className="Pgg__banner">
              <PGGBannerTile
                incomeData={annualPggOverviewData.IncomeData}
                isLoading={isLoading}
                isNewPerformanceYear={isComingSoon}
              />
            </section>
            <section className="Pgg__tracking">
              {isLoading ? (
                <LeafTile isLoading={true} />
              ) : (
                showTrackingTiles &&
                showRequirements && (
                  <TrackProgress
                    isLoading={isLoading}
                    MonthlyRequirementsMet={monthlyRequirements.RequirementsMet}
                    MonthlyRequirementsTotal={monthlyRequirements.TotalRequirements}
                    MonthlyRequirementMetStatuses={monthlyRequirements.RequirementMetStatuses}
                    AnnualRequirementsMet={annualRequirements.RequirementsMet}
                    AnnualRequirementsTotal={annualRequirements.TotalRequirements}
                    AnnualRequirementMetStatuses={annualRequirements.RequirementMetStatuses}
                  />
                )
              )}
            </section>
          </div>

          {showTrackingTiles && (
            <div className="Pgg__section-padding Pgg__calculation">
              <div>
                <h2>{t("incentiveCalc.sectionHeader", String(defaultContent.incentiveCalc.sectionHeader))}</h2>
              </div>
              <div className="Pgg__multiplication">
                <PGGIncentiveCalculation
                  multiplierData={annualPggOverviewData.IncentiveData.MultiplierData}
                  isLoading={isLoading}
                  selectedPeriod={selectedPeriod}
                  isExpanded={multiplierDetailsExpanded}
                />
              </div>
              <MultiplierDetails
                overviewData={annualPggOverviewData}
                selectedPeriod={selectedPeriod}
                isLoading={isLoading}
                onExpand={setMultiplierDetailsExpanded}
              />
              {multiplierDetailsFootnote && (
                <section className="Pgg__multiplierDetailsFootnote">{multiplierDetailsFootnote}</section>
              )}
            </div>
          )}
          {showTrackingTiles && showRequirements && (
            <div className="Pgg__section-padding Pgg__requirements">
              <div>
                <h2>{t("requirements.sectionHeader", String(defaultContent.requirements.sectionHeader))}</h2>
              </div>
              {annualRequirements.TotalRequirements === 1 || annualRequirements.TotalRequirements === 2 ? (
                <div className={"Pgg__requirements-titles" + classAddition}>
                  {monthlyRequirements.TotalRequirements > 0 && (
                    <div
                      className={
                        "Pgg__requirements-titles" +
                        classAddition +
                        "-section Pgg__requirements-titles" +
                        classAddition +
                        "-monthly"
                      }
                    >
                      <div className={"Pgg__requirements-titles" + classAddition + "-section-title"}>
                        {t("requirements.monthlyRequirements", String(defaultContent.requirements.monthlyRequirements))}
                      </div>
                    </div>
                  )}
                  {annualRequirements.TotalRequirements > 0 && (
                    <div
                      className={
                        "Pgg__requirements-titles" +
                        classAddition +
                        "-section Pgg__requirements-titles" +
                        classAddition +
                        "-annual"
                      }
                    >
                      <div className={"Pgg__requirements-titles" + classAddition + "-section-title"}>
                        {t("requirements.annualRequirements", String(defaultContent.requirements.annualRequirements))}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className={"Pgg__requirements-sections" + classAddition}>
                {monthlyRequirements.TotalRequirements > 0 && (
                  <div
                    className={
                      "Pgg__requirements-sections" +
                      classAddition +
                      "-section Pgg__requirements-sections" +
                      classAddition +
                      "-monthly"
                    }
                  >
                    <div className={"Pgg__requirements-sections" + classAddition + "-section-title"}>
                      {t("requirements.monthlyRequirements", String(defaultContent.requirements.monthlyRequirements))}
                    </div>
                    <div className={"Pgg__requirements-sections" + classAddition + "-section-content"}>
                      <MonthlyRubyRequirements isLoading={isLoading} requirements={monthlyRequirements} />
                    </div>
                  </div>
                )}
                {monthlyRequirements.TotalRequirements > 0 && annualRequirements.TotalRequirements > 0 && (
                  <div className="vertical-divider" />
                )}
                {annualRequirements.TotalRequirements > 0 && (
                  <div
                    className={
                      "Pgg__requirements-sections" +
                      classAddition +
                      "-section Pgg__requirements-sections" +
                      classAddition +
                      "-annual"
                    }
                  >
                    <div className={"Pgg__requirements-sections" + classAddition + "-section-title"}>
                      {t("requirements.annualRequirements", String(defaultContent.requirements.annualRequirements))}
                    </div>
                    <div className={"Pgg__requirements-sections" + classAddition + "-section-content"}>
                      <RequirementTileMapper
                        type="annual"
                        isLoading={isLoading}
                        requirements={annualRequirements}
                        content={defaultContent}
                        translation="annualPGG"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <section className="Pgg__footer">
          <BonusInfoFooter />
        </section>
      </div>
    </main>
  );
}
