import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import defaultContent from "../../../../content/bronzeGroupTracking";
import "./BGTTrackingList.scss";
import { AboTileData } from "../../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import { TrackingTile } from "../TrackingTile/TrackingTile";
import { BGTListItem } from "../../bronzeGroupTrackingUtils";

export const BGTTrackingTable = ({ trackingTilesData }: { trackingTilesData: AboTileData[] }) => {
  const { t } = useTranslation(["bronzeGroupTracking"]);

  const [currPage, setCurrPage] = useState(0);
  const [data, setData] = useState([] as AboTileData[]);
  const [pages, setPages] = useState<AboTileData[][]>([][0] as AboTileData[][]);
  const pageSize = 10;

  useEffect(() => {
    const pages = [];
    for (let i = 0; i < trackingTilesData.length; i += pageSize) {
      const chunk = trackingTilesData.slice(i, i + pageSize);
      pages.push(chunk);
    }
    setData(pages[0]);
    setPages(pages);
    setCurrPage(1);
  }, [trackingTilesData]);

  const BronzeGroupTrackingNoDataMsg = () => {
    return <div className="bgtNoData">{`${t("noDownlinesCriteria", defaultContent["noDownlinesCriteria"])}`}</div>;
  };

  const getNextPageData = () => {
    setData([...data, ...pages[currPage]]);
    setCurrPage(currPage + 1);
  };

  return (
    <div className="bgt-table">
      <div className="bgt-table__header-sticky-wrapper bgt-table__display-header--desktop">
        <div className="bgt-table__row bgt-table__header">
          <div className={`bgt-table__cell bgt-table__cell--name`}>{`${t("name", defaultContent["name"])}`}</div>
          <div className="bgt-table__cell">{`${t("tracking", defaultContent["tracking"])}`}</div>
          <div className="bgt-table__cell bgt-table__cell bgt-table__cell--tags">{`${t(
            "tags",
            defaultContent["tags"],
          )}`}</div>
          <div className="bgt-table__cell bgt-table__cell--progress">{`${t(
            "progress",
            defaultContent["progress"],
          )}`}</div>
          <div className="bgt-table__cell bgt-table__cell--eligibility">{`${t(
            "eligibility",
            defaultContent["eligibility"],
          )}`}</div>
          <div className="bgt-table__arrow">
            <span className="bgt-table__arrow--reserved"></span>
          </div>
        </div>
      </div>

      {data && data.length > 0 ? (
        <InfiniteScroll
          className="bgt-table__infinite-scroll"
          dataLength={data.length}
          loader={""}
          hasMore={data.length < trackingTilesData.length}
          next={getNextPageData}
        >
          <ul>
            {data.map((abo, index) => (
              <BGTListItem
                tabIndex={0}
                key={index}
                className="bgt-table__row-list-item"
                data-testid="bgt-tracking-row-item"
                aboTileData={abo}
              >
                <TrackingTile {...abo} />
              </BGTListItem>
            ))}
          </ul>
        </InfiniteScroll>
      ) : (
        <BronzeGroupTrackingNoDataMsg />
      )}
    </div>
  );
};
