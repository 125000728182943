import { useGetCustomerExportNotificationQuery } from "../../../../services/Export/exportAPI";
import { MODULE_CUSTOMER } from "../../../../common/constants/export-constants";
import CustomerExportNotifyContainer from "../CustomerExportNotifyContainer";

const CustomerListNotify = () => {
  const { data, isFetching } = useGetCustomerExportNotificationQuery(
    { moduleId: MODULE_CUSTOMER },
    {
      pollingInterval: 30000,
    },
  );

  const { exports = [] } = data || {};
  const haveDone = exports.some(({ done }) => done);
  const showToast = haveDone === true && isFetching === false;

  return <CustomerExportNotifyContainer showToast={showToast} />;
};

export default CustomerListNotify;
