import { useEffect, useState } from "react";
import { store } from "../../store";
import { useGetBusinessSelectorQuery } from "../../services/BusinessSelector/businessSelectorAPI";
import { getCurrentPeriod } from "../../common/util/period";

export const FetchBusinessSelectorData = (aff: string, abo: string, skip: boolean) => {
  const [initialParams, setInitialParams] = useState<Record<string, string>>({});

  // fetch business selector data for initial logged in abo
  if (!initialParams || Object.keys(initialParams).length === 0) {
    setInitialParams({
      abo,
      aff,
      baseUrl: `${store.getState().boot.configuration.lambdaUrlBase}`,
      selectedPeriod: getCurrentPeriod(),
    });
  }
  const {
    data: payloadData,
    isLoading: isQueryLoading,
    isFetching,
    isError,
  } = useGetBusinessSelectorQuery(
    {
      baseUrl: initialParams.baseUrl,
      abo: initialParams.abo,
      aff: initialParams.aff,
      selectedPeriod: initialParams.selectedPeriod,
      useLite: true,
    },
    {
      skip,
    },
  );

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [data, setData] = useState<any>(null);

  // if loading or fetching set loading flag to true
  useEffect(() => {
    if (isQueryLoading || isFetching) {
      setIsLoading(true);
    }
  }, [isQueryLoading, isFetching]);

  useEffect(() => {
    if (isError) {
      setErrorStatus(true);
      setIsLoading(false);
    }
  }, [payloadData, isError, isQueryLoading, isFetching]);

  // once loading has finished and data is resolved, save to state and set loading to false
  useEffect(() => {
    if (!isQueryLoading && !isFetching && !isError) {
      setData(payloadData);
      setIsLoading(false);
      setErrorStatus(false);
    }
  }, [payloadData, isError, isQueryLoading, isFetching]);

  return { isLoading, data, errorStatus };
};
