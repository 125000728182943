const content: { [key: string]: string | any } = {
  pageTitle: "Two-time Cash Incentives+",
  howQualify: "How do I qualify?",
  learnMore: "Learn More",
  ppvTile: {
    title: "{target} PPV Monthly Average",
    description:
      "You must have an average of at least {required} PPV per month in PY{PY} at the time of pin achievement.",
    viewScheduleBtnText: "View PPV Target Schedule",
    progressBarCurrentLabel: "{currentPV} PV",
    progressBarTargetLabel: "Target: {requiredPV} PV",
  },
  vcsTile: {
    title: "{target}% Cumulative VCS",
    description: "VCS must be at least {required}% of your PY{PY} Personal Volume at the time of pin achievement.",
  },
  bannerTile: {
    notQualifiedTitle: "You’re <1>not yet</1> meeting all requirements for a Two Time Cash Incentive+",
    onTrackTitle: "You’re <1>on track</1> for a Two Time Cash Incentive+",
    newPerformanceYearTitle: "The year ahead belongs to you",
    noLongerQualifiedTitle: "You can <1>no longer qualify</1> for a Two Time Cash Incentive+ in {currentPy}",
    onHoldTitle: "You have a Two Time Cash Incentive+ that is <1>on hold</1>",
    manuallyApprovedTitle: "You have a Two Time Cash Incentive+ that has been <1>approved</1>",
    deniedTitle: "You have a Two Time Cash Incentive+ that has been <1>denied</1>",
    achievedTitle: "<1>Congratulations!</1> You’ve earned the Two Time Cash Incentive+ in {currentPy}. ",
    earnTtciSubTitle:
      "You can earn the Two Time Cash Incentive+ both the first time you reach a pin and if you requalify for that pin the following year.",
    newPerformanceYearSubTitle:
      "Happy new Performance Year! Information for the new performance year is coming soon. Updated information will become available when last performance year's baselines have been confirmed.",
    contactAmway: "For more information please contact Amway Customer Care.",
    potentialEarnings: "Potential {currentPy} Earnings",
    earnableBonus: "You can earn up to <1>{maxBonus}</1> with this bonus!",
  },
  tracking: "Tracking",
  approved: "Approved",
  denied: "Denied",
  onHold: "On Hold",
  firstYear: "First Year",
  viewAllTTCIEligibility: "View All TTCI Eligibility",
  requalification: "Requalification",
  achieved: "Achieved",
  ineligible: "Ineligible",
  achieveEligiblePin: "Achieve an Eligible Pin",
  eligiblePinsPY: "These are your eligible TTCI+ pins in {PY}.",
  additionalPvNeeded: "Additional <strong>{pvNeeded} PV needed</strong>",
  additionalPercentNeeded: "Additional <strong>{percentNeeded}% needed</strong>",
  viewAllTtci: "View All TTCI Eligibility",
  volumeReqNotMet:
    "You must meet the Cumulative VCS% and PPV Monthly Average requirements to achieve the Two Time Cash Incentive+",
  eligiblePinReqMessage:
    "You achieved a pin eligible for a TTCI+ reward in {currentPy} during a month that did not meet all TTCI+ requirements. Please contact Customer Care.",
  vcsReqMessage:
    "The VCS requirement is a percentage of your PV and may fall out of qualification before the month closes.",
  targetSchedule: {
    modalTitle: "PPV Target Schedule",
    modalDescription:
      "To earn a cumulative average of at least {targetValue} PPV per month for the performance year, you can make up for shortfalls in previous months by earning more PPV in the following months.",
    scaleTitle: "PY{year} PV",
    legendTitle: "Monthly Target",
  },
  ttciEligibility: {
    modalTitle: "TTCI Eligibility",
    eligibleTitle: "Eligible",
    eligibleDesc:
      "A pin is eligible for a first-time TTCI+ incentive if the pin has never been achieved. You can only requalify for the incentive in the next consecutive year after first-time pin achievement.",
    ineligibleTitle: "Ineligible",
    ineligibleDesc:
      "A pin is ineligible for a first-time TTCI+ incentive if the pin was achieved in a past year. You can only requalify for the incentive in the next consecutive year after first-time pin achievement, and it becomes ineligible at the end of that year.",
    heldTitle: "Held",
    heldDesc: "We are reviewing your TTCI+ eligibility. Please contact Customer Care.",
    noHeldPinsDesc:
      "A pin is consider held if the business account is under review or if not all TTCI requirements are met at the time of pin achievement.",
    noEligiblePins: "No eligible pins",
    noHeldPins: "No held pins",
    noHeldPinsAtThisTime: "You have no held TTCI+ pins at this time.",
    noIneligiblePins: "No ineligible pins",
    noIneligiblePinsAtThisTime: "You have no ineligible TTCI+ pins at this time.",
  },
};

export default content;
