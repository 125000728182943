import { useEffect, useState } from "react";
import { PPEOverviewResponse } from "../../services/PerformancePlusElite/performancePlusEliteAPI.types";
import { useAppSelector } from "../../store";
import { storedLocale } from "../impersonation/util";
import { lambdaErrorCheck } from "../../common/util/errorHandling";
import { QUALIFICATION_STATUS } from "../../common/enums/incentive-enums";
import { REQUIREMENT_NAME } from "../../common/enums/requirements";
import { useGetPPEOverviewAPIQuery } from "../../services/PerformancePlusElite/performancePlusEliteAPI";

const defaultPPEOverviewResponse = (): PPEOverviewResponse => {
  return {
    IncomeData: {
      PotentialEarnings: "",
      IncentiveCap: "",
      Status: QUALIFICATION_STATUS.UN_AVAILABLE,
    },
    IncentiveCalculation: {
      RubyBV: "",
      IncentiveMultiplier: "",
      Total: "",
    },
    MultiplierMatrix: {
      Rows: [],
    },
    Requirements: {
      VolumeRequirements: [
        {
          Name: REQUIREMENT_NAME.PERSONAL,
          Met: false,
          Exempt: false,
          Volume: {
            Current: {
              Actual: 0,
              Formatted: "",
            },
            Target: {
              Actual: 0,
              Formatted: "",
            },
            Remaining: {
              Actual: 0,
              Formatted: "",
            },
          },
        },
      ],
      BadgeRequirements: [
        {
          Name: REQUIREMENT_NAME.PERSONAL,
          Met: false,
          Status: QUALIFICATION_STATUS.UN_AVAILABLE,
          Target: "",
        },
      ],
      PercentRequirements: [
        {
          Name: REQUIREMENT_NAME.PERSONAL,
          Met: false,
          Percent: {
            Current: {
              Actual: 0,
              Formatted: "",
            },
            Target: {
              Actual: 0,
              Formatted: "",
            },
            Remaining: {
              Actual: 0,
              Formatted: "",
            },
          },
          Volume: {
            Current: {
              Actual: 0,
              Formatted: "",
            },
            Target: {
              Actual: 0,
              Formatted: "",
            },
            Remaining: {
              Actual: 0,
              Formatted: "",
            },
          },
        },
      ],
      PPERubyRequirements: [],
      TotalRequirements: 0,
      RequirementsMet: 0,
      RequirementMetStatuses: [],
    },
    MaxBonusPercent: "",
    ShowDateRangeError: false,
    HideRequirements: false,
    CallResults: [],
    Error: null,
  };
};
export const FetchPPEOverviewData = (period: string) => {
  const { user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetPPEOverviewAPIQuery({
    abo: user.abo,
    aff: user.aff,
    period: period,
    isoCountryCode: user.isoCountryCode,
    isoCurrencyCode: shellConfig.isoCurrencyCode,
    locale: storedLocale(),
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [ppeOverviewData, setPPEOverviewData] = useState<PPEOverviewResponse>(defaultPPEOverviewResponse());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (!isFetching && !isError && payloadData) {
      setPPEOverviewData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error);
      setErrorStatus(foundLambdaError);
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, ppeOverviewData, errorStatus, refetch };
};
