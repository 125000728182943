import { useTranslation } from "react-i18next";
import LeafDateTime from "../../../../../common/leaf/LeafDateTime/LeafDateTime";
import LeafABOName from "../../../../../common/leaf/LeafProfile/LeafABOName/LeafABOName";
import LeafCustomerTags from "../../../../../common/leaf/LeafProfile/LeafCustomerTags/LeafCustomerTags";
import LeafDecodeStoredCountryCode from "../../../../../common/leaf/LeafDecodeStoredCountryCode/LeafDecodeStoredCountryCode";
import { useAppSelector } from "../../../../../store";
import defaultContent from "../../../../../content/customerProfileHeader";
import "./CustomerProfileHeader.scss";
import { useGetProfileQuery } from "../../../../../services/Profile/profileAPI";
import LeafProfileLoader from "../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import { LeafErrorMessage } from "../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import { profileHeaderErrorTrack } from "../../../../../common/tealium/CustomerProfile/Profile/ProfileHeaderTrack";
import { isProgramActive } from "../../../../../common/util/checkProgramActiveStatus";
import { AWARD_CODES } from "../../../../../common/enums/award-codes";

const CustomerProfileHeader = () => {
  const { t } = useTranslation(["customerProfileHeader"]);

  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const { selectedPeriod } = useAppSelector(({ period }) => period);
  const boot = useAppSelector(({ boot }) => boot);

  const showEntryDate = boot?.configuration?.profile?.customerInfo?.showEntryDate;
  const showVerifiedTag = boot?.configuration?.globalCML?.customerList?.showVerifiedTag;
  const eligibleIncentives = useAppSelector(({ boot }) => boot?.shellConfig?.eligibleIncentives);

  const { isFetching, isError, data } = useGetProfileQuery({ period: selectedPeriod, affAbo });

  if (isFetching === false && data?.aboInfo) {
    const {
      aboNo,
      name,
      localName,
      privacyFlag,
      verifiedCustomerFlag,
      entryDate,
      countryCode,
      isNew,
      isNewMigrated,
      businessNatureCode,
      isoLanguageCode,
      isNewInCurrentMonth,
      registrationChannelCd,
    } = data.aboInfo;

    return (
      <>
        <div className="customer-profile-header__abo-tag">
          {privacyFlag === false && (
            <LeafCustomerTags
              vcsFlag={verifiedCustomerFlag}
              isNewCustomer={isNew}
              isNewMigrated={isNewMigrated}
              businessNatureCode={businessNatureCode}
              isNewInCurrentMonth={isNewInCurrentMonth}
              selectedPeriod={selectedPeriod}
              showVerifiedTag={isProgramActive(eligibleIncentives, AWARD_CODES.CSI, showVerifiedTag)}
              registrationChannelCd={registrationChannelCd}
              aboNo={`profile_${aboNo}`}
            />
          )}
        </div>
        <div className="customer-profile-header__abo-name">
          <LeafABOName
            name={name}
            localName={localName}
            privacyFlag={privacyFlag}
            languageCode={isoLanguageCode}
            fontSize="large"
            fontWeight="bold"
          />
          <div className="customer-profile-header__abo-number-and-date">
            <span className="customer-profile-header__abo-number">{aboNo}</span>
            {privacyFlag === false && showEntryDate === true && (
              <p>
                - {t("registered", defaultContent["registered"])}
                <span className="customer-profile-header__registration-date">
                  <LeafDateTime date={entryDate} options={{ month: "long", day: "numeric", year: "numeric" }} />
                </span>
              </p>
            )}
          </div>
          <div className="customer-profile-header__abo-address">
            <LeafDecodeStoredCountryCode countryCode={countryCode} />
          </div>
        </div>
      </>
    );
  }

  if (isError === true) {
    profileHeaderErrorTrack();
  }

  return (
    <>
      <LeafErrorMessage show={isError} />
      <LeafProfileLoader row={1} isFetching={isFetching} />
    </>
  );
};

export default CustomerProfileHeader;
