import { MutableRefObject, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import TEALIUM from "../../../../../common/enums/tealium-enums";
import defaultContent from "../../../../../content/annualPGG";
import { FggOverviewResponse } from "../../../../../services/GroupGrowth/FrontlineGroupGrowth/frontlineGroupGrowthAPI.types";
import { IconPlainTable } from "../../../../../common/icons/IconPlainTable/IconPlainTable";
import "../MultiplierDetails.scss";
import { MultiplierDetail } from "../../../../../common/components/MultiplierDetail/MultiplierDetail";
import { setModalBtnFocus } from "../MultiplierDetailsUtil";
import { MatrixFQModal } from "../../../../../common/components/MatrixFQModal/MatrixFQModal";
import { getPerformanceYearShort } from "../../../../../common/util/period";

type Props = {
  overviewData: FggOverviewResponse; // Shouldn't this be PGG overview response?
  currentPerformanceYear: string;
  prevOpenRef: MutableRefObject<boolean>;
};

export const MatrixFQ = ({ overviewData, currentPerformanceYear, prevOpenRef }: Props) => {
  const { t } = useTranslation(["annualPGG"]);
  const viewMatrixButton = useRef<HTMLButtonElement>(null);
  const [showMatrixModal, setShowMatrixModal] = useState(false);
  const { MultiplierMatrix, IncentiveData } = overviewData;
  const { PotentialMultiplier, TrackingQ } = IncentiveData;
  const pyShort = getPerformanceYearShort(currentPerformanceYear);

  const multiplierMatrixModalToggle = () => {
    setShowMatrixModal(!showMatrixModal);
    setModalBtnFocus(viewMatrixButton, prevOpenRef, showMatrixModal);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.PERSONAL_GROUP_GROWTH,
      `incentive_multiplier_matrix_modal_${!showMatrixModal ? "open" : "close"}`,
      "multiplier_matrix_modal",
    );
  };

  return (
    <>
      <MultiplierDetail
        title={t("multiplierMatrix.title", defaultContent.multiplierMatrix.title, {
          fqs: overviewData.IncentiveData.TrackingQ,
        })}
        subtitle={`${t("multiplierMatrix.potentialMultiplier", defaultContent.multiplierMatrix.potentialMultiplier, {
          multiplier: overviewData.IncentiveData.PotentialMultiplier,
        })}`}
        buttonInfo={{
          callback: multiplierMatrixModalToggle,
          ref: viewMatrixButton,
          text: `${t("multiplierDetails.viewMatrix", defaultContent.multiplierDetails.viewMatrix)}`,
          icon: <IconPlainTable />,
        }}
      />
      <MatrixFQModal
        displayModal={showMatrixModal}
        toggleModal={multiplierMatrixModalToggle}
        incentive="annualPGG"
        matrixFQData={{
          matrixFQs: TrackingQ,
          potentialMultiplier: PotentialMultiplier,
          performanceYear: pyShort,
          multiplierMatrixData: MultiplierMatrix,
        }}
      />
    </>
  );
};
