import { useTranslation, Trans } from "react-i18next";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import LeafTag from "../../../../common/leaf/LeafTag/LeafTag";
import defaultContent from "../../../../content/legRequirementTile";
import { LegRequirement } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";
import "./LegRequirementTile.scss";
import { getLegByLegNumber } from "../pathToBronzeUtils";
import { CreateLegIconGroup } from "./LegIconGroup";

type Props = {
  isLoading: boolean;
  legRequirements: LegRequirement[];
  bonusCurrency: string;
  country: string;
  volumeNeeded: string;
  volumeType: string;
  newLegTenureMonths: number;
};

export default function LegRequirementTile({
  bonusCurrency,
  country,
  legRequirements,
  isLoading,
  volumeNeeded,
  volumeType,
  newLegTenureMonths,
}: Props) {
  const { t } = useTranslation(["legRequirementTile", "common"]);
  const firstLegReq = getLegByLegNumber(legRequirements, 1);
  const hasThreeLegReqs = legRequirements.length === 3; // JP

  const tagMetKey = firstLegReq.met ? "tagMetRequirement" : "";
  const tagLegsNeededKey = firstLegReq.deficit > 1 ? "tagPlural" : "tagSingular";

  const legReqTitle = t("title", defaultContent["title"], {
    firstLegTaget: hasThreeLegReqs ? null : firstLegReq.target,
    volumeNeeded: volumeNeeded,
    volumeType: volumeType,
  });

  return (
    <div id="leafReqTile">
      <LeafTile
        title={legReqTitle}
        isLoading={isLoading}
        showMetStatus={true}
        met={firstLegReq.met}
        circleCheckmarkId={"legReqCheckmarkCircle"}
      >
        <section hidden={hasThreeLegReqs}>{`${t(tagMetKey, defaultContent[tagMetKey])}`}</section>
        <section>
          <LeafTag hideTag={tagMetKey !== "" || hasThreeLegReqs} variant={"orange"}>
            <Trans>
              {t(tagLegsNeededKey, defaultContent[tagLegsNeededKey], { legsNeeded: firstLegReq.deficit })}
              <span className="leg-req-tile__bold"></span>
            </Trans>
          </LeafTag>
        </section>
        <CreateLegIconGroup
          legRequirements={legRequirements}
          bonusCurrency={bonusCurrency}
          country={country}
          hasThreeLegReqs={hasThreeLegReqs}
        />
        <section className="leg-req-tile__desc-container">
          <Trans>
            {t("descriptionOne", defaultContent["descriptionOne"], { numMonths: newLegTenureMonths })}
            <span className="leg-req-tile__bold--purple"></span>
          </Trans>
          <div className="leg-req-tile__desc-two">
            <span>{`${t("descriptionTwo", defaultContent["descriptionTwo"])}`}</span>
            <span className="leg-req-tile__bold--purple">{`${t("newLegs", defaultContent["newLegs"])}`}</span>
            <span>
              {t("gpvNeeded", defaultContent["gpvNeeded"], {
                gpvNeeded: volumeNeeded,
                volumeType: volumeType,
              })}
            </span>
          </div>
        </section>
      </LeafTile>
    </div>
  );
}
