import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "..";
import { PPEOverviewResponse } from "./performancePlusEliteAPI.types";

type PPERequestParams = {
  abo: string;
  aff: string;
  locale: string;
  period: string;
  isoCountryCode: string;
  isoCurrencyCode: string;
};

export const performancePlusEliteAPI = createApi({
  reducerPath: "performancePlusEliteAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getPPEOverviewAPI: builder.query<PPEOverviewResponse, PPERequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/ppe/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const { useGetPPEOverviewAPIQuery } = performancePlusEliteAPI;
