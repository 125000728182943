import { useTranslation } from "react-i18next";
import { AboTileData, BgtMiniProfileData } from "../../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import defaultContent from "../../../../content/bronzeGroupTracking";
import "./BronzeProfileTab.scss";
import { BGTTags } from "../../bronzeGroupTrackingUtils";
import { storedLocale } from "../../../impersonation/util";
import { LeafCheckmarksFull } from "../../../../common/leaf/LeafCheckmarks/LeafCheckmarksFull/LeafCheckmarksFull";
import { BRONZE_REQ_ORDER } from "../../../../common/constants/requirements-constants";
import { LeafEligibility } from "../../../../common/leaf/LeafEligibility/LeafEligibility";
import { BRONZE_TAGS } from "../../../../reducers/model/bgtFilters";

export const BronzeProfileTab = ({
  tabData,
  eligibilityData,
  eligibilityIsLoading,
}: {
  tabData: AboTileData;
  eligibilityData: BgtMiniProfileData;
  eligibilityIsLoading: boolean;
}) => {
  const { t } = useTranslation(["bronzeGroupTracking"]);
  const {
    closeNewTag = "",
    reqTrackingTag = "",
    eligibilityTag = "",
    restarted = false,
    requirements = [],
    graduated,
  } = tabData || {};
  const reqsMet = requirements.filter((req) => req.met).length;
  const isPreWindow = eligibilityTag === BRONZE_TAGS.PRE_WINDOW;

  const locale = storedLocale();

  const BGTLeafTags = () => {
    return (
      <>
        {[closeNewTag, reqTrackingTag, eligibilityTag].map((tag) => BGTTags(tag))}
        {BGTTags("", restarted)}
      </>
    );
  };

  return (
    <div className="bronzeProfileTab">
      <section className={`${graduated ? `bronzeProfileTab--hidden` : "bronzeProfileTab__tags"}`}>
        <BGTLeafTags />
      </section>
      <section
        className={`${graduated ? `bronzeProfileTab--hidden` : ""}`}
        hidden={graduated}
        data-testid="bronzeProfileTab__requirements"
      >
        <h2 className="bronzeProfileTab__sectionTitle">{`${t("requirements", defaultContent["requirements"])}`}</h2>
        <div className="bronzeProfileTab__sectionDescription">{`${t(
          "requirementsMetRange",
          defaultContent["requirementsMetRange"],
          { reqsMet, reqsRequired: requirements.length },
        )}`}</div>
        <LeafCheckmarksFull requirements={requirements} displayOrder={BRONZE_REQ_ORDER} />
      </section>
      <section data-testid="bronzeProfileTab__eligibility">
        <LeafEligibility
          data={eligibilityData}
          isLoading={eligibilityIsLoading}
          isPrewindow={isPreWindow}
          locale={locale}
        />
      </section>
    </div>
  );
};
