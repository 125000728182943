import { accAPI } from "..";

const urlBase = "shellLambda";

type LatestActivePeriodParams = {
  affAbo: string;
  awardCodes: number[];
  annualPeriods: number[];
  latestFiscalYear: number;
};

interface IncentiveStatus {
  isoCountry: string;
  awardNumber: number;
  bonusPeriod: number;
  effectivePeriod: number | null;
  firstEffectivePeriod: number | null;
  programActive: boolean;
  ineligibleBusinessNatures: string[];
  comingSoon: boolean;
}

interface LatestActivePeriodIncentive {
  latestActiveAnnualPeriod: number;
  incentiveStatus: IncentiveStatus[];
}

interface LatestActivePeriodResponse {
  [key: number]: LatestActivePeriodIncentive;
}

export const latestActivePeriodAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getLastestActivePeriod: build.query<LatestActivePeriodResponse, LatestActivePeriodParams>({
      query: (params) => {
        const { affAbo, awardCodes, annualPeriods, latestFiscalYear } = params;
        const queryParams = new URLSearchParams({
          awardCodes: awardCodes.join(","),
          periods: annualPeriods.join(","),
          latestFiscalYear: latestFiscalYear.toString(),
        });
        return `/latestActivePeriod/${affAbo}?${queryParams}`;
      },
      keepUnusedDataFor: 3600,
      extraOptions: { urlBase },
    }),
  }),
});

export const { useGetLastestActivePeriodQuery } = latestActivePeriodAPI;
