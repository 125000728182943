import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import TEALIUM from "../../../common/enums/tealium-enums";
import useWindowSize from "../../../common/hooks/useWindowSize";
import { LeafCollapsibleSection } from "../../../common/leaf/LeafCollapsibleSection/LeafCollapsibleSection";
import defaultContent from "../../../content/frontlineGrowth";
import { FggOverviewResponse } from "../../../services/GroupGrowth/FrontlineGroupGrowth/frontlineGroupGrowthAPI.types";
import LeafTileDivider from "../../../common/leaf/LeafTileDivider/LeafTileDivider";
import "./MultiplierDetails.scss";
import { useAppSelector } from "../../../store";
import { getPerformanceYear } from "../../../common/util/period";
import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { MatrixFQ } from "./MatrixFQ/MatrixFQ";
import { CurrentFQ } from "./CurrentFQ/CurrentFQ";
import { BaselineFQ } from "./BaselineFQ/BaselineFQ";
import { PreviousFQ } from "./PreviousFQ/PreviousFQ";

export interface MultiplierDetailsModal {
  displayModal: boolean;
  isLoading: boolean;
  errorStatus: boolean;
  refetch: Function;
  toggleModal: Function;
}

type Props = {
  fggOverviewData: FggOverviewResponse;
  selectedPeriod: string;
  isLoading: boolean;
  onExpand: Function;
};

const displayBaselineCheck = (
  averageBaselineStartingPY: number | undefined,
  currentPerformanceYear: string,
): boolean => {
  if (!averageBaselineStartingPY) return false;
  return averageBaselineStartingPY <= Number(currentPerformanceYear);
};

export const MultiplierDetails = ({ fggOverviewData, selectedPeriod, isLoading, onExpand }: Props) => {
  const { t } = useTranslation(["frontlineGrowth"]);
  const { configuration } = useAppSelector((state) => state.boot);
  const currentPerformanceYear = getPerformanceYear(selectedPeriod);
  const { width } = useWindowSize();
  const prevOpenRef = useRef(false);
  const isMobile = width && width <= 600;
  const [isExpanded, setIsExpanded] = useState(!width || width > 600);
  const displayBaseline = displayBaselineCheck(configuration.fgg.averageBaselineStartingPY, currentPerformanceYear);
  const { IncentiveData } = fggOverviewData;

  useEffect(() => {
    setIsExpanded(!isMobile);
    onExpand(!isMobile);
  }, [isMobile, onExpand]);

  const renderCenterModal = () => {
    if (displayBaseline) {
      return (
        <BaselineFQ
          selectedPeriod={selectedPeriod}
          baselineFQs={IncentiveData.PreviousPYFQ}
          prevOpenRef={prevOpenRef}
        />
      );
    }
    return (
      <PreviousFQ selectedPeriod={selectedPeriod} previousFQs={IncentiveData.PreviousPYFQ} prevOpenRef={prevOpenRef} />
    );
  };

  const tealiumClickActionToggleMultiplierDetails = (isExpanded: boolean) => {
    const newIsExpanded = !isExpanded;
    setIsExpanded(newIsExpanded);
    onExpand(newIsExpanded);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.FRONTLINE_GROUP_GROWTH,
      `incentive_multiplier_details_${!isExpanded ? "open" : "close"}`,
      "incentive_multiplier_details",
    );
  };

  return (
    <LeafCollapsibleSection
      onClickFn={tealiumClickActionToggleMultiplierDetails}
      isExpanded={isExpanded}
      titleExpand={`${t("multiplierDetails.titleExpand", String(defaultContent.multiplierDetails.titleExpand))}`}
      titleCollapse={`${t("multiplierDetails.titleCollapse", String(defaultContent.multiplierDetails.titleCollapse))}`}
    >
      <div className="MultiplierDetails">
        <LeafRectangleLoader isLoading={isLoading} height="15rem" width="100%">
          <MatrixFQ
            fggOverviewData={fggOverviewData}
            currentPerformanceYear={currentPerformanceYear}
            prevOpenRef={prevOpenRef}
          />
          <LeafTileDivider />
          {renderCenterModal()}
          <LeafTileDivider />
          <CurrentFQ
            selectedPeriod={selectedPeriod}
            currentFQs={IncentiveData.CurrentYTDFQ}
            prevOpenRef={prevOpenRef}
          />
        </LeafRectangleLoader>
      </div>
    </LeafCollapsibleSection>
  );
};
