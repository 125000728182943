import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Markets } from "@amwaycommon/acc-es-data/model";
import { fetchConfig } from "../../common/util/config";
import { SetBootForCurrentBusiness, SetLoginFields } from "../../reducers/model/boot";
import { parseOauth, urlParamsToObj } from "../../common/util/parseOauth";
import { BootUser } from "../../reducers/boot";
import { store } from "../../store";
import { LoginFields } from "../../reducers/loginLocalStorage";
import { useLazyGetShellConfigQuery } from "../../services/ShellConfig/getShellConfigAPI";
import { useLazyGetBusinessSelectorQuery } from "../../services/BusinessSelector/businessSelectorAPI";
import {
  checkForAuthError,
  checkWebsiteUnavailable,
  setHomePage,
  logRedactedHash,
  setlocalNSessionStorage,
  getLoginFields,
  getApigeeAccessToken,
} from "./token";

export const MasheryImpersonation = () => {
  const [progress, setProgress] = useState("0%");
  const navigate = useNavigate();
  const [getShellConfig] = useLazyGetShellConfigQuery();
  const [getBusinessSelector] = useLazyGetBusinessSelectorQuery();

  useEffect(() => {
    (async () => {
      setProgress("20%");
      const defaultConfig = await fetchConfig();
      const localStorageFields: LoginFields = getLoginFields();
      SetLoginFields({ fields: localStorageFields });
      logRedactedHash();
      setProgress("40%");
      checkWebsiteUnavailable(defaultConfig, navigate);
      checkForAuthError(navigate);
      setlocalNSessionStorage(defaultConfig, localStorageFields, navigate);
      // Temporarily store this in localStorage so our utility functions will use it.
      // This will be overwitten by downstream code.

      let urlParams = new URLSearchParams(window.location.hash.substring(1));

      if (!Array.from(urlParams).length) {
        urlParams = new URLSearchParams(window.location.search);
      }

      let convertedUrlParams = urlParamsToObj(urlParams);

      //check for code only - securelink auth flow
      if (convertedUrlParams.code) {
        //call token endpoint

        const responseBody = await getApigeeAccessToken(defaultConfig, convertedUrlParams.code);
        convertedUrlParams = { ...convertedUrlParams, ...responseBody };
      }

      //change parse scope
      const parseResult = parseOauth(convertedUrlParams);
      const { scope, data } = parseResult ? parseResult : { scope: {}, data: {} };
      const { boot } = store.getState();

      const user: BootUser = {
        token_type: data.token_type,
        access_token: data.access_token,
        expires_in: data.expires_in,
        abo: scope.abo,
        aff: scope.aff,
        partyId: scope.partyId,
        globalPartyId: scope.partyId,
        idToken: "",
        isoCountryCode: boot.user.isoCountryCode || new Markets().getByCtry(scope.aff!).iso,
        cacheDateInMS: new Date().getTime(),
        cognitoToken: data.cognitoToken,
      };

      SetLoginFields({
        fields: { ssoOrigin: store.getState().loginLocalStorage.loginFields.ssoOrigin || user.isoCountryCode },
      });
      await SetBootForCurrentBusiness({ user });
      setProgress("60%");
      await setHomePage(navigate, getShellConfig, getBusinessSelector);
    })();
  }, [getShellConfig, navigate]);

  return (
    <div className="JwtImpersonation__progress">
      <div className="JwtImpersonation__bar" style={{ width: progress }}></div>
    </div>
  );
};
