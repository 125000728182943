import "./IconMobilePhone.scss";
import { ReactComponent as MobilePhone } from "./svg/mobile-phone.svg";

type Props = {
  fill?: "white" | "blue" | "green";
};

export const IconMobilePhone = ({ fill }: Props) => {
  return (
    <span className={`IconMobilePhone IconMobilePhone__${fill}`}>
      <MobilePhone />
    </span>
  );
};
