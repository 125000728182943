/* eslint-disable import/order */
import { useTranslation } from "react-i18next";
import { BannerTile } from "../../../common/components/BannerTile/BannerTile";
import defaultContent from "../../../content/qcGrowth";
import { getBannerTitleKey, getBannerSubtitleKey } from "../../../common/util/groupGrowthUtils";
import { MonthlyPGGIncomeData } from "../../../services/GroupGrowth/MonthlyPersonalGroupGrowth/monthlyPGGAPI.types";
import { useAppSelector } from "../../../store";
import { getPerformanceYearShort } from "../../../common/util/period";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import "./QCGBannerTile.scss";

type Props = {
  incomeData: MonthlyPGGIncomeData;
  isLoading: boolean;
};

export default function QCGBannerTile({ incomeData, isLoading }: Props) {
  const { t } = useTranslation(["qcGrowth"]);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { configuration } = useAppSelector((store) => store.boot);

  // Will need something similar to this when a learn more link is added
  // configuration.dashboardLinks?.learnMore?.qcGrowth ?? "";
  const learnMoreLink = "www.google.com";

  const performanceYear = getPerformanceYearShort(selectedPeriod);

  const titleKey = getBannerTitleKey(incomeData.Status);
  const descriptionKey = getBannerSubtitleKey(incomeData.Status);

  // Temporary until tealium tagging is done
  const learnMoreLinkTealiumFunction = () => {};

  const bannerStyle = () => {
    return incomeData.Status === QUALIFICATION_STATUS.HELD || incomeData.Status === QUALIFICATION_STATUS.DENIED
      ? "QCGBannerTile_not-qualified-banners-only"
      : "";
  };

  return (
    <div className={`QCGBannerTile ${bannerStyle()}`}>
      <BannerTile
        isLoading={isLoading}
        title={t(`bannerTile.${titleKey}`, defaultContent.bannerTile[titleKey], { performanceYear })}
        description={t(`bannerTile.${descriptionKey}`, defaultContent.bannerTile[descriptionKey]).toString()}
        bonusAmountLabel={t("bannerTile.potentialEarnings", defaultContent.bannerTile.potentialEarnings, {
          performanceYear,
        })}
        shouldDisplayAmount={![QUALIFICATION_STATUS.HELD, QUALIFICATION_STATUS.DENIED].includes(incomeData.Status)}
        bonusAmount={incomeData.PotentialPYEarnings}
        learnMoreLink={learnMoreLink}
        learnMoreTealiumFunction={learnMoreLinkTealiumFunction}
      />
    </div>
  );
}
