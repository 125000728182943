import {
  updateCustomerExportCount,
  updateNotifyReqId,
  updateOrderExportCount,
  updateRequestingCustomerExport,
  updateRequestingOrderExport,
} from "../../reducers/customer/customerExport";
import { MODULE_CUSTOMER, MODULE_ORDER } from "../../common/constants/export-constants";
import { accAPI } from "..";
import {
  DeleteExportParams,
  DeleteExportResponse,
  DownloadExportParams,
  ExportCustomerListParams,
  ExportCustomerListResponse,
  ExportNotificationListParams,
  ExportNotificationListResponse,
  ExportOrderHistoryParams,
  ExportOrderHistoryResponse,
  ExportStatusParams,
  ExportStatusResponse,
} from "./exportAPI.types";

const TAG_EXPORT_STATUS = "ExportStatus";
const TAG_EXPORT_NOTIFY = "ExportNotify";
const urlBase = "export";

export const exportAPI = accAPI
  .enhanceEndpoints({
    addTagTypes: [TAG_EXPORT_STATUS, TAG_EXPORT_NOTIFY],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getExportDownload: build.query<string, DownloadExportParams>({
        query: (params) => {
          const { exportId, fileName, fileFormat } = params;
          const queryParams = new URLSearchParams({
            fileName,
            fileFormat,
          });
          return {
            url: `/${exportId}/download?${queryParams}`,
            headers: { accept: "text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
            responseHandler: async (response) => window.URL.createObjectURL(await response.blob()),
            cache: "no-cache",
          };
        },
        keepUnusedDataFor: 0,
        extraOptions: { urlBase },
      }),
      deleteExport: build.mutation<DeleteExportResponse, DeleteExportParams>({
        query: ({ exportId }) => ({
          url: `/${exportId}`,
          method: "DELETE",
        }),
        extraOptions: {
          urlBase,
        },
        async onQueryStarted(args, { dispatch }) {
          try {
            const { moduleId } = args;
            const notifyState = { exports: [], exportCount: 0, visibleExportRequestCount: 0 };

            switch (moduleId) {
              case MODULE_CUSTOMER:
                dispatch(exportAPI.util.upsertQueryData("getCustomerExportNotification", { moduleId }, notifyState));
                break;
              case MODULE_ORDER:
                dispatch(exportAPI.util.upsertQueryData("getOrderExportNotification", { moduleId }, notifyState));
                break;
            }
          } catch {
            console.error("deleteExport: failed to reset cache");
          }
        },
      }),
      exportCustomerList: build.mutation<ExportCustomerListResponse, ExportCustomerListParams>({
        query: (body) => ({
          url: "/fullmap/customers",
          method: "POST",
          body,
        }),
        extraOptions: {
          urlBase,
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            // flag the customer export is requesting
            dispatch(updateRequestingCustomerExport(true));
            const { data } = await queryFulfilled;
            // Manually update the export status cache, to transition Download view into in-progress status
            dispatch(
              exportAPI.util.upsertQueryData("getExportStatus", { moduleId: MODULE_CUSTOMER }, { exports: [data] }),
            );
            // Manually update notification cache, to allow transition from Export view to Download view
            dispatch(
              exportAPI.util.upsertQueryData(
                "getCustomerExportNotification",
                { moduleId: MODULE_CUSTOMER },
                { exports: [], exportCount: 0, visibleExportRequestCount: 1 },
              ),
            );
            // flag the customer export is completed the request
            dispatch(updateRequestingCustomerExport(false));
          } catch {
            dispatch(updateRequestingCustomerExport(false));
            console.error("exportCustomerList: failed to reset cache");
          }
        },
      }),
      exportOrderHistory: build.mutation<ExportOrderHistoryResponse, ExportOrderHistoryParams>({
        query: (body) => ({
          url: "/fullmap/customers/orders",
          method: "POST",
          body,
        }),
        extraOptions: {
          urlBase,
        },
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          try {
            // flag the order export is requesting
            dispatch(updateRequestingOrderExport(true));
            const { data } = await queryFulfilled;
            // Manually update the export status cache, to transition Download view into in-progress status
            dispatch(
              exportAPI.util.upsertQueryData("getExportStatus", { moduleId: MODULE_ORDER }, { exports: [data] }),
            );
            // Manually update notification cache, to allow transition from Export view to Download view
            dispatch(
              exportAPI.util.upsertQueryData(
                "getOrderExportNotification",
                { moduleId: MODULE_ORDER },
                { exports: [], exportCount: 0, visibleExportRequestCount: 1 },
              ),
            );
            // flag the order export is completed the request
            dispatch(updateRequestingOrderExport(false));
          } catch {
            dispatch(updateRequestingOrderExport(false));
            console.error("exportCustomerList: failed to reset cache");
          }
        },
      }),
      getExportStatus: build.query<ExportStatusResponse, ExportStatusParams>({
        query: (params) => {
          const { moduleId } = params;
          const queryParams = new URLSearchParams({
            moduleId,
          });
          return `/?${queryParams}`;
        },
        providesTags: (_result, _error, arg) => {
          const { moduleId } = arg;
          return [{ type: TAG_EXPORT_STATUS, id: moduleId }];
        },
        keepUnusedDataFor: 29,
        extraOptions: {
          urlBase,
        },
      }),
      getOrderExportNotification: build.query<ExportNotificationListResponse, ExportNotificationListParams>({
        query: () => {
          const queryParams = new URLSearchParams({
            moduleId: MODULE_ORDER,
          });
          return `/notify?${queryParams}`;
        },
        providesTags: [{ type: TAG_EXPORT_NOTIFY, id: MODULE_ORDER }],
        keepUnusedDataFor: 29,
        extraOptions: { urlBase },
        async onQueryStarted(_args, { dispatch, queryFulfilled, requestId }) {
          try {
            dispatch(updateNotifyReqId({ orderNotifyReqId: requestId }));
            const { data } = await queryFulfilled;
            dispatch(updateOrderExportCount({ orderNotifyReqId: requestId, data }));
            // invalidate export status cache to re-fetch the API
            dispatch(exportAPI.util.invalidateTags([{ type: TAG_EXPORT_STATUS, id: MODULE_ORDER }]));
          } catch (error) {
            console.warn("export order notify error", error);
          }
        },
      }),
      getCustomerExportNotification: build.query<ExportNotificationListResponse, ExportNotificationListParams>({
        query: () => {
          const queryParams = new URLSearchParams({
            moduleId: MODULE_CUSTOMER,
          });
          return `/notify?${queryParams}`;
        },
        providesTags: [{ type: TAG_EXPORT_NOTIFY, id: MODULE_CUSTOMER }],
        keepUnusedDataFor: 29,
        extraOptions: { urlBase },
        async onQueryStarted(_args, { dispatch, queryFulfilled, requestId }) {
          try {
            dispatch(updateNotifyReqId({ customerNotifyReqId: requestId }));
            const { data } = await queryFulfilled;
            dispatch(updateCustomerExportCount({ customerNotifyReqId: requestId, data }));
            // invalidate export status cache to re-fetch the API
            dispatch(exportAPI.util.invalidateTags([{ type: TAG_EXPORT_STATUS, id: MODULE_CUSTOMER }]));
          } catch (error) {
            console.warn("export customer notify error", error);
          }
        },
      }),
    }),
  });

export const {
  useGetExportStatusQuery,
  useLazyGetExportDownloadQuery,
  useDeleteExportMutation,
  useExportCustomerListMutation,
  useExportOrderHistoryMutation,
  useLazyGetExportStatusQuery,
  useGetCustomerExportNotificationQuery,
  useGetOrderExportNotificationQuery,
} = exportAPI;
