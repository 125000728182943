import { useGetTotalABOQuery } from "../../../../../../../../../services/PerformanceHistory/performanceHistoryAPI";
import { PerfHistoryDataProps } from "../ABOProfilePerfHistoryKPIs";

const ABOProfilePerfHistoryTotalABO = ({
  affAbo,
  locale,
  isoCountryCode,
  period,
  periodCount = 12,
  children,
}: PerfHistoryDataProps) => {
  const { isFetching, data, isError } = useGetTotalABOQuery({
    affAbo,
    period,
    periodCount,
    locale,
    market: isoCountryCode,
  });

  return <>{children({ data, isFetching, isError })}</>;
};

export default ABOProfilePerfHistoryTotalABO;
