import LeafApplicantItem from "../LeafApplicantItem/LeafApplicantItem";
import { ABOInfo, PartyInfo, ProfileInfo } from "../../../../../services/Profile/profileAPI.types";
import "./LeafApplicantsList.scss";

type Props = {
  aboInfo: ABOInfo;
  partyInfo: PartyInfo[];
  applicantTrackOnclick: Function;
  profileInfo: Partial<ProfileInfo>;
};

const LeafApplicantsList = (props: Props) => {
  const { aboInfo, partyInfo, applicantTrackOnclick, profileInfo } = props;

  return (
    <div className="leaf-applicants-list">
      {partyInfo.map((info, index) => {
        return (
          <div className="leaf-applicants-list__border" key={index}>
            <LeafApplicantItem
              key={index}
              isExpandable={true}
              aboInfo={aboInfo}
              partyInfo={info}
              applicantTrackOnclick={applicantTrackOnclick}
              profileInfo={profileInfo}
            />
          </div>
        );
      })}
    </div>
  );
};
export default LeafApplicantsList;
