import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import defaultContent from "../../../content/leadershipSeminar";
import "./LSBanner.scss";
import { LeafLearnMoreLink } from "../../../common/leaf/LeafLearnMoreLink/LeafLearnMoreLink";
import { QUALIFICATION_STATUS } from "../../../common/enums/incentive-enums";
import { useAppSelector } from "../../../store";
import LeafRectangleLoader from "../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import TEALIUM from "../../../common/enums/tealium-enums";

type Props = {
  Status: QUALIFICATION_STATUS;
  isLoading: boolean;
  tripName: string;
};

const getBannerHeadline = (status: QUALIFICATION_STATUS) => {
  switch (status) {
    case QUALIFICATION_STATUS.AUTOMATICALLY_APPROVED:
      return "automaticallyApproved";
    case QUALIFICATION_STATUS.QUALIFIED:
    case QUALIFICATION_STATUS.ELIGIBLE:
      return "earned";
    case QUALIFICATION_STATUS.MANUALLY_APPROVED:
    case QUALIFICATION_STATUS.MANUALLY_QUALIFIED:
      return "automaticallyApproved";
    case QUALIFICATION_STATUS.TRACKING:
    case QUALIFICATION_STATUS.PENDING_ELIGIBLE:
      return "tracking";
    case QUALIFICATION_STATUS.NOT_QUALIFIED:
    case QUALIFICATION_STATUS.NOT_TRACKING:
      return "notTracking";
    case QUALIFICATION_STATUS.NOT_ELIGIBLE:
      return "notEligible";
    default:
      return ``;
  }
};

export const LSBanner = ({ Status, isLoading, tripName }: Props) => {
  const { t } = useTranslation(["leadershipSeminar"]);
  const { configuration } = useAppSelector((store) => store.boot);
  const learnMoreLink = configuration.dashboardLinks?.learnMore?.leadershipSeminar ?? "";
  const bannerHeadline = getBannerHeadline(Status);

  const onClickLearnMoreLink = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      TEALIUM.SECTION.BANNER_CTA,
      TEALIUM.CATEGORY.LEADERSHIP_SEMINAR,
      "",
      "learn_more",
    );
  };

  if (isLoading) {
    return <LeafRectangleLoader isLoading={isLoading} height="25.5rem" width="auto" />;
  }

  return (
    <div className={`LSBanner ${bannerHeadline === "automaticallyApproved" ? "LSBanner--automaticallyApproved" : ""}`}>
      <div className="LSBanner__text-overlay">
        <h3 className="LSBanner__text-overlay__headline">
          {t(`bannerTile.${bannerHeadline}`, String(defaultContent.bannerTile[bannerHeadline]), {
            tripName: tripName,
          })}
        </h3>
        <p className="LSBanner__text-overlay__sub-headline">
          {t("bannerTile.description", String(defaultContent.bannerTile.description))}
        </p>
        {learnMoreLink && (
          <div className="LSBanner__learnMoreLink" data-testid="lsi-learn-more-link">
            <LeafLearnMoreLink href={learnMoreLink} onClickFn={onClickLearnMoreLink} />
          </div>
        )}
      </div>
    </div>
  );
};
