import { useTranslation } from "react-i18next";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import LeafTable, { TableRow } from "../../../../../common/leaf/LeafTable/LeafTable";
import defaultContent from "../../../../../content/frontlineGrowth";
import "./CurrentFQModal.scss";
import { DetailsFqLeg } from "../../../../../services/GroupGrowth/FrontlineGroupGrowth/frontlineGroupGrowthAPI.types";
import { MultiplierDetailsModal } from "../../MutliplierDetails";
import { renderLoadingOrError } from "../../MultiplierDetailsUtil";
import LeafTag from "../../../../../common/leaf/LeafTag/LeafTag";
import { IconBadge } from "../../../../../common/icons/IconBadge/IconBadge";

interface CurrentFQModalProps extends MultiplierDetailsModal {
  currentFQLegs: DetailsFqLeg[];
  currentFQs: string;
}

export const CurrentFQModal = ({
  currentFQLegs,
  currentFQs,
  toggleModal,
  displayModal,
  isLoading,
  errorStatus,
  refetch,
}: CurrentFQModalProps) => {
  const { t } = useTranslation(["frontlineGrowth"]);

  const rows = currentFQLegs.map((leg: DetailsFqLeg): TableRow => {
    const { name, aboNo, newPlatLegStatus, ytdQualCount, veTracking } = leg;
    const displayName = !name ? `${t("confidentialUser", String(defaultContent["confidentialUser"]))}` : name;

    const veTrackingTag = () => {
      if (veTracking && ytdQualCount === 10) {
        return (
          <LeafTag variant={"darkOrange"}>{`${t("veTrackingTag", String(defaultContent["veTrackingTag"]))}`}</LeafTag>
        );
      }
      return null;
    };

    return {
      row: [
        {
          value: (
            <span className="MultiplierDetails__cell-container">
              <p className="MultiplierDetails__cell-container--name">{displayName}</p>
              <div className="MultiplierDetails__cell-container--abo-container">
                <p className="MultiplierDetails__cell-container--abo">#{aboNo.toString()}</p>
                <span>{newPlatLegStatus && <IconBadge />}</span>
              </div>
            </span>
          ),
          columnAlign: "left",
        },
        {
          value: (
            <span className="MultiplierDetails__cell-container--count">
              {veTrackingTag()}
              <p>{ytdQualCount}</p>
            </span>
          ),
          columnAlign: "right",
        },
      ],
    };
  });

  const renderModalBody = () => {
    return (
      <div className="CurrentFQModal__body">
        <LeafTable
          header={[]}
          rows={rows}
          columnAlign="center"
          hideHeader={true}
          rowHeight="2.5rem"
          headerStyle={"default"}
        />
      </div>
    );
  };

  return (
    <div className="CurrentFQModal">
      <LeafModal
        open={displayModal}
        onCloseCallbackFn={toggleModal}
        title={`${t("multiplierDetails.currentYtd", defaultContent.multiplierDetails.currentYtd)}`}
        subtitle={`${t("currentFqs", defaultContent.currentFqs, { fqs: currentFQs })}`}
      >
        {!isLoading && !errorStatus ? (
          <>{renderModalBody()}</>
        ) : (
          <>{renderLoadingOrError(isLoading, errorStatus, refetch, "Current_FQ_Modal")}</>
        )}
      </LeafModal>
    </div>
  );
};
