import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/src/modules/tealium";
import { useEffect, useState } from "react";
import TEALIUM from "../../../../common/enums/tealium-enums";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import { LeafCollapsibleSection } from "../../../../common/leaf/LeafCollapsibleSection/LeafCollapsibleSection";
import defaultContent from "../../../../content/annualGcrPGG";
import { GCRIncentiveData } from "../../../../services/GroupGrowth/PersonalGroupGrowthGCR/personalGroupGrowthGCRAPI.types";
import LeafTileDivider from "../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import { getPerformanceYearShort } from "../../../../common/util/period";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { MultiplierDetail } from "../../../../common/components/MultiplierDetail/MultiplierDetail";
import "./GCRPGGMultiplierDetails.scss";

export interface MultiplierDetailsModal {
  displayModal: boolean;
  isLoading: boolean;
  errorStatus: boolean;
  refetch: Function;
  toggleModal: Function;
}

type Props = {
  data: GCRIncentiveData;
  selectedPeriod: string;
  isLoading: boolean;
  onExpand: Function;
};

export const GCRPGGMultiplierDetails = ({ data: incentiveData, selectedPeriod, isLoading, onExpand }: Props) => {
  const { t } = useTranslation(["annualGcrPGG"]);
  const currentPerformanceYear = getPerformanceYearShort(selectedPeriod);
  const { width } = useWindowSize();
  const isMobile = width && width <= 600;
  const [isExpanded, setIsExpanded] = useState(!width || width > 600);

  const tealiumClickActionToggleMultiplierDetails = (isExpanded: boolean) => {
    const newIsExpanded = !isExpanded;
    setIsExpanded(newIsExpanded);
    onExpand(newIsExpanded);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.PERSONAL_GROUP_GROWTH,
      `incentive_multiplier_details_${!isExpanded ? "open" : "close"}`,
      "incentive_multiplier_details",
    );
  };

  useEffect(() => {
    setIsExpanded(!isMobile);
    onExpand(!isMobile);
  }, [isMobile, onExpand]);

  return (
    <LeafCollapsibleSection
      onClickFn={tealiumClickActionToggleMultiplierDetails}
      isExpanded={isExpanded}
      titleExpand={`${t("incentiveCalculation.showDetails", String(defaultContent.incentiveCalculation.showDetails))}`}
      titleCollapse={`${t(
        "incentiveCalculation.hideDetails",
        String(defaultContent.incentiveCalculation.hideDetails),
      )}`}
    >
      <div className="MultiplierDetails">
        <LeafRectangleLoader isLoading={isLoading} height="15rem" width="100%">
          <MultiplierDetail
            title={t("incentiveCalculation.pqMonths", defaultContent.incentiveCalculation.pqMonths, {
              pqMonths: incentiveData.PQBase,
            })}
            subtitle={`${t("incentiveCalculation.pqBase", defaultContent.incentiveCalculation.pqBase)}`}
            buttonInfo={null}
          />
          <LeafTileDivider />
          <MultiplierDetail
            title={t("incentiveCalculation.pqMonths", defaultContent.incentiveCalculation.pqMonths, {
              pqMonths: incentiveData.CurrentYTDPQMonths,
            })}
            subtitle={`${t("incentiveCalculation.pqStatus", defaultContent.incentiveCalculation.pqStatus, {
              perfYear: currentPerformanceYear,
            })}`}
            buttonInfo={null}
          />
          <LeafTileDivider />
          <MultiplierDetail
            title={t("incentiveCalculation.trackingPQMonths", defaultContent.incentiveCalculation.trackingPQMonths, {
              trackingPQMonths: incentiveData.TrackingPQMonths,
            })}
            subtitle={`${t(
              "incentiveCalculation.trackingPQMonthsStatus",
              defaultContent.incentiveCalculation.trackingPQMonthsStatus,
              {
                perfYear: currentPerformanceYear,
              },
            )}`}
            buttonInfo={null}
          />
        </LeafRectangleLoader>
      </div>
    </LeafCollapsibleSection>
  );
};
