import { useTranslation } from "react-i18next";
import { formatAboPV } from "@amwaycommon/acc-mixins/src/modules/l10n";
import LeafTile from "../../../../../common/leaf/LeafTile/LeafTile";
import LeafTileDivider from "../../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import defaultContent from "../../../../../content/foundersPlatinumProgressTile";
import { QUALIFICATION_STATUS } from "../../../../../common/enums/incentive-enums";
import { BADGE_SIZE, IconShield } from "../../../../../common/icons/IconShield/IconShield";
import { useAppSelector } from "../../../../../store";
import { storedLocale } from "../../../../impersonation/util";
import { getBadgeType } from "../GpvRequirementTile/GpvRequirementTile";
import { VepvRequirement } from "../../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI.types";

type Props = {
  vepvRequirementData: VepvRequirement;
};

export const VepvRequirementTile = ({ vepvRequirementData }: Props) => {
  const {
    MonthsRequired,
    MonthsAchieved,
    MonthsNeeded,
    AnnualVepvRequired,
    AnnualVepvNeeded,
    AnnualVepvEarned,
    MonthsQualificationStatus,
    VepvQualificationStatus,
    RequirementQualificationStatus,
  } = vepvRequirementData;
  const { t } = useTranslation(["foundersPlatinumProgressTile"]);
  const locale = storedLocale();
  const { user } = useAppSelector((state) => state.boot);
  const market = user.isoCountryCode;
  const vepvQualStatusBadge = getBadgeType(VepvQualificationStatus);
  const monthsQualStatusBadge = getBadgeType(MonthsQualificationStatus);

  return (
    <LeafTile borderColor={RequirementQualificationStatus === QUALIFICATION_STATUS.QUALIFIED ? "#107F47" : undefined}>
      <div className="FppReqTile">
        <div className="FppReqTile__top-section">
          <div className="FppReqTile__title">{t("vepvTitle", defaultContent["vepvTitle"])}</div>
          <div className="FppReqTile__shield-section">
            <div className="FppReqTile__shield-text">
              <IconShield size={BADGE_SIZE.LARGE} type={vepvQualStatusBadge} />
              {t("tooltips.vepvMonths.vepvQual", defaultContent.tooltips.vepvMonths.vepvQual, {
                vePvSpMonths: formatAboPV(AnnualVepvRequired, locale, market),
              })}
            </div>
            <div className="FppReqTile__shield-text">
              <IconShield size={BADGE_SIZE.LARGE} type={monthsQualStatusBadge} />
              {t("tooltips.vepvMonths.spMonthsQual", defaultContent.tooltips.vepvMonths.spMonthsQual, {
                monthsRequired: MonthsRequired,
              })}
            </div>
          </div>
          <div className="FppReqTile__needed-section">
            {RequirementQualificationStatus === QUALIFICATION_STATUS.UN_AVAILABLE ||
            (VepvQualificationStatus === QUALIFICATION_STATUS.UN_AVAILABLE &&
              MonthsQualificationStatus === QUALIFICATION_STATUS.UN_AVAILABLE) ? (
              <div className="FppReqTile__not-available">
                {t("notAvailableLabel", defaultContent.notAvailableLabel)}
              </div>
            ) : (
              <>
                <div className={AnnualVepvNeeded <= 0 ? "FppReqTile__closed-text" : "FppReqTile__open-text"}>
                  {t("vepvNeededLabel", defaultContent.vepvNeededLabel, {
                    vePvSpMonths: formatAboPV(AnnualVepvNeeded, locale, market),
                  })}
                </div>
                <div className={MonthsNeeded <= 0 ? "FppReqTile__closed-text" : "FppReqTile__open-text"}>
                  {t("spMonthsNeededLabel", defaultContent.spMonthsNeededLabel, { spMonthsNeeded: MonthsNeeded })}
                </div>
              </>
            )}
          </div>
        </div>

        <LeafTileDivider />

        <div className="FppReqTile__bottom-section">
          <div className="FppReqTile__current-title">
            {t("currentlyHaveLabel", defaultContent["currentlyHaveLabel"]).toString() + ":"}
          </div>
          <div className="FppReqTile__current-requirements">
            {t("vePvSpMonthsLabel", defaultContent.vePvSpMonthsLabel, {
              currentVePv: formatAboPV(AnnualVepvEarned, locale, market),
              currentSpMonths: MonthsAchieved,
            })}
          </div>
        </div>
      </div>
    </LeafTile>
  );
};
