const content: { [key: string]: any } = {
  pageTitle: "Path to Bronze+",
  aboActivationLabel: "Path to Bronze Incentive+",
  monthlyReqHeader: "What are the monthly requirements?",
  bonusCalcHeader: "How is the bonus calculated?",
  eligibleModal: {
    title: "Eligibility",
    whoIsEligibleHeader: "Who is eligible for the Path to Bronze bonus?",
    whoIsEligibleDescription:
      "Eligibility is determined at the end of each performance year. The Path to Bronze Incentive+ is for ABOs below a 9% performance bonus level achievement in the previous performance year. The Amway sales plan offers other bonuses to reward ABOs beyond the 9% performance bonus.",
    notEarningHeader: "You’re eligible to start earning the Path to Bronze Incentive+",
    earningHeader: "You have earned <span>{x} out of 6 times</span> in PY{y}",
    metHeader: "Your Path to Bronze Incentive+ eligibility has been met",
    eligibleDescription: "You can receive a maximum of {max} payments within a performance year.",
    closedYearHeader: "Your Path to Bronze Incentive+ eligibility period has ended for PY{YY}.",
    extended: {
      earningHeader: "You have earned <span>{x} out of 6 times</span> in your 18 month window.",
      eligibleDescription: "You can receive a maximum of {max} payments within an 18 month window.",
    },
  },
  performanceBonusReq: {
    title: "{perfBonus}% Performance Bonus",
    tag: "Additional <strong>{neededGPV} {volumeType} needed</strong>",
    tagMet: "You've met this requirement.",
    progressSubtitle: "Target: {targetGPV}",
  },
  ppvReq: {
    title: "{targetPPV} {volumeType}",
    tag: "Additional <strong>{neededPPV} {volumeType} needed</strong>",
    tagMet: "You've met this requirement.",
    progressSubtitle: "Target: {targetPPV}",
  },
  eligibleTitle: "Check your eligibility",
  eligibileDescription: "You’re eligible to earn this incentive ",
  eligibileDescriptionRemaining: "{remainingTimes} more times ",
  eligibileDescriptionPeriod: "until {monthYYYY}.",
  ineligibleTitle: "You are ineligible for the Path to Bronze Incentive+ in {dateText}",
  ineligibleDescription: "If you require more information, please contact Customer Support for further details.",
  ineligibleForPYDescription:
    "The Path to Bronze Incentive+ is for ABOs below a 9% performance bonus level achievement in the previous performance year. The Amway sales plan offers other bonuses to reward ABOs beyond the 9% performance bonus.",
  viewEligibility: "View Eligibility",
  allReqsMet: "Great job! You’re earning the Path to Bronze Incentive+",
  earningIncentiveTitle: "Great job! Keep going to level up your incentive",
  notEarningIncentiveTitle: "You're not earning the Path to Bronze Incentive+",
  manuallyApprovedIncentiveTitle: "You've been manually approved for the Path to Bronze Incentive+",
  deniedIncentiveTitle: "{month} business is not eligible for the Path to Bronze Incentive+",
  onHoldIncentiveTitle: "Your Path to Bronze Incentive+ award is on hold",
  customerCareContactDescription: "For more information, please contact Amway Customer Care.",
  downlineGrowthDescription: "The Path to Bronze Incentive+ rewards new downline growth.",
  bonusAmountDescription: "You can earn up to <1>{amount}</1> with this bonus!",
  totalsAsOf: "Totals as of {timestamp}",
  learnMore: "Learn More",
};
export default content;
