import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../../icons/IconShield/IconShield";
import LeafTile from "../../../leaf/LeafTile/LeafTile";
import "./TwoBadgesRequirementTile.scss";
import { IconPlainTable } from "../../../icons/IconPlainTable/IconPlainTable";
import LeafFilterButton from "../../../leaf/LeafFilter/LeafFilterButton/LeafFilterButton";

type Props = {
  isLoading: boolean;
  title: string;
  badge1: {
    title: string;
    met: boolean;
    eligible: boolean;
  };
  badge2: {
    title: string;
    met: boolean;
    eligible: boolean;
  };
  viewDetailButton: {
    text: string;
    buttonOnClick: () => void;
  };
};

const TwoBadgesRequirementTile = ({ isLoading, title, badge1, badge2, viewDetailButton }: Props) => {
  const getBadgeType = (status: boolean) => {
    if (status === true) {
      return BADGE_TYPE.EARNED;
    }
    return BADGE_TYPE.POTENTIAL;
  };

  const displayTwoBadges = [badge1.eligible, badge2.eligible].every((eligible) => eligible === true);
  const noBadge = [badge1.eligible, badge2.eligible].every((eligible) => eligible === false);

  const getTitle = () => {
    if (displayTwoBadges) {
      return title;
    }
    return badge1.eligible ? badge1.title : badge2.title;
  };

  const isMet = () => {
    if (displayTwoBadges) {
      return badge1.met || badge2.met;
    }
    return badge1.eligible ? badge1.met : badge2.met;
  };

  if (noBadge) {
    return <></>;
  }

  return (
    <div className="two-badges-req-tile">
      <LeafTile
        isLoading={isLoading}
        title={getTitle()}
        showMetStatus={true}
        circleCheckmarkId={"twoBadgesReqTile"}
        met={isMet()}
      >
        <div className="two-badges-req-tile__content">
          {displayTwoBadges ? (
            <div className="two-badges-req-tile__content--two-badges">
              <div className="two-badges-req-tile__content__FQ">
                {badge1.title}
                <IconShield type={getBadgeType(badge1.met)} />
              </div>
              <div className="two-badges-req-tile__content__separator"></div>
              <div className="two-badges-req-tile__content__QC">
                {badge2.title}
                <IconShield type={getBadgeType(badge2.met)} />
              </div>
            </div>
          ) : (
            <IconShield size={BADGE_SIZE.EXTRA_LARGE} type={getBadgeType(badge1.eligible ? badge1.met : badge2.met)} />
          )}
          <div className="two-badges-req-tile__content--view-details-button">
            <LeafFilterButton text={""} onButtonClick={viewDetailButton.buttonOnClick}>
              {viewDetailButton.text}
              <IconPlainTable />
            </LeafFilterButton>
          </div>
        </div>
      </LeafTile>
    </div>
  );
};

export default TwoBadgesRequirementTile;
