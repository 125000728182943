import {
  DefaultContent,
  Kpi,
  MyBizPreference,
  PreferenceDetails,
  UpdatedSavedKpiPreferencesData,
} from "../../services/KPIPreferences/savedKpiPreferencesAPI.types";

export const getSavedKpiPreferencesForView = (
  viewName: string,
  savedKpiPreferencesData: PreferenceDetails,
): string[] => {
  return (
    savedKpiPreferencesData.savedDataViews?.[viewName]?.availableKpis
      .filter((kpi: Kpi) => kpi.displaydefault)
      .map((kpi: Kpi) => kpi.id) || []
  );
};

export const updateSavedKpiPreferencesForView = (
  viewName: string,
  viewDisplayName: string,
  availableKpis: string[],
  selectedKpis: string[],
  savedKpiPreferencesData: PreferenceDetails,
  defaultContent: DefaultContent,
  t: any,
): UpdatedSavedKpiPreferencesData => {
  const kpisAvailable = availableKpis.map((kpi, index) => ({
    id: kpi,
    displaydefault: selectedKpis.includes(kpi),
    label: t(kpi, defaultContent[kpi]),
    sequence: index,
  }));

  const preferenceDetails: PreferenceDetails = {
    savedDataViews: {
      ...savedKpiPreferencesData.savedDataViews,
      [viewName]: {
        viewDisplayName,
        availableKpis: kpisAvailable,
        timestamp: new Date().getTime(),
      },
    },
  };
  const myBizPreferenceObj: {
    myBizPreference: MyBizPreference;
  } = {
    myBizPreference: {
      preferenceType: "CC",
      preferenceDetails: JSON.stringify(preferenceDetails),
    },
  };

  return {
    preferenceDetails,
    myBizPreferenceObj,
  };
};
