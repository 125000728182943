import { useTranslation } from "react-i18next";
import { IconCarrot } from "../../../../common/icons/IconCarrot/IconCarrot";
import { Requirement } from "../../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import { LeafCheckmarksBase } from "../../../../common/leaf/LeafCheckmarks/LeafCheckmarksBase/LeafCheckmarksBase";
import LeafTag from "../../../../common/leaf/LeafTag/LeafTag";
import defaultContent from "../../../../content/bronzeGroupTracking";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import "./GraduatedTile.scss";
import { BRONZE_TAGS } from "../../../../reducers/model/bgtFilters";
import { storedLocale } from "../../../impersonation/util";
import { GetABODisplayName } from "../../bronzeGroupTrackingUtils";
import breakpoints from "../../../../common/scss/breakpoints.module.scss";
import { getLongPeriodFormat } from "../../../../common/util/period";

type Props = {
  aboName: string;
  aboLocalName: string;
  isoLanguage: string;
  isoCountry: string;
  aboNumber: string;
  affNumber: string;
  incentiveTag: "Bronze Builder" | "Bronze Foundation" | string;
  requirements: Requirement[];
  graduated: boolean;
  graduationPeriod: string;
  restarted: boolean;
};

export const GraduatedTile = ({
  aboName,
  aboLocalName,
  isoLanguage,
  isoCountry,
  aboNumber,
  affNumber,
  incentiveTag,
  requirements,
  graduated,
  graduationPeriod,
  restarted,
}: Props) => {
  const { t } = useTranslation(["bronzeGroupTracking"]);
  const { width } = useWindowSize();

  const formatedGraduationPeriod = getLongPeriodFormat(graduationPeriod, storedLocale());

  const renderTag = () => {
    const bronzeBuilderContent = t("bronzeBuilder", defaultContent["bronzeBuilder"]);
    const bronzeFoundationContent = t("bronzeFoundation", defaultContent["bronzeFoundation"]);
    return (
      <>
        <LeafTag hideTag={false} variant={incentiveTag === BRONZE_TAGS.BRONZE_BUILDER ? "darkRed" : "lightRed"}>
          {`${incentiveTag === BRONZE_TAGS.BRONZE_BUILDER ? bronzeBuilderContent : bronzeFoundationContent}`}
        </LeafTag>
      </>
    );
  };

  const renderGraduatedTileMobile = () => {
    return (
      <div className="GraduatedTile aboTrackingTile__display--mobile">
        <div className="aboTrackingTile__group">
          <div className="GraduatedTile__cell GraduatedTile__cell--mobileStatus">
            <span>{`${t(`graduated`, defaultContent["graduated"])}`}</span>
            <span>{renderTag()}</span>
          </div>
          <div className="GraduatedTile__aboName">
            <p>{<GetABODisplayName aboName={aboName} aboLocalName={aboLocalName} aboIsoLanguage={isoLanguage} />}</p>
            <LeafCheckmarksBase requirements={requirements} />
          </div>
          <div className="GraduatedTile__aboNum">
            <p>#{aboNumber}</p>
          </div>
          <div className="GraduatedTile__graduatedIn">
            <p>{`${t(`graduatedIn`, defaultContent["graduatedIn"], { graduationDate: formatedGraduationPeriod })}`}</p>
          </div>
        </div>

        <div className="GraduatedTile__arrow">
          <IconCarrot orientation="right" color="black" />
        </div>
      </div>
    );
  };

  const renderGraduatedTileDesktop = () => {
    return (
      <div className="GraduatedTile GraduatedTile__display--desktop">
        <div className="GraduatedTile__aboNameNum GraduatedTile__cell GraduatedTile__cell--name">
          <span className="GraduatedTile__aboName">
            {<GetABODisplayName aboName={aboName} aboLocalName={aboLocalName} aboIsoLanguage={isoLanguage} />}
          </span>
          <span className="GraduatedTile__aboNum">#{aboNumber}</span>
        </div>

        <div className="aboTrackingTile__infoTags GraduatedTile__cell GraduatedTile__cell--tags">{renderTag()}</div>

        <div className="GraduatedTile__cell GraduatedTile__cell--status">
          {`${t(`graduated`, defaultContent["graduated"])}`}
        </div>

        <div className="GraduatedTile__cell GraduatedTile__cell--graduationPeriod">{formatedGraduationPeriod}</div>

        <div className="GraduatedTile__arrow">
          <IconCarrot orientation="right" color="black" />
        </div>
      </div>
    );
  };

  return (
    <>
      {width && Number(width) < Number(breakpoints.desktop)
        ? renderGraduatedTileMobile()
        : renderGraduatedTileDesktop()}
    </>
  );
};
