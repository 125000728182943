import { Path, Polygon, Svg } from "@react-pdf/renderer";
import createStyles from "../PDFExportStyles";

const styles = createStyles();

export const AmwayLogo = () => (
  <Svg style={{ ...styles.logo, ...styles.marginBottomMD }} viewBox="0 0 206.39 95">
    <Path
      fill="#000000"
      d="M206.39,60.61a183.5,183.5,0,0,0-21-4.29c1.51-2.13,2.54-5.65,3.21-7.88l10.19-35.39h-9.26l-5.33,24-5.82-24h-9.88V13h-8.74V17.6c-1.61-3.45-4.4-5.44-7.93-5.44-6.07,0-14,5.23-14,19.27,0,11.16,5.7,18,13.32,18a9.32,9.32,0,0,0,8.43-4.68v3.92h8.92V13.16L178.4,44a11.85,11.85,0,0,1,.68,3,5.09,5.09,0,0,1-1.36,3.58c-.93.9-3,.9-3.84.9h-2.42v2.91C137.13,50.56,78.68,49,1.47,65.54L0,70.15S90.19,48,171.46,56v3.46c.93.07,1.36.14,2.42.14,6.56,0,8.85-.66,10.46-2.06,7.4,1,14.69,2.37,21.78,4ZM156.77,39.14a5.29,5.29,0,0,1-4,2c-1.8,0-3.85-1.1-5.08-4.2a16.05,16.05,0,0,1-1-6.13A13.71,13.71,0,0,1,148.66,23a5.79,5.79,0,0,1,4.52-2.62,5,5,0,0,1,3.66,1.79c1.15,1.33,2.29,3.72,2.29,8.33C159.13,33.71,158.69,36.8,156.77,39.14Z"
    />
    <Polygon
      fill="#000000"
      points="111.5 48.71 116.82 24.62 122.22 48.71 130.71 48.71 140 13.05 130.71 13.05 125.81 35.36 120.85 13.05 112.8 13.05 107.78 35.36 102.89 13.05 93.59 13.05 102.89 48.71 111.5 48.71"
    />
    <Path
      fill="#000000"
      d="M20.38,38.36H34.73l2.92,10.35H56.81V33c0-6.75.07-9.36,2.73-10.88a4.87,4.87,0,0,1,2.36-.62,4.33,4.33,0,0,1,3.71,2.14c.93,1.58.93,3.3.93,7.15v18h9.11V31.09c0-2.89,0-5.85,1-7.57a4.74,4.74,0,0,1,3.91-2.07,4,4,0,0,1,3.78,2c.93,1.51,1,2.68,1,8.4V48.71h9.11v-19c0-8.26-.13-11.56-3.22-14.59A10.36,10.36,0,0,0,84,12.3a10.75,10.75,0,0,0-9.79,6.05A8.38,8.38,0,0,0,69,12.84a10.66,10.66,0,0,0-3.53-.61,9.84,9.84,0,0,0-6.26,2.13,8.79,8.79,0,0,0-2.48,3.51V13.05h-9V44.92L33,0H21.59L6.29,48.71H17.53Zm7-27.66,4.88,18.76H22.73Z"
    />
  </Svg>
);
