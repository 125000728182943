import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { useAppSelector } from "../../../../store";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { isProgramActive } from "../../../../common/util/checkProgramActiveStatus";
import { EligibleIncentive } from "../../../../services/ShellConfig/getShellConfigAPI.types";
import { FetchAnnualIncentiveTracking } from "./AnnualIncentiveTrackingDataLayer";
import { PggTrackingTile } from "./PggTrackingTile/PggTrackingTile";
import { FggTrackingTile } from "./FggTrackingTile/FggTrackingTile";
import "./AnnualIncentiveTracking.scss";
import { FaaTrackingTile } from "./FaaTrackingTile/FaaTrackingTile";

export const AnnualIncentiveTrackingTile = () => {
  const { selectedPeriod } = useAppSelector((store) => store.period);
  const { shellConfig } = useAppSelector((state) => state.boot);
  const { isLoading, annualIncentivesTrackingData, errorStatus, refetch } = FetchAnnualIncentiveTracking({
    period: selectedPeriod,
  });

  const { PggTile, FggTile, FaaTile } = annualIncentivesTrackingData;

  if (errorStatus) {
    return (
      <ErrorTile
        clickFunction={refetch}
        errorStatus={errorStatus}
        isLoading={isLoading}
        tealiumTrackId="Annual_Incentive_Tracking_Tile"
      />
    );
  }

  const checkEligibility = (awardcode: AWARD_CODES) => {
    return (
      isProgramActive(shellConfig.eligibleIncentives, awardcode) ||
      isComingSoon(shellConfig.eligibleIncentives, awardcode)
    );
  };

  const isComingSoon = (eligibleIncentives: EligibleIncentive[] = [], awardCode: number) => {
    return eligibleIncentives.some(({ awardNumber, comingSoon }) => awardNumber === awardCode && comingSoon);
  };

  const checkSingleTile = () => {
    return (
      (checkEligibility(AWARD_CODES.ANNUAL_PGG) || checkEligibility(AWARD_CODES.PGG_GCR)) &&
      checkEligibility(AWARD_CODES.FGG)
    );
  };

  return (
    <section
      className={`annualIncentiveTrackingTile__container ${
        !checkSingleTile() ? "annualIncentiveTrackingTile__singleTile" : ""
      }`}
    >
      <div className="annualIncentiveTrackingTile__groupGrowthTiles">
        {(checkEligibility(AWARD_CODES.ANNUAL_PGG) || checkEligibility(AWARD_CODES.PGG_GCR)) && (
          <div className="annualIncentiveTrackingTile__tile">
            <PggTrackingTile tileData={PggTile} isLoading={isLoading} />
          </div>
        )}
        {checkEligibility(AWARD_CODES.FGG) && (
          <div className="annualIncentiveTrackingTile__tile">
            <FggTrackingTile tileData={FggTile} isLoading={isLoading} />
          </div>
        )}
      </div>
      {!FaaTile.DisableTile && (
        <div className="annualIncentiveTrackingTile__tile--fullwidth">
          <FaaTrackingTile tileData={FaaTile} isLoading={isLoading} />
        </div>
      )}
    </section>
  );
};
