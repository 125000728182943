import { LeafErrorMessage } from "../../../../../../common/leaf/LeafErrorMessage/LeafErrorMessage";
import LeafBirthdayInfo from "../../../../../../common/leaf/LeafProfile/LeafInfo/LeafBirthdayInfo/LeafBirthdayInfo";
import LeafDateJoinedInfo, {
  BUSINESS_ENTRY_KEY,
} from "../../../../../../common/leaf/LeafProfile/LeafInfo/LeafDateJoinedInfo/LeafDateJoinedInfo";
import LeafRecentOrderDateInfo from "../../../../../../common/leaf/LeafProfile/LeafInfo/LeafRecentOrderDateInfo/LeafRecentOrderDateInfo";
import LeafRecentSponsorDateInfo from "../../../../../../common/leaf/LeafProfile/LeafInfo/LeafRecentSponsorDateInfo/LeafRecentSponsorDateInfo";
import LeafRenewalDateInfo from "../../../../../../common/leaf/LeafProfile/LeafInfo/LeafRenewalDateInfo/LeafRenewalDateInfo";
import LeafProfileLoader from "../../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import { useGetABOProfileQuery } from "../../../../../../services/Profile/profileAPI";
import { useAppSelector } from "../../../../../../store";
import "./DatesTab.scss";

const DatesTab = () => {
  // Get Login ABO
  const user = useAppSelector((state) => state.boot?.user);
  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const { selectedPeriod } = useAppSelector(({ period }) => period);
  const profileInfo = useAppSelector((state) => state.boot?.configuration?.profile?.aboInfo);

  const { showBirthday, showEntryDate, showRecentOrderDate, showRenewalDate, showRecentSponsorDate } =
    profileInfo || {};

  // fetch profile data
  const {
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
    data: profileData,
  } = useGetABOProfileQuery({
    period: selectedPeriod,
    affAbo,
    reqAffAbo: `${user.aff}-${user.abo}`,
  });

  // Display loading state
  if (isFetchingProfile === true) {
    return <LeafProfileLoader row={5} card={true} isFetching={isFetchingProfile} />;
  }

  // Display error state
  if (isErrorProfile === true) {
    return <LeafErrorMessage show={isErrorProfile} />;
  }

  // Profile Data
  const { isoBirthdate = "", entryDate = "", renewalDate = "", recentSponsorDate = "" } = profileData?.aboInfo || {};

  const { aboRecentOrderDate = "" } = profileData?.orderInfo || {};

  return (
    <div className="key-dates-tab__container" tabIndex={0}>
      <div className="key-dates-tab__content">
        <LeafBirthdayInfo date={isoBirthdate} show={showBirthday} />
        <LeafRenewalDateInfo date={renewalDate} show={showRenewalDate} />
        <LeafDateJoinedInfo date={entryDate} show={showEntryDate} title={BUSINESS_ENTRY_KEY} />
        <LeafRecentSponsorDateInfo date={recentSponsorDate} show={showRecentSponsorDate} />
        <LeafRecentOrderDateInfo date={aboRecentOrderDate} show={showRecentOrderDate} />
      </div>
    </div>
  );
};

export default DatesTab;
