import { useEffect, useState } from "react";
import { getAbo, getAff } from "../impersonation/util";
import { BronzeGroupTrackingApiData } from "../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import { useGetBronzeGroupTrackingApiQuery } from "../../services/CorePlusIncentives/corePlusIncentivesApi";
import { store, useAppSelector } from "../../store";
import { ROUTE_PATHS } from "../../common/enums/routing-enums";
import { lambdaErrorCheck } from "../../common/util/errorHandling";
import { getCurrentPeriod } from "../../common/util/period";
import { returnDefaultBronzeTrackingData } from "./bronzeGroupTrackingUtils";

export const FetchBronzeGroupTrackingData = (period: string) => {
  const { removePeriodSelectorFromRoute = [] } = useAppSelector((state) => state.boot.configuration);
  const { user } = useAppSelector((state) => state.boot);
  const useCurrentPeriod = removePeriodSelectorFromRoute.includes(ROUTE_PATHS.BRONZE_GROUP_TRACKING);
  const {
    data: payloadData,
    isFetching,
    isError,
  } = useGetBronzeGroupTrackingApiQuery({
    baseUrl: store.getState().boot.configuration.incentivesApiUrl,
    affAbo: `${getAff()}-${getAbo()}`,
    bonusPeriod: useCurrentPeriod ? getCurrentPeriod() : period,
    countryCode: user.isoCountryCode,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [bronzeTrackingData, setData] = useState<BronzeGroupTrackingApiData>(returnDefaultBronzeTrackingData());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (payloadData && !isFetching && !isError) {
      setData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.errors);
      setErrorStatus(foundLambdaError);
    }
  }, [isError, payloadData, isFetching]);

  return { isLoading, bronzeTrackingData, errorStatus };
};
