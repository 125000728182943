import { AWARD_CODES } from "../../../../../common/enums/award-codes";
import { ROUTE_PATHS } from "../../../../../common/enums/routing-enums";
import { AnnualTrackingGGTile } from "../../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI.types";
import { useAppSelector } from "../../../../../store";
import { GroupGrowthTrackingTile } from "../../GroupGrowthTrackingTile/GroupGrowthTrackingTile";

interface FggTrackingTileProps {
  tileData: AnnualTrackingGGTile;
  isLoading: boolean;
}

export const FggTrackingTile = ({ tileData, isLoading }: FggTrackingTileProps) => {
  const { shellConfig } = useAppSelector((state) => state.boot);
  const fgg = shellConfig.eligibleIncentives.filter((incentive) => incentive.awardNumber === AWARD_CODES.FGG);
  const isComingSoon = fgg.some((incentive) => incentive.comingSoon);

  return (
    <GroupGrowthTrackingTile
      isLoading={isLoading}
      baselineMonths={tileData.BaselineMonths}
      trackingMonths={tileData.TrackingMonths}
      trackingBonusAmount={tileData.TrackingBonusAmount}
      isComingSoon={isComingSoon}
      title="frontlineGrowth"
      titleRoutePath={ROUTE_PATHS.FGG}
      countLabel="fqsLabel"
      isDisabled={tileData.DisableTile}
    />
  );
};
