import { useTranslation } from "react-i18next";
import { BadgeRequirementTile } from "../../../common/components/RequirementTiles/BadgeRequirementTile/BadgeRequirementTile";
import { Requirements } from "../../../services/GroupGrowth/groupGrowth.types";
import { VcsPercentRequirementTile } from "../../../common/components/RequirementTiles/VcsPercentRequirementTile/VcsPercentRequirementTile";
import { VolumeRequirementTile } from "../../../common/components/RequirementTiles/VolumeRequirementTile/VolumeRequirementTile";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import fggContent from "../../../content/frontlineGrowth";
import "./RequirementTileMapper.scss";
import { REQUIREMENT_NAME } from "../../enums/requirements";

export const RequirementTileMapper = ({
  isLoading,
  requirements,
  content = fggContent,
  translation = "frontlineGrowth",
  numOfLoadersOverride,
  type,
}: {
  isLoading: boolean;
  requirements: Requirements;
  numOfLoadersOverride?: number;
  content?: { [key: string]: any };
  translation?: string;
  type?: "monthly" | "annual";
}) => {
  const { VolumeRequirements, PercentRequirements, BadgeRequirements = [] } = requirements;

  const { t } = useTranslation([translation]);
  const numberOfLoaders = numOfLoadersOverride || Math.min(requirements.TotalRequirements, 3);

  const renderLoaders = () => {
    const loaders = [];
    for (let i = 0; i < numberOfLoaders; i++) {
      loaders.push(
        <div className="Growth-requirement__tile-loader">
          <LeafTile isLoading={isLoading} className="Growth-requirement__tile-loader" />
        </div>,
      );
    }
    return <>{loaders}</>;
  };

  const checkRubyContentKey = (name: REQUIREMENT_NAME) => {
    if (type === "annual" && name === REQUIREMENT_NAME.RUBY) {
      return REQUIREMENT_NAME.ANNUAL_RUBY;
    }
    return name;
  };

  return isLoading ? (
    <>{renderLoaders()}</>
  ) : (
    <>
      {VolumeRequirements.map((requirement) => {
        const definition = content["requirements"][requirement.Name]["definition"]
          ? t(
              `requirements.${requirement.Name}.definition`,
              content["requirements"][requirement.Name]["definition"],
            ).toString()
          : "";
        return (
          <section className="Growth-requirement__tile" key={requirement.Name} data-testid={"Tile-" + requirement.Name}>
            <VolumeRequirementTile
              isLoading={false}
              title={t(
                `requirements.${checkRubyContentKey(requirement.Name)}.title`,
                content["requirements"][checkRubyContentKey(requirement.Name)]["title"] as string,
                {
                  volume:
                    translation === "ssi" ? requirement.Volume.Target.Actual : requirement.Volume.Target.Formatted,
                  volumeRuby: requirement.Volume.Target.Formatted,
                  volumeLegs: requirement.Legs ? requirement.Legs.Target.Formatted : "",
                },
              )}
              currentAmount={requirement.Volume.Current.Actual}
              targetAmount={requirement.Volume.Target.Actual}
              met={requirement.Met}
              circleCheckmarkId={requirement.Name}
              volumeType={requirement.Name}
              labels={[requirement.Volume.Current.Formatted, requirement.Volume.Remaining.Formatted]}
              showLabelsOnly={true}
              additionalNeeded={requirement.Volume.Remaining.Formatted}
              exempt={requirement.Exempt}
              definition={definition}
            />
          </section>
        );
      })}
      {PercentRequirements.map((requirement) => {
        return (
          <section className="Growth-requirement__tile" key={requirement.Name} data-testid={"Tile-" + requirement.Name}>
            <VcsPercentRequirementTile
              isLoading={false}
              additionalNeeded={requirement.Percent.Remaining.Actual}
              targetValue={requirement.Percent.Target.Actual}
              met={requirement.Met}
              shouldShowGraphics={true}
              leftValue={requirement.Volume.Current.Actual}
              rightValue={requirement.Volume.Target.Actual}
              percent={requirement.Percent.Current.Actual}
            />
          </section>
        );
      })}
      {BadgeRequirements.map((requirement) => {
        return (
          <section
            className={
              "Growth-requirement__tile" +
              (requirement.Name === REQUIREMENT_NAME.MAINTAIN_QC ? " Growth-requirement__wideTile" : "")
            }
            key={requirement.Name}
            data-testid={"Tile-" + requirement.Name}
          >
            <BadgeRequirementTile
              title={t(
                `requirements.${requirement.Name}.title`,
                content["requirements"][requirement.Name]["title"] as string,
                {
                  bonusAmount: requirement.Target,
                },
              )}
              description={t(
                `requirements.${requirement.Name}.description`,
                content["requirements"][requirement.Name]["description"] as string,
              )}
              status={requirement.Status}
              showLabel={requirement.ShowLabel}
            />
          </section>
        );
      })}
    </>
  );
};
