import { Trans, useTranslation } from "react-i18next";
import { IconCheckCircle } from "../../../../common/icons/IconCheckCircle/IconCheckCircle";
import defaultContent from "../../../../content/leafIncentiveProgress";
import { TrackProgressTile } from "../../../../common/components/TrackProgressTile/TrackProgressTile";
import { BronzeOverviewResponse } from "../../../../services/BronzeIncentives/bronzeIncentivesAPI.types";
import "./BFIIncentiveProgress.scss";

type Props = {
  data: BronzeOverviewResponse;
  show?: boolean;
  isFetching: boolean;
};

const BFIIncentiveProgress = ({ data, show = true, isFetching = true }: Props) => {
  const { t } = useTranslation(["leafIncentiveProgress"]);

  const { requirements } = data;
  const { requiredCount = 0, metCount = 0, requirementMetStatuses = [] } = requirements || {};

  const id = "Bronze Foundation Incentive Progress";

  const circlesSection = () => {
    return (
      <div className="Tracking__icons">
        {requirementMetStatuses &&
          requirementMetStatuses.map((isReqMet, index) => (
            <div
              key={index}
              tabIndex={0}
              aria-label={
                isReqMet
                  ? `Bronze Builder Requirement ${index + 1} Met`
                  : `Tracking Bronze Builder Requirement ${index + 1}`
              }
            >
              <IconCheckCircle key={index} id={index.toString()} color={isReqMet ? "green" : "gray"} size="xlg" />
            </div>
          ))}
      </div>
    );
  };

  const title = t("title", defaultContent.title).toString();
  const subtext = (
    <Trans>
      {t("bronzeProgressText", defaultContent.bronzeProgressText, {
        metCount,
        requiredCount,
      })}
      <span className={requiredCount === metCount ? "Tracking__bold Tracking__met" : "Tracking__bold"}></span>
    </Trans>
  );

  return (
    <TrackProgressTile
      isLoading={isFetching}
      id={id}
      title={title}
      circlesSection={circlesSection()}
      subtext={subtext}
    />
  );
};
export default BFIIncentiveProgress;
