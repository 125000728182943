import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../../../store";
import { lambdaErrorCheck } from "../../../../common/util/errorHandling";
import { useLazyGetMonthlyBonusBreakdownQuery } from "../../../../services/MonthlyPerformanceDashboard/monthlyPerformanceDashboardAPI";
import { MonthlyBonusBreakdownData } from "../../../../services/MonthlyPerformanceDashboard/monthlyPerformanceDashboardAPI.types";
import { storedLocale } from "../../../impersonation/util";

export const defaultBonusBreakdownData = (): MonthlyBonusBreakdownData => {
  return {
    BonusAmountSubTotal: "",
    BonusAmount: [],
    CallResults: [],
    Error: null,
  };
};

export const FetchBonusBreakdownData = (period: string) => {
  const { configuration, user, isShellFetching, isShellLoading } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);

  const { persona, hasAchieved15PercentOrHigher } = shellConfig || {};
  const [isShellDataReady, setIsShellDataReady] = useState(false);

  useEffect(() => {
    setIsShellDataReady(shellConfig && !isShellLoading && !isShellFetching);
  }, [shellConfig, isShellLoading, isShellFetching]);

  const [triggerBonusBreakdownQuery, { data: payloadData, isLoading: isBonusBreakdownLoading, isFetching, isError }] =
    useLazyGetMonthlyBonusBreakdownQuery();

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [bonusBreakdownData, setBonusBreakdownData] = useState<MonthlyBonusBreakdownData>(defaultBonusBreakdownData());

  useMemo(() => {
    if (isShellDataReady) {
      triggerBonusBreakdownQuery(
        {
          baseUrl: configuration.monthlyDashboardApiUrl,
          abo: user.abo,
          aff: user.aff,
          period: period,
          isoCountryCode: user.isoCountryCode,
          isoCurrencyCode: shellConfig?.isoCurrencyCode,
          locale: storedLocale(),
          persona: persona,
          hasQualified: hasAchieved15PercentOrHigher,
          bonusPeriodCount: 1,
          disabledIncentives: "",
        },
        true,
      ); // use cached response if available
    }
  }, [
    configuration.monthlyDashboardApiUrl,
    hasAchieved15PercentOrHigher,
    isShellDataReady,
    period,
    persona,
    shellConfig?.isoCurrencyCode,
    triggerBonusBreakdownQuery,
    user.abo,
    user.aff,
    user.isoCountryCode,
  ]);

  useEffect(() => {
    setErrorStatus(isError);
    if (!isLoading && !isError && payloadData) {
      setBonusBreakdownData(payloadData);
      if (payloadData.Error && payloadData.Error[0] !== null) {
        const foundLambdaError = lambdaErrorCheck(payloadData.Error);
        setErrorStatus(foundLambdaError);
      }
    }
  }, [isLoading, isError, payloadData]);

  useMemo(() => {
    setIsLoading(isFetching || isBonusBreakdownLoading || isShellLoading || isShellFetching);
  }, [isFetching, isBonusBreakdownLoading, isShellLoading, isShellFetching]);

  return { isLoading, bonusBreakdownData, errorStatus, refetch: triggerBonusBreakdownQuery };
};
