import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../content/common";
import "./BonusInfoFooter.scss";
import { TimeStamp } from "../TimeStamp/TimeStamp";

type Props = {
  hideDate?: boolean;
};

export const BonusInfoFooter = (props: Props) => {
  const { t } = useTranslation(["common"]);
  const { hideDate = false } = props;

  return (
    <div className="bonus-footer-container">
      {hideDate === false && (
        <section className="short-date">
          <TimeStamp dateFormat={"short"} />
        </section>
      )}
      <br />
      <div className="bonus-footer-desc">
        <section className="market-disclaimer">
          <>{t("marketDisclaimer", defaultContent["marketDisclaimer"])}</>
        </section>
        <br />
        <section>
          <>{t("totalsDisclaimer", defaultContent["totalsDisclaimer"])}</>
        </section>
        <br />
        <section>
          <Trans
            i18nKey={"bonusesDisclaimer"}
            ns="common"
            defaults={defaultContent["bonusesDisclaimer"]}
            components={{
              em: <em />,
              br: <br />,
            }}
          ></Trans>
        </section>
        <br />
        <section>
          <Trans
            i18nKey={"footer"}
            ns="common"
            defaults={defaultContent["footer"]}
            components={{
              // displays only for ANA
              1: <a href="http://amway.com/en_US/money-and-rewards" id="bonus-info-footer-link"></a>,
              br: <br />,
            }}
          ></Trans>
        </section>
      </div>
    </div>
  );
};
