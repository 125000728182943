import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import LeafTable, { TableRow, RowColumn } from "../../../common/leaf/LeafTable/LeafTable";
import { PPEMatrix } from "../../../services/PerformancePlusElite/performancePlusEliteAPI.types";
import defaultContent from "../../../content/ppe";
import "./MultiplierMatrix.scss";
import { getCurrentPeriod } from "../../../common/util/period";
import { useAppSelector } from "../../../store";

export type props = {
  data: PPEMatrix;
  isLoading: boolean;
  open: boolean;
  closeButtonCallback: Function;
  multiplier: string;
};

const getCellClass = (highlighted: boolean, isCurrentMonth: boolean, percentLevel: string) => {
  const classes = ["PPEMultiplierMatrix__row"];

  if (highlighted) {
    let hightlightClass = "PPEMultiplierMatrix__hightlight";
    isCurrentMonth || percentLevel === "0%" ? (hightlightClass += "--orange") : (hightlightClass += "--green");

    classes.push(hightlightClass);
  }

  return classes.join(" ");
};

const createRows = (data: PPEMatrix, selectedPeriod: string, t: TFunction): TableRow[] => {
  const isCurrentMonth = selectedPeriod === getCurrentPeriod();

  return data.Rows.map((row) => {
    const [col1, col2] = row;

    const rowCols: RowColumn[] = [
      {
        value: <span className={getCellClass(col1.IsHighlighted, isCurrentMonth, col1.Value)}>{col1.Value}</span>,
      },
      {
        value: (
          <span
            className={getCellClass(col2.IsHighlighted, isCurrentMonth, col1.Value) + " PPEMultiplierMatrix__rubyPv"}
          >
            {col2.ContentKey
              ? t(`multiplierMatrix.${col2.ContentKey}`, defaultContent?.multiplierMatrix?.[col2.ContentKey], {
                  volume: col2.Value,
                })
              : col2.Value}
          </span>
        ),
      },
    ];

    return { row: rowCols };
  });
};

export const MultiplierMatrix = ({ data, multiplier, isLoading, open, closeButtonCallback }: props) => {
  const { t } = useTranslation(["ppe"]);
  const { selectedPeriod } = useAppSelector(({ period }) => period);

  return (
    <div className="PPEMultiplierMatrix">
      <LeafModal
        title={`${t("multiplierMatrix.title", defaultContent.multiplierMatrix.title)}`}
        subtitle={`${t("multiplierMatrix.potentialMultiplier", defaultContent.multiplierMatrix.potentialMultiplier, {
          multiplier: multiplier,
        })}`}
        open={open}
        onCloseCallbackFn={closeButtonCallback}
      >
        <div className="PPEMultiplierMatrix__table">
          <LeafTable
            header={[
              {
                value: `${t("multiplierMatrix.multiplier", defaultContent.multiplierMatrix.multiplier)}`,
                width: "50%",
              },
              { value: `${t("multiplierMatrix.rubyPv", defaultContent.multiplierMatrix.rubyPv)}`, width: "50%" },
            ]}
            rows={createRows(data, selectedPeriod, t)}
          />
        </div>
      </LeafModal>
    </div>
  );
};
