import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { track } from "@amwaycommon/acc-mixins/analytics";
import defaultContent from "../../../content/annualPerformanceDashboard";
import "./AnnualPerformanceDash.scss";
import LeafTile from "../../../common/leaf/LeafTile/LeafTile";
import { useAppDispatch, useAppSelector } from "../../../store";
import { SELECTOR_TYPE, updateSelectorType } from "../../../reducers/period";
import TEALIUM from "../../../common/enums/tealium-enums";
import { AWARD_CODES } from "../../../common/enums/award-codes";
import { isProgramActive } from "../../../common/util/checkProgramActiveStatus";
import { AnnualInfoModal } from "./InfoModal/AnnualInfoModal";
import { AnnualKeyMetricsTile } from "./AnnualKeyMetrics/AnnualKeyMetrics";
import { TrackingPinInformation } from "./TrackingPinInformation/TrackingPinInformation";
import { AnnualBonusBreakdown } from "./AnnualBonusBreakdown/AnnualBonusBreakdown";
import { AnnualIncentiveTrackingTile } from "./AnnualIncentiveTracking/AnnualIncentiveTrackingTile";

export const AnnualPerformanceDashboard = ({
  showModal,
  modalCloseCallback,
}: {
  showModal: boolean;
  modalCloseCallback: Function;
}) => {
  const { t } = useTranslation(["annualPerformanceDashboard", "common"]);
  const dispatch = useAppDispatch();

  const { shellConfig } = useAppSelector((state) => state.boot);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.PY));
  }, [dispatch]);

  useEffect(() => {
    track(
      TEALIUM.U_TAG_METHOD.VIEW,
      "",
      "annual_dashboard",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      `persona${shellConfig.persona}:annual`,
    );
  }, []);

  const trackDashboardToggleClick = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "monthlyDashboard",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "dashboard_toggle",
    );
  };

  const checkEligibility = () => {
    return (
      isProgramActive(shellConfig.eligibleIncentives, AWARD_CODES.ANNUAL_PGG) ||
      isProgramActive(shellConfig.eligibleIncentives, AWARD_CODES.PGG_GCR) ||
      isProgramActive(shellConfig.eligibleIncentives, AWARD_CODES.FGG) ||
      isProgramActive(shellConfig.eligibleIncentives, AWARD_CODES.FAA)
    );
  };

  return (
    <main className="Apd">
      <div className="Apd__container">
        <div className="Apd__container-split">
          <div className="Apd__container-row">
            <section className="Apd__section">
              <LeafTile hideBorder={true} isLoading={false}>
                <section className="Apd__keymetricsContainer">
                  <AnnualKeyMetricsTile />
                </section>
              </LeafTile>
            </section>
            <section className="Apd__section">
              <AnnualBonusBreakdown />
            </section>
          </div>
          <div className="Apd__container-row">
            <section className="Apd__section">
              <LeafTile isLoading={false} hideBorder={true}>
                <div className="TpiTile">
                  <div className={"TpiTile__header"}>
                    <h2 className="TpiTile__title">{t("trackingPinTitle", defaultContent["trackingPinTitle"])}</h2>
                  </div>
                  <div className="TpiTile__tiles">
                    <TrackingPinInformation />
                  </div>
                </div>
              </LeafTile>
            </section>
            <section className="Apd__section">
              {checkEligibility() && (
                <LeafTile isLoading={false} hideBorder={true}>
                  <div className="TpiTile">
                    <div className={"TpiTile__header"}>
                      <h2 className="TpiTile__title">
                        {t("annualIncentiveTrackingTitle", defaultContent["annualIncentiveTrackingTitle"])}
                      </h2>
                    </div>
                    <AnnualIncentiveTrackingTile />
                  </div>
                </LeafTile>
              )}
            </section>
          </div>
        </div>
      </div>
      <AnnualInfoModal show={showModal} setShowInfoModal={modalCloseCallback} />
    </main>
  );
};
