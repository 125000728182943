import { useTranslation } from "react-i18next";
import { LeafModal } from "../../leaf/LeafModal/LeafModal";
import defaultContent from "../../../content/pqModal";
import { createMultiplierDetailsTableRows } from "../../../components/PGG/AnnualPGG/MultiplierDetails/MultiplierDetailsUtil";
import { getPerformanceYearShort } from "../../util/period";
import LeafTable from "../../leaf/LeafTable/LeafTable";
import { PQ } from "../../../services/GroupGrowth/PersonalGroupGrowth/personalGroupGrowthAPI.types";
import LeafRectangleLoader from "../../leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { ErrorTile } from "../ErrorTile/ErrorTile";
import { BadgeLegend } from "../BadgeLegend/BadgeLegend";
import "./PQModal.scss";

interface PQModalProps {
  toggleModal: Function;
  displayModal: boolean;
  pqMonths: PQ[];
  header: "currentYtd" | "previousYtd" | "monthlyGpv";
  performanceYear: string;
  isLoading: boolean;
  showBooster: boolean;
  showMonthlyGpv?: boolean;
  errorStatus: boolean;
  refetchCallback: Function;
}

export const PQModal = ({
  toggleModal,
  displayModal,
  pqMonths,
  header,
  performanceYear,
  isLoading,
  showMonthlyGpv,
  showBooster,
  errorStatus,
  refetchCallback,
}: PQModalProps) => {
  const { t } = useTranslation(["pqModal"]);
  let headers: string[];
  if (showMonthlyGpv) {
    headers = ["qualified", "gpv"];
  } else {
    headers = ["qualified", "rubyPv", "qualifiedLegs", "rubyPvNeeded"];
  }
  const headersBoost = ["qualified", "boosterRequirements", "rubyPv", "qualifiedLegs", "rubyPvNeeded"];

  const colWidth = 100 / (showBooster ? headersBoost.length : headers.length) + "%";
  const rows = createMultiplierDetailsTableRows(pqMonths, showBooster, showMonthlyGpv);

  const generateHeaders = () => {
    const headersToUse = showBooster ? headersBoost : headers;
    return headersToUse.map((header) => ({
      value: `${t(`pqModal.${header}`, defaultContent.pqModal[header])}`,
      width: colWidth,
    }));
  };

  const renderLegend = () => {
    return <BadgeLegend />;
  };

  const renderModalBody = () => {
    return (
      <div className="PQModal__body">
        {!isLoading ? (
          <LeafTable
            header={generateHeaders()}
            rows={rows}
            columnAlign="center"
            hideHeader={false}
            rowHeight="3.25rem"
            headerStyle={"default"}
          />
        ) : (
          <>
            <LeafRectangleLoader width="100%" height="4.3125rem" margin="0" isLoading={true} />
            <LeafRectangleLoader width="100%" height="2.75rem" margin=".5rem 0" isLoading={true} numRows={5} />
          </>
        )}
      </div>
    );
  };

  return (
    <div className="PQModal">
      <LeafModal
        open={displayModal}
        onCloseCallbackFn={toggleModal}
        title={`${t(`pqModal.${header}`, defaultContent.pqModal[header])}`}
        subtitle={`${t(`pqModal.performanceYear`, defaultContent.pqModal.performanceYear, {
          performanceYear: getPerformanceYearShort(performanceYear),
        })}`}
        description={renderLegend()}
      >
        {errorStatus && !isLoading ? (
          <ErrorTile
            clickFunction={refetchCallback}
            errorStatus={errorStatus}
            isLoading={isLoading}
            tealiumTrackId="PQ_Modal"
          />
        ) : (
          renderModalBody()
        )}
      </LeafModal>
    </div>
  );
};
