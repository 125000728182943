import { getLoginAffAbo } from "../../../../../reducers/model/boot";
import { useGetCustomerPVStatQuery } from "../../../../../services/Customer/CustomerPVStat/customerPVStatAPI";
import { useAppSelector } from "../../../../../store";
import Statistic from "../Statistic";

const CustomerPVStatistic = () => {
  const { selectedPeriod } = useAppSelector(({ period }) => period);

  const boot = useAppSelector(({ boot }) => boot);
  const affAbo = getLoginAffAbo({ boot });

  const { data, isFetching } = useGetCustomerPVStatQuery({ affAbo, period: selectedPeriod });
  const { current = 0, previous = 0, entryDate = "" } = data || {};

  return (
    <Statistic
      isFetching={isFetching}
      current={current}
      previous={previous}
      entryDate={entryDate}
      selectedPeriod={selectedPeriod}
      module="CustomerPV"
      formatPV={true}
    />
  );
};

export default CustomerPVStatistic;
