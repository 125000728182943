import "./IconClipboard.scss";
import { ReactComponent as Clipboard } from "./svg/icon-clipboard.svg";

type Props = {
  fill?: "black" | "white" | "blue" | "green";
};

export const IconClipboard = ({ fill = "black" }: Props) => {
  return (
    <span className={`IconClipboard IconClipboard__${fill}`}>
      <Clipboard />
    </span>
  );
};
