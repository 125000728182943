import { accAPI } from "..";
import {
  PathToBronzeOverviewParam,
  PathToBronzeOverviewResponse,
  BronzeOverviewParam,
  BronzeOverviewResponse,
} from "./bronzeIncentivesAPI.types";

export const bronzeAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getBronzeOverview: build.query<BronzeOverviewResponse, BronzeOverviewParam>({
      query: (params) => {
        const {
          affAbo,
          period,
          awardCode,
          fixedPayment,
          restart1,
          restart2,
          locale,
          isoCountryCode,
          isoCurrencyCode,
          isStandarRetailEnhancementCountry,
        } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          awardCode: `${awardCode}`,
          locale,
          isoCountryCode,
          isoCurrencyCode,
          isStandarRetailEnhancementCountry: `${isStandarRetailEnhancementCountry}`,
        });

        // optional fields
        if (restart1 !== void 0) {
          queryParams.append("restart1", `${restart1}`);
        }

        if (restart2 !== void 0) {
          queryParams.append("restart2", `${restart2}`);
        }

        if (fixedPayment) {
          queryParams.append("fixedPaymentAwardCode", `${fixedPayment}`);
        }

        return `/incentives-v2/api/bronze/overview?${queryParams}`;
      },
    }),
    getPathToBronzeOverview: build.query<PathToBronzeOverviewResponse, PathToBronzeOverviewParam>({
      query: (params) => {
        const { affAbo, period, locale, countryCode } = params;
        const queryParams = new URLSearchParams({
          affAbo,
          period,
          locale,
          countryCode,
        });
        return `/incentives-v2/api/ptb/overview?${queryParams}`;
      },
    }),
  }),
});

export const { useGetBronzeOverviewQuery, useGetPathToBronzeOverviewQuery } = bronzeAPI;
