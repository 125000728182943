import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ABO_PROFILE_TABS_ID, CUSTOMER_PROFILE_TABS_ID } from "../common/enums/profile-enums";
import { setBoot } from "./boot";

type Profile = {
  affAbo: string;
  isShowProfile: boolean;
  isShowApplicant: boolean;
  customerDefaultTab: string;
  customerActiveTab: string;
  customerActiveTabs: CUSTOMER_PROFILE_TABS_ID[];
  aboDefaultTab: string;
  aboActiveTab: string;
  aboActiveTabs: ABO_PROFILE_TABS_ID[];
};

const initialState: Profile = {
  affAbo: "",
  isShowProfile: false,
  isShowApplicant: false,
  customerDefaultTab: "",
  customerActiveTab: "",
  customerActiveTabs: [],
  aboDefaultTab: "",
  aboActiveTab: "",
  aboActiveTabs: [],
};

const getDefaultTab = (tabs: string[]): string => (Array.isArray(tabs) && tabs.length ? tabs[0] : "");

const { actions, reducer } = createSlice({
  name: "profile",
  initialState,
  reducers: {
    showProfile: (state, action: PayloadAction<string>) => ({
      ...state,
      affAbo: action.payload,
      isShowProfile: true,
      customerActiveTab: state.customerDefaultTab,
      aboActiveTab: state.aboDefaultTab,
    }),
    hideProfile: (state) => ({
      ...state,
      isShowProfile: false,
      customerActiveTab: state.customerDefaultTab,
      aboActiveTab: state.aboDefaultTab,
    }),
    showApplicant: (state) => ({
      ...state,
      isShowApplicant: true,
    }),
    hideApplicant: (state) => ({
      ...state,
      isShowApplicant: false,
    }),
    setCustomerActiveTab: (state, action: PayloadAction<string>) => ({
      ...state,
      customerActiveTab: action.payload,
    }),
    setAboActiveTab: (state, action: PayloadAction<string>) => ({
      ...state,
      aboActiveTab: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(setBoot, (state, { payload }) => {
      const { customerProfileTabs, aboProfileTabs } = payload.configuration.profile;
      return {
        ...state,
        customerDefaultTab: getDefaultTab(customerProfileTabs),
        customerActiveTabs: customerProfileTabs,
        aboDefaultTab: getDefaultTab(aboProfileTabs),
        aboActiveTabs: aboProfileTabs,
      };
    });
  },
});

export const { showProfile, hideProfile, showApplicant, hideApplicant, setCustomerActiveTab, setAboActiveTab } =
  actions;

export default reducer;
