import { useEffect } from "react";
import { useLazyGetProfileOrdersQuery } from "../../services/Customer/CustomerOrderList/customerOrderListAPI";

interface Props {
  autoFetch: boolean;
  affAbo: string;
  pageNum: number;
  pageSize: number;
  period: string;
  sortValue: string;
  sortOrder: string;
  customerIds: number;
  searchBy?: string;
  businessNature?: string;
}

const useGetProfileOrders = ({
  autoFetch,
  affAbo,
  pageNum,
  period,
  pageSize,
  sortOrder,
  sortValue,
  customerIds,
  searchBy,
  businessNature,
}: Props) => {
  const [getProfileOrders] = useLazyGetProfileOrdersQuery();

  useEffect(() => {
    if (autoFetch === true) {
      getProfileOrders({
        affAbo,
        pageSize,
        pageNum,
        period,
        sortValue,
        sortOrder,
        customerIds,
        searchBy,
        businessNature,
      });
    }
  }, [
    affAbo,
    autoFetch,
    customerIds,
    getProfileOrders,
    pageNum,
    pageSize,
    period,
    sortOrder,
    sortValue,
    searchBy,
    businessNature,
  ]);
};

export default useGetProfileOrders;
