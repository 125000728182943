import { useTranslation } from "react-i18next";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { getAwardLabelKeyFromAwardCode } from "../../../../common/util/awardLabels";
import defaultContent from "../../../../content/ttci";
import commonContent from "../../../../content/common";
import { TtciEligibilityAwards } from "../../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import { LeafDisplayCard } from "../../../../common/leaf/LeafDisplayCard/LeafDisplayCard";
import { Card } from "../TtciEligibilityModal";
import { IconHeart } from "../../../../common/icons/IconHeart/IconHeart";
import "../TtciEligibilityModal.scss";
import { TTCI_STATUS } from "../../../../common/enums/ttci-enums";
import { TagColor } from "../../../../common/leaf/LeafTag/LeafTag";

type Props = {
  data: TtciEligibilityAwards[];
};

const getIneligibleTag = (status: string, eligible: boolean) => {
  let tagStatus = "";
  let tagColor: TagColor = "orange";
  if (!eligible) {
    tagStatus = "ineligible";
    tagColor = "orange";
  }
  if (status === TTCI_STATUS.QUALIFIED) {
    tagStatus = "achieved";
    tagColor = "green";
  }
  return {
    tagStatus,
    tagColor,
  };
};

export const createInEligibleLeafCards = (data: TtciEligibilityAwards[]) => {
  const ineliligibleCards: Card[] = [];

  data.forEach((eligibilityData) => {
    if (eligibilityData.firstTimeAwardEligible === false && eligibilityData.status !== TTCI_STATUS.HELD) {
      const { tagStatus, tagColor } = getIneligibleTag(eligibilityData.status, eligibilityData.firstTimeAwardEligible);
      ineliligibleCards.push({
        awardLabel: getAwardLabelKeyFromAwardCode(eligibilityData.awardCode, eligibilityData.awardLevel),
        awardCode: eligibilityData.awardCode,
        isRequalAward: false,
        tagStatus: tagStatus,
        tagColorVariant: tagColor,
        id: `${eligibilityData.awardCode}-firstTime`,
      });
    }
    if (
      eligibilityData.requalAwardEligible === false &&
      eligibilityData.requalificationAward.requalAwardStatus !== TTCI_STATUS.HELD
    ) {
      const { tagStatus, tagColor } = getIneligibleTag(
        eligibilityData.requalificationAward.requalAwardStatus,
        eligibilityData.requalAwardEligible,
      );
      ineliligibleCards.push({
        awardLabel: getAwardLabelKeyFromAwardCode(eligibilityData.awardCode, eligibilityData.awardLevel),
        awardCode: eligibilityData.awardCode,
        isRequalAward: true,
        tagStatus: tagStatus,
        tagColorVariant: tagColor,
        id: `${eligibilityData.awardCode}-requal`,
      });
    }
  });

  return ineliligibleCards.sort((a, b) => a.awardCode - b.awardCode);
};

export const IneligibleTab = ({ data }: Props) => {
  const { t } = useTranslation(["ttci", "common"]);
  const ineligibleDesc = `${t("ttciEligibility.ineligibleDesc", defaultContent.ttciEligibility["ineligibleDesc"])}`;

  const ineliligibleCards = createInEligibleLeafCards(data);

  const getAwardLabel = (isRequalAward: boolean, awardCode: number, awardLabel: string) => {
    const requalification = isRequalAward ? ` ${t(`common:requalification`, commonContent.requalification)}` : "";
    const label = `${t(
      `common:awardLabels.${getAwardLabelKeyFromAwardCode(awardCode, awardLabel)}`,
      commonContent.awardLabels[getAwardLabelKeyFromAwardCode(awardCode, awardLabel)],
    )}${requalification}`;
    return label;
  };

  const returnLeafCards = (cards: Card[]) => {
    if (cards.length > 0) {
      return cards.map((d) => {
        return (
          <LeafDisplayCard
            id={d.id}
            key={d.id}
            awardLabel={getAwardLabel(d.isRequalAward, d.awardCode, d.awardLabel)}
            awardStatus={`${t(`${d.tagStatus}`, defaultContent[d.tagStatus])}`}
            leafTagVariant={d.tagColorVariant}
          ></LeafDisplayCard>
        );
      });
    }
    return (
      <LeafDisplayCard
        id={"no-ineligible-pins"}
        key={"no-ineligible-pins"}
        awardLabel={`${t(`ttciEligibility.noIneligiblePins`, defaultContent.ttciEligibility["noIneligiblePins"])}.`}
        awardStatus={""}
        leafTagVariant={"blue"}
      ></LeafDisplayCard>
    );
  };

  const createNoPinsComponent = (ineliligibleCards: Card[]) => {
    if (ineliligibleCards.length === 0) {
      return (
        <section className="no-pins-section">
          <IconHeart />
          <span className="no-pins-section__title">
            {`${t(`ttciEligibility.noIneligiblePins`, defaultContent.ttciEligibility["noIneligiblePins"])}`}
          </span>
          <span className="no-pins-section__body">
            {`${t(
              `ttciEligibility.noIneligiblePinsAtThisTime`,
              defaultContent.ttciEligibility["noIneligiblePinsAtThisTime"],
            )}`}
          </span>
        </section>
      );
    }
    return <></>;
  };

  return (
    <div>
      <LeafTile isLoading={false} description={ineligibleDesc}>
        <div>{returnLeafCards(ineliligibleCards)}</div>
      </LeafTile>
      <div>{createNoPinsComponent(ineliligibleCards)}</div>
    </div>
  );
};
