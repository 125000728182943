import { EligibleIncentive } from "../../services/ShellConfig/getShellConfigAPI.types";

export const isProgramActive = (
  eligibleIncentives: EligibleIncentive[] = [],
  awardCode: number,
  featureFlag = true,
) => {
  return (
    featureFlag === true &&
    eligibleIncentives.some(({ awardNumber, programActive }) => awardNumber === awardCode && programActive === true)
  );
};

export const isProgramActiveBulkCheck = (eligibleIncentives: EligibleIncentive[] = [], awardCodes: number[]) => {
  return eligibleIncentives.some(
    ({ awardNumber, programActive }) => awardCodes.includes(awardNumber) && programActive === true,
  );
};
