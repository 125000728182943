import { LeafModal } from "../../../leaf/LeafModal/LeafModal";
import "./DetailsListModal.scss";
import LeafTable, { TableHeader, TableRow } from "../../../leaf/LeafTable/LeafTable";
import { ErrorTile } from "../../../components/ErrorTile/ErrorTile";
import LeafRectangleLoader from "../../../leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { IconCheckCircle } from "../../../icons/IconCheckCircle/IconCheckCircle";
import { AwardProgressBadge } from "../../../components/AwardProgressBadge/AwardProgressBadge";
import { AWARD_PROGRESS } from "../../../enums/award-progress-codes";
import { QUALIFICATION_STATUS } from "../../../enums/incentive-enums";

export interface table {
  sectionHeaderIcon: boolean;
  sectionHeaderText?: string;
  achieved?: boolean;
  displaySectionHeader?: boolean;
  tableHeader: TableHeader[];
  tableRows: TableRow[];
}

export interface total {
  label: string;
  amount: string;
}

type Props = {
  multiplierBaseReponseData: {
    isLoading: boolean;
    errorStatus: boolean;
    refetch: Function;
  };
  modalIsOpen: boolean;
  toggleModal: Function;
  totalMultiplierBase?: total;
  title: string;
  table: table[];
  tagStatus?: string;
};

const getTagStatus = (status: string): AWARD_PROGRESS => {
  if (status === QUALIFICATION_STATUS.TRACKING) {
    return AWARD_PROGRESS.TRACKING;
  } else if (status === QUALIFICATION_STATUS.QUALIFIED) {
    return AWARD_PROGRESS.ACHIEVED;
  } else {
    return AWARD_PROGRESS.NOT_TRACKING;
  }
};

export const DetailsListModel = ({
  multiplierBaseReponseData,
  modalIsOpen,
  toggleModal,
  totalMultiplierBase,
  title,
  table,
  tagStatus,
}: Props) => {
  const { isLoading, errorStatus, refetch } = multiplierBaseReponseData;

  const renderLoadingOrError = () => {
    if (isLoading) {
      return (
        <LeafRectangleLoader isLoading={isLoading} numRows={2} width="100%" height="6rem" margin={"1rem 0 0.5rem 0"} />
      );
    }
    if (errorStatus) {
      return (
        <ErrorTile
          clickFunction={refetch}
          errorStatus={errorStatus}
          isLoading={isLoading}
          tealiumTrackId="Detail_List_Modal"
        />
      );
    }
    return <></>;
  };

  const returnDividerLine = (index: number, table: table[]) => {
    const activeSections = table.filter((d) => d.displaySectionHeader).length;
    if (activeSections > 1 && index !== table.length - 1) {
      return <span className="VisionBonusMultiplierBase__divider-line"></span>;
    }
    return <></>;
  };

  const renderComponent = () => {
    return (
      <div>
        <section className="DetailsListModal__header-container">
          <h2>{title}</h2>
          {tagStatus !== undefined && <AwardProgressBadge status={getTagStatus(tagStatus)} hideIcon={true} />}
        </section>
        {table.map((tab, index) => {
          return (
            <div className={`DetailsListModal__multiplier-section`} key={index}>
              <section>
                {tab.displaySectionHeader ? (
                  <div>
                    {tab.sectionHeaderIcon && (
                      <IconCheckCircle color={tab.achieved ? "green" : "gray"} size="md" solidCheckCircle={false} />
                    )}
                    <p className={`${tab.achieved ? "DetailsListModal__multiplier-section__green" : ""}`}>
                      {tab.sectionHeaderText}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
                <LeafTable
                  header={tab.tableHeader}
                  rows={tab.tableRows}
                  columnAlign={"left"}
                  headerStyle={"clear"}
                  hideHeader={false}
                />
              </section>
              {returnDividerLine(index, table)}
            </div>
          );
        })}
        {totalMultiplierBase && (
          <section className="DetailsListModal__total-container">
            <p>{totalMultiplierBase.label}</p>
            <span>{totalMultiplierBase.amount}</span>
          </section>
        )}
      </div>
    );
  };

  return (
    <div id="details-list-modal" className="DetailsListModal">
      <LeafModal open={modalIsOpen} onCloseCallbackFn={toggleModal}>
        {!isLoading && !errorStatus ? <>{renderComponent()}</> : <>{renderLoadingOrError()}</>}
      </LeafModal>
    </div>
  );
};
