import { accAPI } from "../..";
import { RecentOrderStatParams, RecentOrderStatResponse } from "./recentOrderStatAPI.types";

export const recentOrderStatAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getRecentOrderStat: build.query<RecentOrderStatResponse, RecentOrderStatParams>({
      query: (params) => {
        const { affAbo, period } = params;
        const queryParams = new URLSearchParams({
          period,
        });
        return `/customer/v2/${affAbo}/stat/recent/order?${queryParams}`;
      },
    }),
  }),
});

export const { useGetRecentOrderStatQuery } = recentOrderStatAPI;
