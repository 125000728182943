import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { lambdaErrorCheck } from "../../../../common/util/errorHandling";
import { storedLocale } from "../../../impersonation/util";
import { useGetAnnualBonusBreakdownQuery } from "../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI";
import { BonusBreakdownData } from "../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI.types";

export const defaultBonusBreakdownData = (): BonusBreakdownData => {
  return {
    BonusAmountSubTotal: "",
    BonusAmount: [],
    CallResults: [],
    Error: null,
  };
};

export const FetchBonusBreakdownData = (period: string) => {
  const { configuration, user } = useAppSelector((state) => state.boot);
  const shellConfig = useAppSelector((state) => state.boot.shellConfig);
  const { persona } = shellConfig;
  const eligibleIncentives = shellConfig.eligibleIncentives
    .filter((item) => item.programActive)
    .map((item) => item.awardNumber);
  const {
    data: payloadData,
    isFetching,
    isError,
    refetch,
  } = useGetAnnualBonusBreakdownQuery({
    baseUrl: configuration.annualDashboardApiUrl,
    abo: user.abo,
    aff: user.aff,
    period: period,
    isoCountryCode: user.isoCountryCode,
    isoCurrencyCode: shellConfig.isoCurrencyCode,
    locale: storedLocale(),
    persona: persona,
    eligibleIncentives: eligibleIncentives,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [bonusBreakdownData, setBonusBreakdownData] = useState<BonusBreakdownData>(defaultBonusBreakdownData());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (payloadData && !isFetching && !isError) {
      setBonusBreakdownData(payloadData);
      if (payloadData.Error && payloadData.Error[0] !== null) {
        const foundLambdaError = lambdaErrorCheck(payloadData.Error);
        setErrorStatus(foundLambdaError);
      }
    }
  }, [isFetching, period, isError, payloadData]);

  return { isLoading, bonusBreakdownData, errorStatus, refetch };
};
