import { useTranslation } from "react-i18next";
import "./FGGMultiplierBase.scss";
import defaultContent from "../../../content/frontlineGrowth";
import { AWARD_CODES } from "../../../common/enums/award-codes";
import {
  BonusAwardDetail,
  MultiplierBaseResponse,
} from "../../../services/GroupGrowth/FrontlineGroupGrowth/frontlineGroupGrowthAPI.types";
import { DetailsListModel, table } from "../../../common/components/Modals/DetailsListModal/DetailsListModal";

type Props = {
  multiplierBaseReponseData: {
    multiplierBaseData: MultiplierBaseResponse;
    isLoading: boolean;
    errorStatus: boolean;
    refetch: Function;
  };
  modalIsOpen: boolean;
  toggleModal: Function;
  performanceYear: string;
  multiplierBase: string;
};

const getAwardContentKey = (awardNumber: number) => {
  switch (awardNumber) {
    case AWARD_CODES.LEADERSHIP:
      return "leadershipBonus";
    case AWARD_CODES.FOSTER:
      return "fosterBonus";
    case AWARD_CODES.PERFORMANCE_BONUS:
      return "performanceBonus";
    case AWARD_CODES.RUBY:
      return "rubyBonus";
    case AWARD_CODES.DEPTH:
      return "depthBonus";
    default:
      return "";
  }
};

export const FGGMultiplierBase = ({
  multiplierBaseReponseData,
  modalIsOpen,
  toggleModal,
  performanceYear,
  multiplierBase,
}: Props) => {
  const { t } = useTranslation(["frontlineGrowth"]);
  const { isLoading, errorStatus, refetch, multiplierBaseData } = multiplierBaseReponseData;
  const { Status, MultiplierBaseDetails } = multiplierBaseData;

  const createRows = (bonusAwardDetail: BonusAwardDetail[]) => {
    if (bonusAwardDetail && bonusAwardDetail.length > 0) {
      const rows = new Array(bonusAwardDetail.length);
      bonusAwardDetail.forEach((_, index) => {
        const bonusAward = getAwardContentKey(bonusAwardDetail[index].BonusAward);
        const bonusAwardContent = `${t(
          `multiplierBase.${bonusAward}`,
          defaultContent["multiplierBase"][`${bonusAward}`],
          {
            performanceYear: performanceYear,
          },
        )}`;
        rows[index] = {
          row: [
            { value: bonusAwardContent, columnAlign: "left" },
            { value: bonusAwardDetail[index].BonusTotal, columnAlign: "left" },
          ],
        };
      });

      return rows;
    }
    return [];
  };

  const renderTable = (): table[] => {
    return MultiplierBaseDetails.map((base) => {
      return {
        sectionHeaderIcon: true,
        sectionHeaderText: `${t(
          `multiplierBase.${base.MultiplierType}`,
          defaultContent["multiplierBase"][`${base.MultiplierType}`],
        )}`,
        achieved: base.Achieved,
        displaySectionHeader: base.DisplaySectionHeader,
        tableHeader: [
          {
            value: `${t("multiplierBase.bonus", defaultContent["multiplierBase"]["bonus"])}`,
            width: "70%",
            padding: "0",
          },
          {
            value: `${t("multiplierBase.amount", defaultContent["multiplierBase"]["amount"])}`,
            width: "30%",
          },
        ],
        tableRows: createRows(base.BonusAwards),
      };
    }).filter((table) => table.tableRows.length !== 0);
  };

  return (
    <div id="fgg-multiplier-base" className="FGGMultiplierBase">
      <DetailsListModel
        multiplierBaseReponseData={{
          isLoading: isLoading,
          errorStatus: errorStatus,
          refetch: refetch,
        }}
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
        title={`${t("multiplierBase.title", defaultContent["multiplierBase"]["title"], {
          performanceYear: performanceYear,
        })} `}
        tagStatus={Status}
        table={renderTable()}
        totalMultiplierBase={{
          label: `${t("multiplierBase.totalMultiplierBase", defaultContent["multiplierBase"]["totalMultiplierBase"])} `,
          amount: multiplierBase,
        }}
      ></DetailsListModel>
    </div>
  );
};
