import { useTranslation } from "react-i18next";
import LeafPanel from "../../../common/leaf/LeafPanel/LeafPanel";
import LeafTabViews, { TabView } from "../../../common/leaf/LeafTabViews/LeafTabViews";
import { setActiveTabView } from "../../../reducers/eligibilityTab";
import { useAppDispatch, useAppSelector } from "../../../store";
import defaultContent from "../../../content/ttci";
import { TTCIResponse } from "../../../services/CorePlusIncentives/corePlusIncentivesApi.types";
import { EliligibleTab } from "./Tabs/EligibleTab";
import { HeldTab } from "./Tabs/HeldTab";
import { IneligibleTab } from "./Tabs/IneligibleTab";

type Props = {
  data: TTCIResponse;
  onCloseClick: Function;
  show: boolean;
};

export interface Card {
  awardLabel: string;
  awardCode: number;
  isRequalAward: boolean;
  tagStatus: string;
  tagColorVariant: "blue" | "green" | "red" | "orange" | "purple" | "gray";
  id: string;
}

export const TtciEligibilityModal = ({ data, onCloseClick, show }: Props) => {
  const { t } = useTranslation(["ttci"]);
  const { activeView, activeTabs } = useAppSelector((state) => state.eligiblityTab);
  const dispatch = useAppDispatch();

  const internalTabsView = [
    {
      id: "eligible_tab",
      title: t("ttciEligibility.eligibleTitle", defaultContent.ttciEligibility["eligibleTitle"]),
      View: <EliligibleTab data={data.ttciEligibilityAwards} />,
    },
    {
      id: "ineligible_tab",
      title: t("ttciEligibility.ineligibleTitle", defaultContent.ttciEligibility["ineligibleTitle"]),
      View: <IneligibleTab data={data.ttciEligibilityAwards} />,
    },
    {
      id: "held_tab",
      title: t("ttciEligibility.heldTitle", defaultContent.ttciEligibility["heldTitle"]),
      View: <HeldTab data={data.ttciEligibilityAwards} />,
    },
  ];

  const tabsView = activeTabs.map((id) => ({ ...internalTabsView.find((item) => item.id === id) })) as TabView[];

  const setViewOnClick = (activeView: string) => {
    dispatch(setActiveTabView(activeView));
  };

  return (
    <LeafPanel
      show={show}
      onCloseClick={onCloseClick}
      title={`${t("ttciEligibility.modalTitle", defaultContent.ttciEligibility["modalTitle"])}`}
      id={"ttci-eligibility-modal"}
    >
      <LeafTabViews setViewOnClick={setViewOnClick} Views={tabsView} activeView={activeView} />
    </LeafPanel>
  );
};
