import { formatKpiValue } from "../../../../../../../../../common/util/format";
import { useGetVCSPercentageQuery } from "../../../../../../../../../services/PerformanceHistory/performanceHistoryAPI";
import { PerfHistoryData } from "../../../../../../../../../services/PerformanceHistory/performanceHistoryAPI.types";
import { PerfHistoryDataProps } from "../ABOProfilePerfHistoryKPIs";

const ABOProfilePerfHistoryVCSPercent = ({
  affAbo,
  locale,
  isoCountryCode,
  period,
  periodCount = 12,
  children,
}: PerfHistoryDataProps) => {
  const { isFetching, data, isError } = useGetVCSPercentageQuery({
    affAbo,
    period,
    periodCount,
    locale,
    market: isoCountryCode,
  });

  const overrideFormatTableKPIData = (data: PerfHistoryData) => {
    return formatKpiValue("percent", data.integerAmount, isoCountryCode, locale);
  };

  const overrideFormatChartData = (data: number | string) => {
    return formatKpiValue("percent", Number(data), isoCountryCode, locale);
  };

  return <>{children({ data, isFetching, isError, overrideFormatTableKPIData, overrideFormatChartData })}</>;
};

export default ABOProfilePerfHistoryVCSPercent;
