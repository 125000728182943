import { accAPI } from "../..";
import { OverviewCardsParams, OverviewCardsResponse, OverviewCardsTransformResponse } from "./overviewCardsAPI.types";

export const overViewCardsAPI = accAPI.injectEndpoints({
  endpoints: (build) => ({
    getOverViewCardsDetails: build.query<OverviewCardsTransformResponse, OverviewCardsParams>({
      query: (params) => {
        const { period, affAbo } = params;
        const query = `{
            aboDetail(period: "${period}", affAbo: "${affAbo}") {
              pvbv {
                customerPV,
                vcsPV,
                nonVCSPV
              }
            }
          }`;
        const queryParams = new URLSearchParams({
          query,
        });
        return `/gql/los/graphql?${queryParams}`;
      },
      transformResponse: (response: OverviewCardsResponse) => {
        const { data } = response || {};
        const { aboDetail } = data || {};
        const { pvbv } = aboDetail || {};
        const { customerPV = 0, vcsPV = 0, nonVCSPV = 0 } = pvbv || {};

        return {
          customerPV,
          vcsPV,
          nonVCSPV,
        };
      },
    }),
  }),
});

export const { useGetOverViewCardsDetailsQuery } = overViewCardsAPI;
