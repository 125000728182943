import { useTranslation } from "react-i18next";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import defaultContent from "../../../../../content/monthlyPGG";
import LeafTable, { TableRow } from "../../../../../common/leaf/LeafTable/LeafTable";
import { QUALIFICATION_STATUS } from "../../../../../common/enums/incentive-enums";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../../../../common/icons/IconShield/IconShield";
import { getPerformanceYearShort, getShortMonth } from "../../../../../common/util/period";
import { storedLocale } from "../../../../impersonation/util";
import { useAppSelector } from "../../../../../store";
import { PerformanceBonusData } from "../../../../../services/GroupGrowth/MonthlyPersonalGroupGrowth/monthlyPGGAPI.types";
import { BadgeLegend } from "../../../../../common/components/BadgeLegend/BadgeLegend";
import "./MonthlyPerformanceBonusModal.scss";

type Props = {
  performanceBonusData: PerformanceBonusData[];
  toggleModal: Function;
  displayModal: boolean;
};

const getBadgeType = (status: QUALIFICATION_STATUS) => {
  switch (status) {
    case QUALIFICATION_STATUS.QUALIFIED:
    case QUALIFICATION_STATUS.TRACKING:
      return BADGE_TYPE.EARNED;
    case QUALIFICATION_STATUS.NOT_QUALIFIED:
    case QUALIFICATION_STATUS.HELD:
    case QUALIFICATION_STATUS.DENIED:
      return BADGE_TYPE.NOT_EARNED;
    case QUALIFICATION_STATUS.OPEN:
      return BADGE_TYPE.POTENTIAL;
    default:
      return BADGE_TYPE.UNAVAILABLE;
  }
};

const createRows = (performanceBonusData: PerformanceBonusData[], locale: string): TableRow[] => {
  return performanceBonusData.map((data) => ({
    row: [
      {
        value: (
          <span className="MonthlyPGGPerformanceBonusModal__badge-cell-content">
            <IconShield type={getBadgeType(data.Status)} size={BADGE_SIZE.MEDIUM} />
            <p>{getShortMonth(data.Period, locale)}</p>
          </span>
        ),
      },
      { value: <p>{data.PerfBonus || "-"}</p> },
      { value: <p>{data.Multiplier || "-"}</p> },
      { value: <p>{data.MonthlyTotal || "-"}</p> },
    ],
  }));
};

export const MonthlyPGGPerformanceBonusModal = ({ performanceBonusData, toggleModal, displayModal }: Props) => {
  const { t } = useTranslation(["monthlyPGG"]);
  const locale = storedLocale();
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const renderLegend = () => {
    return <BadgeLegend />;
  };
  return (
    <div className="MonthlyPGGPerformanceBonusModal">
      <LeafModal
        open={displayModal}
        onCloseCallbackFn={toggleModal}
        title={`${t("performanceBonus", defaultContent["performanceBonus"])}`}
        subtitle={`${t("shortPerformanceYear", defaultContent["shortPerformanceYear"], {
          shortPY: getPerformanceYearShort(selectedPeriod),
        })}`}
        description={renderLegend()}
      >
        <LeafTable
          header={[
            { value: `${t("qualified", defaultContent["qualified"])}` },
            { value: `${t("perfBonus", defaultContent["perfBonus"])}` },
            { value: `${t("multiplier", defaultContent["multiplier"])}` },
            { value: `${t("monthlyTotal", defaultContent["monthlyTotal"])}` },
          ]}
          rows={createRows(performanceBonusData, locale)}
          columnAlign={"center"}
          headerStyle={"default"}
          hideHeader={false}
        />
      </LeafModal>
    </div>
  );
};
